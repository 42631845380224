import React from 'react'
import PropTypes from 'prop-types'

const Banner = ({ bannerClasses, bannerId, backgroundStyles, withBackground, children }) => {
    return (
        <section id={bannerId} className={`banner ${bannerClasses}`}>
            {withBackground ? (
                <div className="banner-bg" style={backgroundStyles}>
                    {(children != null) && children}
                </div>
            ) : (
                <>
                    {(children != null) && children}
                </>
            )}
        </section>
    )
}
Banner.propTypes = {
    bannerClasses: PropTypes.string,
    bannerId: PropTypes.string,
    backgroundStyles: PropTypes.object,
    children: PropTypes.node
}
Banner.defaultProps = {
    bannerId: "",
    bannerClasses: "",
    withBackground: true,
    backgroundStyles: {},
}
export default Banner
