import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"

// import store
import { connect } from 'react-redux'

// import store actions
import {
    setAppTokenAsked, setAppTokenRetrieved,
    setDomain, setFormation, togglePreloader
} from '../../store/actions'

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment';
import 'moment/locale/fr';

// import services
import { getDomain } from '../../services/domainService'
import { getFormations } from '../../services/formationService'

// import dummies
import { DOMAIN } from '../../helpers/dummies'

// import common components
import Banner from '../../components/common/Banner'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import Section from '../../components/common/Section'
import Square from '../../components/common/Square'
import Sliders, { RESPONSIVES } from '../../components/common/Sliders'

// import custom components
import ListFormation from '../../components/custom/schools/ListFormation'

// import utilities
import { isUnauthenticatedRequest } from '../../helpers/utilities'

// import constants
import { APP_TOKEN } from '../../helpers/constants'

// custom sliders options
const SLIDERS_RESPONSIVES = {
    ...RESPONSIVES,
    desktop: { ...RESPONSIVES.desktop, items: 3 },
    tablet: { ...RESPONSIVES.tablet, items: 2 }
}


class Domain extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        try {// Load domain
            this.props.onPreloaderToggled(true, "Chargement du domaine")
            this.onGetDomain()
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                if (this.props.tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    this.props.onAppTokenRetrieved(false)
                    this.onGetDomain()
                } else if (this.props.tokenFailed) {
                    this.props.onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        this.props.onSetDomain({})
    }

    /**
     * Get domain.
     * 
     */
    onGetDomain() {
        let { domainCode } = this.props.match.params
        getDomain(domainCode).then((response) => {
            if (response && response.publicId && (response.publicId === domainCode)) {
                this.props.onSetDomain({
                    ...response,
                    image: response.image ? response.image : DOMAIN.image,
                    icon: response.icon ? response.icon : DOMAIN.icon,
                    tag: response.tag ? response.tag : DOMAIN.tag,
                    sliders: response.sliders ? response.sliders : DOMAIN.sliders,
                    content: response.content ? response.content : DOMAIN.content
                })
                this.onGetFormations()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET DOMAIN ERROR")
            }
        }).catch((error) => {
            console.error(error, "GET DOMAIN ERROR")
        });
    }

    /**
     * Get formations.
     * 
     */
    onGetFormations() {
        getFormations().then((response) => {
            if (response && response.data) {
                this.props.onSetFormations(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET FORMATIONS ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET FORMATIONS ERROR")
        });
    }

    render() {
        const { domain, formations, isPreloader } = this.props

        return (
            <React.Fragment>
                <div id="domain" className={`${isPreloader ? "d-none" : "d-block"}`}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    {(domain && domain.publicId) ? (
                        <div id="domain-content">
                            {/* Banner */}
                            <Banner
                                bannerId="domain-banner"
                                backgroundStyles={{ backgroundImage: `url(${domain.image})` }}
                                withBackground
                            />

                            <div className="container">
                                {/* Breadcrumbs */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Breadcrumbs
                                            subtitle="Domaines"
                                            subtitleLink="/domains"
                                            currentTile="Détails d'un domaine"
                                        />
                                    </div>
                                </div>

                                <div className="row my-5">
                                    <div className="col-lg-9">
                                        <div className="box-single single-shadow p-4">
                                            <article>
                                                <h2 className="single-title">
                                                    {domain.name}
                                                </h2>

                                                <div dangerouslySetInnerHTML={{ __html: domain.content }} />
                                            </article>

                                            <Section
                                                subtitle=""
                                                sectionId="domain-sliders"
                                                sectionClasses="domain-sliders py-3"
                                                sectionInnerClasses=""
                                            >
                                                <Sliders
                                                    slidersClasses="domain-sliders"
                                                    responsiveOptions={SLIDERS_RESPONSIVES}
                                                >
                                                    {domain.sliders.map((item, index) => (
                                                        <div key={`domain_slider_item_${index}`} className="box">
                                                            <img src={item.image} alt={`domain slider ${index}`} />
                                                        </div>
                                                    ))}
                                                </Sliders>
                                            </Section>

                                            <Section
                                                subtitle="Formations dans ce domaine"
                                                sectionId="domain-formations"
                                                sectionClasses="domain-formations py-3"
                                                sectionInnerClasses=""
                                            >
                                                <ListFormation
                                                    listerClasses="g-3 mt-0"
                                                    items={formations.slice(0, 4)}
                                                />
                                            </Section>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="ads">
                                            <div className="rectangle center-box">
                                                PUB LATÉRAL ICI
                                            </div>
                                        </div>

                                        <Square squareClasses="my-3">
                                            <h3 className="square-title">Les dernières formations</h3>
                                            <div className="square-item square-recents">
                                                {(formations && formations.reverse().slice(0, 6).map((item, index) => (
                                                    <div key={`formation_item_${index}`} className="recent-item clearfix">
                                                        <img
                                                            src={item.image ? item.image : ""}
                                                            alt={`formation ${index}`}
                                                            className={`${item.image ? "" : "d-none"}`}
                                                        />
                                                        <h4 className={`${item.image ? "" : "ms-0"}`}>
                                                            <a href="#">
                                                                {(item.name.length > 25) ? (item.name.substr(0, 24) + "...") : item.name}
                                                            </a>
                                                        </h4>
                                                        <time dateTime={item.createdAt} className={`${item.image ? "" : "ms-0"}`}>
                                                            {moment(item.createdAt, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY')}
                                                        </time>
                                                    </div>
                                                )))}
                                            </div>
                                        </Square>

                                        <div className="ads">
                                            <div className="interstitial center-box">
                                                PUB LATÉRAL ICI
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12">
                                    <center>
                                        <p>Ce domaine est introuvable.</p>
                                        <p className="mb-0">
                                            Continuer sur{" "}
                                            <a href="/">l'accueil</a> ou sur la liste des{" "}
                                            <a href="/">domaines</a>.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
Domain.propTypes = {
    domain: PropTypes.object,
    formations: PropTypes.array,
    onSetDomain: PropTypes.func,
    onSetFormations: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
    onAppTokenAsked: PropTypes.func,
    onAppTokenRetrieved: PropTypes.func,
    onPreloaderToggled: PropTypes.func,
}
const mapStateToProps = state => ({
    domain: state.domain,
    formations: state.formations,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetDomain: data => dispatch(setDomain(data)),
    onSetFormations: data => dispatch(setFormation(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Domain)