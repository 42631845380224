import React, { useState } from "react";
import PropTypes from 'prop-types';

// import components
import { Form, Col, Row, Button } from 'react-bootstrap';

// import phone input
import PhoneInput from 'react-phone-input-2';

// import loader
import Loader from "./Loader";
import Selecter from "./Selecter";

// import constants
import { USER_EDUCATOR, USER_PARENT, USER_STUDENT, USER_TEACHER } from "../../helpers/constants";

// import dummies
import { DIPLOMES, LOCATIONS } from "../../helpers/dummies";


const Questioner = ({ onQuestioned, loading, failure, success, items }) => {

    const [data, setData] = useState({});

    const onChanged = (e) => {
        if (e.target) {
            let { name, value, type } = e.target
            setData({ ...data, [name]: value })
        }
    }

    const onSelected = (selected, option) => {
        setData({ ...data, [option]: [selected] })
    }

    const onCleared = () => {
        setData({})
        document.getElementById("faqs").value = ""
        if (document.getElementById("otherQuestion")) {
            document.getElementById("otherQuestion").value = ""
        }
        document.getElementById("profile").value = ""
        document.getElementById("residence").value = ""
        document.getElementById("diplomas").value = ""
        document.getElementById("useremail").value = ""
        document.getElementById("userphone").value = ""
    }

    const onSubmited = (e) => {
        e.preventDefault();
        onQuestioned(data, onCleared)
    }


    return (
        <React.Fragment>
            <Form onSubmit={onSubmited}>
                <Row>
                    <Col lg={12} sm={12} className="mb-3">
                        <Form.Group className="mb-0">
                            <Selecter
                                id="faqs"
                                name="faqs"
                                placeholder="Sélectionner votre question"
                                selecterClasses="questioner-selects"
                                selecterClassesPrefix="questioner-selects-prefix"
                                selected={data.faqs || []}
                                options={items}
                                onSelected={onSelected}
                                valueAttribut="question"
                                labelAttribut="question"
                            />
                        </Form.Group>
                    </Col>
                    {(data.faqs && (data.faqs.length > 0) && data.faqs[0]) ? (
                        <>
                            {(data.faqs[0].question && (data.faqs[0].question === "Autres")) ? (
                                <Col sm={12}>
                                    <Form.Group>
                                        <Form.Label>Votre question</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="otherQuestion"
                                            name="otherQuestion"
                                            className="form-control"
                                            placeholder="Que voulez-vous savoir ?"
                                            onChange={onChanged}
                                        />
                                    </Form.Group>
                                </Col>
                            ) : null}
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Vous êtes</Form.Label>
                                    <Form.Control
                                        as="select" custom required
                                        id="profile" name="profile"
                                        onChange={onChanged}
                                    >
                                        <option>---</option>
                                        {[USER_STUDENT, USER_PARENT, USER_TEACHER, USER_EDUCATOR].map((item, index) => (
                                            <option key={`user_type_item_${index}`} value={item.key}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Pays de résidence</Form.Label>
                                    <Selecter
                                        id="residence"
                                        name="residence"
                                        placeholder="Pays de résidence"
                                        selecterClasses="questioner-selects"
                                        selecterClassesPrefix="questioner-selects-prefix"
                                        selected={data.residence || []}
                                        options={LOCATIONS}
                                        onSelected={onSelected}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Votre niveau d'étude</Form.Label>
                                    <Selecter
                                        id="diplomas"
                                        name="diplomas"
                                        placeholder="Niveau d'étude actuel"
                                        selecterClasses="questioner-selects"
                                        selecterClassesPrefix="questioner-selects-prefix"
                                        selected={data.diplomas || []}
                                        options={DIPLOMES}
                                        onSelected={onSelected}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label> Votre email </Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        id="useremail"
                                        name="useremail"
                                        className="form-control"
                                        placeholder="johndoe@gmail.com"
                                        onChange={onChanged}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label> Votre contact téléphonique </Form.Label>
                                    <PhoneInput
                                        country={'us'}
                                        value={data.userphone || ""}
                                        onChange={onChanged}
                                        placeholder="+229 90 00 00 00"
                                        inputProps={{
                                            id: 'userphone',
                                            name: 'userphone',
                                            required: true,
                                            className: "form-control"
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            {(success) ? (
                                <Col sm={12} className="mt-4">
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        Votre question a bien été envoyée.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                                    </div>
                                </Col>
                            ) : null}
                            <Col sm={12}>
                                {(loading) ? (
                                    <Loader color="blue" />
                                ) : (
                                    <button type="submit" className="btn-submit btn-mailer">
                                        Demander
                                    </button>
                                )}
                            </Col>
                        </>
                    ) : null}
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default Questioner