import React from 'react'
import PropTypes from 'prop-types'

// import Link
import { Link } from "react-router-dom"

// import moment
import moment from 'moment';
import 'moment/locale/fr';

// import Flag
import Flag from 'react-flagkit';

// import constants
import { ITEMS_TO_DISPLAY_XS } from '../../../helpers/constants'

// import utilitites
import { calculateCol, slugify } from '../../../helpers/utilities';

// import custom common component
import Lister from '../../common/Lister';

// import dummies
import { COUNTRY, SCHOOL } from '../../../helpers/dummies';

// import images
import { icDate, icDeliveryMode, icDuration, icLanguage } from '../../../assets/images';


const ListFormation = ({ items, itemsPerRow, listerClasses, withDiscover, withPagination }) => {

    return (
        <React.Fragment>
            <Lister
                listerClasses={`${listerClasses} formations`}
                withDiscover={withDiscover}
                withPagination={withPagination}
                discoverOptions={{ discoverLink: "/formations" }}
            >
                {items && items.map((item, index) => {
                    let itemFormation = {
                        ...item,
                        school: {
                            ...item.school,
                            schoolLogo: item.school.schoolLogo ? item.school.schoolLogo : SCHOOL.image,
                            country: item.school.country ? {
                                ...item.school.country,
                                countryCode: item.school.country.countryCode ? item.school.country.countryCode : COUNTRY.countryCode
                            } : COUNTRY
                        }
                    }
                    return (
                        <FormationItem
                            key={`formation_item_${index}`}
                            col={calculateCol(itemsPerRow)}
                            {...itemFormation}
                        />
                    )
                })}
            </Lister>
        </React.Fragment>
    )

}
ListFormation.propTypes = {
    items: PropTypes.array,
    itemsPerRow: PropTypes.number,
    listerClasses: PropTypes.string,
    withPagination: PropTypes.bool
}
ListFormation.defaultProps = {
    itemsPerRow: ITEMS_TO_DISPLAY_XS,
    listerClasses: "g-3 mt-5",
    withDiscover: false,
    withPagination: false
}
export default ListFormation


/**
 * School Item.
 */
const FormationItem = ({ col, name, publicId, formationRythme, formationCost, language, school, domain, subDomain }) => (
    <div className={`col-lg-${col} col-md-6 col-sm-12`}>
        <div className="box-item">
            <div className="box-title">
                <h5>
                    {(name.length > 40) ? (name.substr(0, 39) + "...") : name}
                    {(domain && subDomain) ? (
                        <small className="d-block text-muted">
                            {(subDomain.name.length > 20) ? (subDomain.name.substr(0, 19) + "...") : subDomain.name}, {" "}
                            {(domain.name.length > 20) ? (domain.name.substr(0, 19) + "...") : domain.name}
                        </small>
                    ) : null}
                </h5>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-2">
                <div className="reviews">
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <span className="text-muted">
                        {`(${school.note ? school.note : "0"})`}
                    </span>
                </div>
            </div>

            <div className="box-content mt-1">
                <div className="d-flex align-items-center">
                    <img
                        src={school.schoolLogo}
                        className="img-fluid"
                        alt="school logo"
                        width={115} height={115}
                    />

                    <div className="d-flex flex-column">
                        <div className="box-title">
                            <h3>
                                {(school.name.length > 30) ? (school.name.substr(0, 29) + "...") : school.name}
                            </h3>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="me-1">
                                {(school.country.name.length > 25) ? (school.country.name.substr(0, 24) + "...") : school.country.name}
                            </span>
                            <Flag
                                country={school.country.countryCode.toUpperCase()}
                                className="ms-1 border border-dark"
                                height={16} width={22}
                            />
                        </div>
                    </div>
                </div>

                <div className="box-meta mt-3">
                    <ul>
                        {(school.recognizedByState) ? (
                            <li>
                                <span className="d-flex align-items-center">
                                    <i className='bx bxs-check-circle text-green' />
                                    Reconnu par l'État
                                </span>
                            </li>
                        ) : null}
                        <li>
                            <span title="Date de la prochaine rentrée">
                                <img src={icDate} width={24} height={24} alt="date icon" />{" "}
                                {`${(school.annualBackToSchool === 0) ? "Non renseigné" : moment(new Date(school.annualBackToSchool)).format('DD MMM YYYY')}`}
                            </span>
                        </li>

                        <li>
                            <span title="Durée de la formation">
                                <img src={icDuration} width={24} height={24} alt="duration icon" />{" "}
                                {(formationCost !== null) ? `${formationCost} mois` : "Non renseigné"}
                            </span>
                        </li>

                        <li>
                            <span title={`Format${((school.teachingMode !== null) && (school.teachingMode.length > 1)) ? "s" : ""} d’apprentissage`}>
                                <img src={icDeliveryMode} width={24} height={24} alt="formats icon" />{" "}
                                {(school.teachingMode !== null) ? `${school.teachingMode}` : "Non renseigné"}
                            </span>
                        </li>

                        <li>
                            <span title={`Langue${((language !== null) && (language.length > 1)) ? "s" : ""} d’enseignement`}>
                                <img src={icLanguage} width={24} height={24} alt="langues icon" />{" "}
                                {(language !== null) ? `${language}` : "Non renseigné"}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="box-links mt-2 d-flex align-items-center justify-content-end">
                <Link
                    to={{
                        pathname: `/formations/${slugify(name)}`,
                        state: { formationCode: publicId, formationName: name }
                    }}
                    className="see-more"
                >
                    Voir plus <i className='bx bx-right-arrow-alt'></i>
                </Link>
            </div>
        </div>
    </div>
);
