// import constants
import {
    ARTICLES_LIST, ARTICLES_SHOW, COUNTRIES_LIST, COUNTRIES_SHOW, DOMAINS_LIST, DOMAINS_SHOW, OPPORTUNITIES_LIST, OPPORTUNITIES_SHOW,
    SCHOOLS_LIST, SCHOOLS_SHOW, SUB_DOMAINS_LIST, SUB_DOMAINS_SHOW, FORMATIONS_LIST, FORMATIONS_SHOW, SLIDERS_LIST, SOCIALS_LIST, 
    MEMBERS_LIST, VISIONS_LIST, APP_TOKEN_ASKED, APP_TOKEN_RETRIEVED, TOGGLE_PRELOADER, SCHOOLS_SEARCHES, FAVORITE_SCHOOLS_LIST, FAVORITE_OPPORTUNITY_LIST
} from "../constants/constants"


// Home 
export const setSliders = data => ({
    type: SLIDERS_LIST,
    payload: { data },
})

export const setVisions = data => ({
    type: VISIONS_LIST,
    payload: { data },
})

export const setMembers = data => ({
    type: MEMBERS_LIST,
    payload: { data },
})

export const setSocials = data => ({
    type: SOCIALS_LIST,
    payload: { data },
})


// Opportunities 
export const setOpportunity = data => ({
    type: ((Array.isArray(data)) ? OPPORTUNITIES_LIST : OPPORTUNITIES_SHOW),
    payload: { data },
})

// Articles
export const setArticle = data => ({
    type: ((Array.isArray(data)) ? ARTICLES_LIST : ARTICLES_SHOW),
    payload: { data },
})


// Countries
export const setCountry = data => ({
    type: ((Array.isArray(data)) ? COUNTRIES_LIST : COUNTRIES_SHOW),
    payload: { data },
})


// Schools
export const setSchool = data => ({
    type: (Array.isArray(data) ? SCHOOLS_LIST : data.searcheds ? SCHOOLS_SEARCHES : SCHOOLS_SHOW),
    payload: { data: data.searcheds ? data.searcheds : data },
})

// Set Favorite Schools list
export const setStudentFavoriteSchoolList = data => (
    // console.log(data, 'this is redux data')
    {
        type: (Array.isArray(data) && FAVORITE_SCHOOLS_LIST),
        payload: { data: data},
    }
)

// Set Favorite Opportunity list
export const setStudentFavoriteOfferList = data => (
    // console.log(data, 'this is redux data')
    {
        type: (Array.isArray(data) && FAVORITE_OPPORTUNITY_LIST),
        payload: { data: data},
    }
)


// Domains
export const setDomain = data => ({
    type: ((Array.isArray(data)) ? DOMAINS_LIST : DOMAINS_SHOW),
    payload: { data },
})

// SubDomains
export const setSubDomain = data => ({
    type: ((Array.isArray(data)) ? SUB_DOMAINS_LIST : SUB_DOMAINS_SHOW),
    payload: { data },
})


// Formations
export const setFormation = data => ({
    type: ((Array.isArray(data)) ? FORMATIONS_LIST : FORMATIONS_SHOW),
    payload: { data },
})


// App Token
export const setAppTokenAsked = asked => ({
    type: APP_TOKEN_ASKED,
    payload: { asked },
})

export const setAppTokenRetrieved = (retrieved, failed = false) => ({
    type: APP_TOKEN_RETRIEVED,
    payload: { retrieved, failed },
})


// Preloader
export const togglePreloader = (preload, message = "Chargement") => ({
    type: TOGGLE_PRELOADER,
    payload: { preload, message },
})