import React from 'react'
import PropTypes from 'prop-types'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import Leaflet from "leaflet"

import MarkerClusterGroup from 'react-leaflet-markercluster'

import { OPPORTUNITY } from '../../helpers/dummies'


const Maper = ({ items, icon, size, withPopup, redirectLink, redirectParam, displayAttribut }) => {

    const [activeMarker, setActiveMarker] = React.useState(null);

    const customMarkerIcon = new Leaflet.Icon({ iconUrl: icon, iconSize: [50, 50] })

    const createClusterCustomIcon = (cluster) => {
        return new Leaflet.divIcon({
            html: "<span>" + cluster.getChildCount() + "</span>",
            className: 'custom-cluster',
            iconSize: Leaflet.Point(40, 40, true)
        });
    };

    const onPopupOpened = (item) => {
        setActiveMarker(((activeMarker == null) || (activeMarker.publicId !== item.publicId)) ? item : null)
    }

    const onPopupClosed = () => {
        setActiveMarker(null)
    }

    return (
        <MapContainer
            center={Array.isArray(items) ? [20.593683, 78.962883] : [items.coordinates.lat, items.coordinates.lng]}
            zoom={5} scrollWheelZoom={false} zoomControl={false} style={{ height: `${size}px` }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {(Array.isArray(items) ? (
                <MarkerClusterGroup
                    showCoverageOnHover={false}
                    spiderfyDistanceMultiplier={2}
                    iconCreateFunction={createClusterCustomIcon}
                >
                    {items.map((item, index) => (
                        <Marker
                            key={`map_marker_${index}`}
                            position={
                                item.coordinates ?
                                    [item.coordinates.lat, item.coordinates.lng] :
                                    [OPPORTUNITY.coordinates.lat, OPPORTUNITY.coordinates.lng]
                            }
                            icon={customMarkerIcon}
                            eventHandlers={(withPopup) ? {
                                click: () => onPopupOpened(item)
                            } : {}}
                        />
                    ))}
                    {(withPopup && activeMarker) && (
                        <Popup
                            position={
                                activeMarker.coordinates ?
                                    [activeMarker.coordinates.lat, activeMarker.coordinates.lng] :
                                    [OPPORTUNITY.coordinates.lat, OPPORTUNITY.coordinates.lng]
                            }
                            onClose={() => onPopupClosed}
                        >
                            <div className="popup-box">
                                {activeMarker.image ? (
                                    <img
                                        src={activeMarker.image}
                                        className="popup-content"
                                        alt="marker popup"
                                    />
                                ) : null}
                                <a href={`${redirectLink}${(activeMarker[redirectParam] !== "") ? `/${activeMarker[redirectParam]}` : ""}`}>
                                    <h6 className="fw-bold text-green p-3 pb-0">
                                        {activeMarker[displayAttribut]}
                                    </h6>
                                </a>
                            </div>
                        </Popup>
                    )}
                </MarkerClusterGroup>
            ) : (
                <Marker
                    position={[items.coordinates.lat, items.coordinates.lng]}
                    icon={customMarkerIcon}
                    eventHandlers={{}}
                />
            ))}
        </MapContainer>
    )

}
Maper.propTypes = {
    items: PropTypes.any.isRequired,
    icon: PropTypes.any,
    withPopup: PropTypes.bool,
    redirectLink: PropTypes.string,
    redirectParam: PropTypes.string,
    displayAttribut: PropTypes.string,
    size: PropTypes.string
}
Maper.defaultProps = {
    withPopup: false,
    size: "500",
    displayAttribut: "name",
    redirectLink: "#",
    redirectParam: "publicId"
}
export default Maper
