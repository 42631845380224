import React from 'react'

const Paginator = () => {
    return (
        <div className="center-box paginator my-5">
            <ul className="justify-content-center">
                <li className="active"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
            </ul>
        </div>
    )
}
export default Paginator
