import React from 'react';
import PropTypes from 'prop-types';

// import store
import { connect } from 'react-redux';

// import store actions
import { setAppTokenAsked, setAppTokenRetrieved, setSchool, togglePreloader } from '../../store/actions';

// import deep-equal
import equal from "deep-equal"

// import services
import { getSchools } from '../../services/schoolService';

// import dummies
import { SCHOOL } from '../../helpers/dummies';

// import common components
import Banner from '../../components/common/Banner';
import Leading from '../../components/common/Leading';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import Section from '../../components/common/Section';

// import custom components
import ListSchool from '../../components/custom/schools/ListSchool';
import FilterSchool from '../../components/custom/schools/FilterSchool';
import { getStudentFavoriteUniversityList, addUniversityInStudentFavoriteList, deleteStudentFavoriteUniversity  } from '../../services/schoolService';

// import constants
import { APP_TOKEN, USER_DATA } from '../../helpers/constants';
import { toast } from 'react-toastify';

// import utilities
import { isUnauthenticatedRequest } from '../../helpers/utilities';


class Schools extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            studentFavoriteUniversities:[],
            userPublicId:'',
        }
        this.addFavoriteInStudentPanel = this.addFavoriteInStudentPanel.bind(this);
        this.studentFavoriteUniversityList = this.studentFavoriteUniversityList.bind(this);
        this.deleteUniversityFromStudentPanel = this.deleteUniversityFromStudentPanel.bind(this);
    }
    

    componentDidMount() {
        try {
            let user = JSON.parse(localStorage.getItem(USER_DATA));    
            this.setState({userPublicId: user.publicId})
            
            //Get props
            let { onPreloaderToggled, onAppTokenAsked } = this.props

            //Start Preload
            onPreloaderToggled(true, "Chargement des établissements")

            //Load app token or Load schools
            if (!localStorage.getItem(APP_TOKEN)) {
                onAppTokenAsked(true)
            } else {
                this.onGetSchools()
                this.studentFavoriteUniversityList(user.publicId);
            }
        } catch (error) {
            console.log(error)
        }
    }

    deleteUniversityFromStudentPanel(data) {
        deleteStudentFavoriteUniversity(data).then(res=> {
            toast.success("University is removed from the favorite.")
            this.studentFavoriteUniversityList(data.publicId)
        }
        ).catch(err=>console.log(err))
    }

    studentFavoriteUniversityList(userPublicId) {
        getStudentFavoriteUniversityList({
            publicId:userPublicId,
            lookingFor:"university"
        }).then(resp=>this.setState({studentFavoriteUniversities:resp})).catch(err=>console.log(err));
    }

    addFavoriteInStudentPanel(data) {
        addUniversityInStudentFavoriteList(data).then(resp=> {
            toast.success("University is added in the favorite.")
            this.studentFavoriteUniversityList(data.publicId)
        }
        ).catch(err=>console.log(err))
    }


    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                //Get props
                let { tokenRetrieved, tokenFailed, onAppTokenRetrieved, onPreloaderToggled } = this.props

                //Handle props values
                if (tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    onAppTokenRetrieved(false)
                    this.onGetSchools()
                } else if (tokenFailed) {
                    onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {
            this.props.onSetSchools([])
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Get schools.
     * 
     */
    onGetSchools() {
        getSchools().then((response) => {
            if (response && response.data) {
                this.props.onSetSchools(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET SCHOOLS ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET SCHOOLS ERROR")
        });
    }

    /**
     * On Filtered. 
     * 
     * @param {*} data 
     */
    onFiltered = (data) => {
        try {
            let { levels, sectors, location } = this.data
            let { alls, onSetSchools } = this.props

            let searcheds_list = alls.filter((item) => {
                return (
                    (`${item.city} ${item.country.name}`).includes(location.trim())
                )
            })

            onSetSchools({ searcheds: searcheds_list })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Reseted.
     * 
     */
    onReseted = () => {
        try {
            let { alls, onSetSchools } = this.props
            onSetSchools({ searcheds: alls })
        } catch (error) {
            console.log(error)
        }
    }

   
    render() {

        const { schools, isPreloader } = this.props

        return (
            <React.Fragment>
                <div id="schools" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {(schools.length > 0) ? (
                        <div id="schools-content">
                            {/* Banner, Leading */}
                            <Banner bannerId="schools-banner">
                                <Leading
                                    title="Nos établissements"
                                    subtitle="Trouvez votre école ou université"
                                />
                            </Banner>

                            {/* Breadcrumbs */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Breadcrumbs
                                            subtitle="Annuaire"
                                            currentTile="Tous les établissements"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="ads mt-3">
                                <div className="x-leaderboard center-box">
                                    PUB LARGE ICI
                                </div>
                            </div>

                            {/* Contenu */}
                            <Section
                                subtitle="Liste de tous les établissements référencés"
                                sectionId="page-schools"
                                sectionClasses="page-schools py-5"
                            >
                                <FilterSchool onFiltered={this.onFiltered} onReseted={this.onReseted} />

                                <ListSchool items={schools} deleteUniversityFromStudentPanel={this.deleteUniversityFromStudentPanel} studentFavoriteUniversities={this.state.studentFavoriteUniversities} userPublicId={this.state.userPublicId} addFavoriteInStudentPanel={this.addFavoriteInStudentPanel} />
                            </Section>

                            <div className="ads mb-5">
                                <div className="hugeboard center-box">
                                    PUB EXTRA LARGE ICI
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12 py-5">
                                    <center>
                                        <p className="mb-0 p-2">
                                            La liste de nos établissements référencés bientôt disponible.
                                        </p>
                                        <p className="mb-0 p-2">
                                            Continuer sur{" "} <a href="/">l'accueil</a>
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }

}
Schools.propTypes = {
    alls: PropTypes.array,
    schools: PropTypes.array,
    onSetSchools: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
}
const mapStateToProps = state => ({
    alls: state.schools,
    schools: state.searched_schools,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetSchools: (data) => dispatch(setSchool(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Schools)
