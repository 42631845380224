import React, { useState } from "react";

// import bootstrap components
import { Form, Col } from 'react-bootstrap';

// import custom common components
import Selecter from '../../common/Selecter';
import Filter from '../../common/Filter';

// import dummies
import { DIPLOMES, SECTORS } from '../../../helpers/dummies';

const FilterSchool = ({ onFiltered, onReseted }) => {

    const [data, setData] = useState({});

    const onSelected = (selected, option) => {
        setData({ ...data, [option]: [selected] })
    }

    const onChanged = (e) => {
        if (e.target) {
            let { name, value } = e.target
            setData({ ...data, [name]: value })
        }
    }

    const onSubmited = (e) => {
        e.preventDefault();
        console.log(data, "ON SUBMIT DATA")
        onFiltered(data)
    }

    return (
        <Filter withReset withShadow onSubmited={onSubmited} onReseted={onReseted}>
            <Col lg={3} sm={12}>
                <Form.Group className="mb-0">
                    <Selecter
                        id="levels"
                        name="levels"
                        placeholder="Niveau d'étude"
                        selecterClasses="filters-selects"
                        selecterClassesPrefix="filters-selects-prefix"
                        selected={data.levels || []}
                        options={DIPLOMES}
                        onSelected={onSelected}
                    />
                </Form.Group>
            </Col>
            <Col lg={4} sm={12}>
                <Form.Group className="mb-0">
                    <Selecter
                        id="sectors"
                        name="sectors"
                        placeholder="Domaines d'étude"
                        selecterClasses="filters-selects"
                        selecterClassesPrefix="filters-selects-prefix"
                        selected={data.sectors || []}
                        options={SECTORS}
                        onSelected={onSelected}
                    />
                </Form.Group>
            </Col>
            <Col lg={3} sm={12}>
                <Form.Group className="mb-0 h-100">
                    <Form.Control
                        type="text"
                        id="location"
                        name="location"
                        className="form-control"
                        placeholder="Ville ou Pays"
                        onChange={onChanged}
                    />
                </Form.Group>
            </Col>
        </Filter>
    )

}
export default FilterSchool