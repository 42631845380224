import React from 'react';
import PropTypes from 'prop-types';
import {Container, Form, Col, Row, Button } from 'react-bootstrap';
import {getAllUniversities, getAllCountries, addOpportunity} from '../../services/opportunityService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { setAppTokenAsked} from '../../store/actions';
import { APP_TOKEN } from '../../helpers/constants';
import Banner from '../../components/common/Banner';
import Article from '../../assets/images/placeholders/article.jpg';

class AddOpportunity extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            name:'',
            universityPublicId:'',
            universityId: null,
            description:'',
            advantages:'',
            processToApply:'',
            opportunityDuration:'',
            beginDate:new Date(),
            highlight:null,
            countryId:null,
            city:'',
            image:'',
            message:'',
            getAllUniversityState:[],
            getAllCountryState:[],
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.getAllUniversity = this.getAllUniversity.bind(this);
        this.getAllCountry = this.getAllCountry.bind(this);
    }
    
    componentDidMount() {
        try {
            let { onAppTokenAsked } = this.props;
            if (!localStorage.getItem(APP_TOKEN)) {
                onAppTokenAsked(true)
            }
            else {
                this.getAllUniversity();
                this.getAllCountry();
            }
        }
        catch(error) {
            console.log(error, 'this is the erro')
        }
    }

    getAllUniversity() {
        getAllUniversities().then(response=>this.setState({getAllUniversityState:response?.universities})).catch(error=>console.log(error, 'this is new error'));
    }
  
    getAllCountry() {
        getAllCountries().then(response=>this.setState({getAllCountryState:response})).catch(error=>console.log(error, 'this is new error'));
    }

    onChanged(e) {
        if(e.target.name === "image") {
            this.setState({...this.state, [e.target.name]:e.target.files[0]})
        }
        else {
            this.setState({...this.state, [e.target.name]:e.target.value})
        }
    }
    
    onSubmit(e) {
        e.preventDefault();
        let newData = {...this.state};
        newData.beginDate = newData.beginDate.toISOString();
        console.log(newData, 'dklfhgklh')
        let formData = new FormData(e.target);

        addOpportunity(newData).then(resp=>{console.log(resp, 'this is the response'); this.setState({message:resp})}).catch(error=>console.log(error));
    }

    render() {
        return (
            <>
                <Banner
                bannerId="opportunity-banner"
                bannnerInnerClasses={{ backgroundImage: `url(${Article})` }}
                />
                <h1 className='text-center my-5'>Ajouter une opportunité</h1>  
                <Form onSubmit={this.onSubmit} className='mb-5'>
                    <Container>
                        <Row>
                            <Col xs="7" className='text-end'></Col>
                            <Col xs="3" className='text-end'>
                                <Form.Group className='d-flex flex-wrap align-items-center justify-content-between mb-0'>
                                    <Form.Label className='mb-0'>Après sélectionnée?</Form.Label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="highlight" name="highlight" onChange={(e)=>e.target.checked ? this.setState({highlight:1}): this.setState({highlight:0})} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs="2" className='text-end'>
                                <DatePicker showIcon selected={this.state.beginDate} onChange={(date) => this.setState({beginDate:date})} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Titre de l'opportunité</Form.Label>
                                    <Form.Control id="name" name="name" required onChange={this.onChanged} />
                                </Form.Group>
                                </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>carte d'identité de l'université</Form.Label>
                                    <Form.Control id="universityPublicId" name="universityPublicId" required onChange={this.onChanged} />
                                </Form.Group>
                                </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>université</Form.Label>
                                    <Form.Control as="select" required custom onChange={this.onChanged} name="universityId" id="universityId">
                                        <option value="" selected={this.state.universityId == null && 'selected'}>Veuillez sélectionner l'université</option>
                                        {this.state.getAllUniversityState?.map((university)=>(<option key={university.id} value={university.id}>{university.name}</option>))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Durée de l'opportunité (en jours)</Form.Label>
                                    <Form.Control id="opportunityDuration" name="opportunityDuration" required onChange={this.onChanged} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Control as="select" required custom onChange={this.onChanged} name="countryId" id="countryId">
                                        {this.state.getAllCountryState?.map((country)=>(<option key={country.id} value={country.id}>{country.name}</option>))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Ville</Form.Label>
                                    <Form.Control id="city" name="city" required onChange={this.onChanged} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={4} id="description" name="description" required onChange={this.onChanged} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Avantages</Form.Label>
                                    <Form.Control as="textarea" rows={4} id="advantages" name="advantages" required onChange={this.onChanged} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Processus de candidature</Form.Label>
                                    <Form.Control as="textarea" rows={4} id="processToApply" name="processToApply" required onChange={this.onChanged} />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Image</Form.Label>
                                    <input type="file" className="form-control" id="image" name="image" onChange={this.onChanged} />
                                </Form.Group>
                                <Button type="submit" className="btn-submit btn-commenter">le commentaire</Button>
                                {this.state.message?.errors && this.state.message?.errors[0] && <div className='text-danger fw-bold mt-3'>{this.state.message?.errors[0]}</div>}
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </>
        )
    }
}
AddOpportunity.propTypes = {
    onAppTokenAsked: PropTypes.func,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
}
const mapStateToProps = state => ({
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
})
const mapDispatchToProps = dispatch => ({
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddOpportunity)