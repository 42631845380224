import { APP_TOKEN } from '../helpers/constants'// import constants

// const BASE_URL = "https://api-dev.aceorientation.com/api/"
// const FAKE_BASE_URL = "http://api-aceo.herokuapp.com/api/"

// const BASE_URL = "http://127.0.0.1:8000/api/"
// const BASE_URL = "http://192.168.0.144:8080/api/"
const BASE_URL = "https://aceapi.digital4design.in/api/"
// const BASE_URL = "http://192.168.146.170:8080/api/"
//const FAKE_BASE_URL = "http://127.0.0.1:8000/api/"

const headers = (authentified = true, acceptJson = true) => {
    let apiHeader = acceptJson ? { Accept: 'application/json', 'Content-Type': 'application/json' } : {}
    if (authentified) apiHeader.Authorization = 'Bearer ' + localStorage.getItem(APP_TOKEN)
    return apiHeader
}

export const upload = (route, data, method, authentified = false) => {
    return fetch((`${BASE_URL}${route}`), {
        method: method,
        headers: headers(authentified, false),
        body: data
    }).then(
        response => response.json()
    ).then(
        json => json
    ).catch(
        (error) => console.error(error)
    )
}

export const get = (route, authentified = true) => {
    return fetch((`${BASE_URL}${route}`), {
        method: 'GET',
        headers: headers(authentified)
    }).then(
        response => response.json()
    ).then(
        json => json
    ).catch(
        (error) => console.error(error)
    )
}

export const post = (route, data, authentified = true) => {
    return fetch((`${BASE_URL}${route}`), {
        method: 'POST',
        headers: headers(authentified),
        body: JSON.stringify(data),
    }).then(
        response => response.json()
    ).then(
        json => json
    ).catch(
        (error) => console.error(error)
    )
}

export const patch = (route, data, authentified = true) => {
    return fetch((`${BASE_URL}${route}`), {
        method: 'PATCH',
        headers: headers(authentified),
        body: JSON.stringify(data),
    }).then(
        response => response.json()
    ).then(
        json => json
    ).catch(
        (error) => console.error(error)
    )
}

export const put = (route, data, authentified = true) => {
    return fetch((`${BASE_URL}${route}`), {
        method: 'PUT',
        headers: headers(authentified),
        body: (data !== "") ? JSON.stringify(data) : data
    }).then(
        response => response.json()
    ).then(
        json => json
    ).catch(
        (error) => console.error(error)
    )
}

export const destroy = (route, authentified = true) => {
    return fetch((`${BASE_URL}${route}`), {
        method: 'DELETE',
        headers: headers(authentified)
    }).then(
        response => response.json()
    ).then(
        json => json
    ).catch(
        (error) => console.error(error)
    )
}
