import React from 'react'
import PropTypes from 'prop-types'

// import carousel
import Carousel from "react-multi-carousel"

// RESPONSIVES
export const RESPONSIVES = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 5, partialVisibilityGutter: 30 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 3, partialVisibilityGutter: 30 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1, partialVisibilityGutter: 30 }
};

const Sliders = ({ children, slidersClasses, responsiveOptions, withArrows, withDots, itemsToSlide, infiniteLooped }) => {

    return (
        <Carousel
            additionalTransfrom={0} autoPlaySpeed={3000} centerMode={false} autoPlay={false}
            arrows={withArrows} showDots={withDots} className={slidersClasses}
            containerClass="sliders" sliderClass="slider-class" itemClass="slider-item" dotListClass=""
            draggable swipeable keyBoardControl minimumTouchDrag={80} renderDotsOutside={false}
            partialVisible={false} focusOnSelect={false} renderButtonGroupOutside={false}
            infinite={infiniteLooped} responsive={responsiveOptions} slidesToSlide={itemsToSlide}
            removeArrowOnDeviceType={["tablet", "mobile"]}
        >
            {(children != null) && children}
        </Carousel>
    )

}
Sliders.propTypes = {
    children: PropTypes.node,
    slidersClasses: PropTypes.string,
    withArrows: PropTypes.bool,
    withDots: PropTypes.bool,
    infiniteLooped: PropTypes.bool,
    itemsToSlide: PropTypes.number,
    responsiveOptions: PropTypes.object
}
Sliders.defaultProps = {
    children: null,
    slidersClasses: "",
    withArrows: true,
    withDots: false,
    infiniteLooped: true,
    itemsToSlide: 2,
    responsiveOptions: RESPONSIVES
}
export default Sliders
