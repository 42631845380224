import React from "react"

// import router
import { Route, Redirect } from "react-router-dom"

// import constants
import { USER_DATA } from '../helpers/constants'

const AppRoute = ({ component: Component, layout: Layout, isProtected, ...rest }) => (
  <Route {...rest}
    render={props => {
      if (isProtected && !localStorage.getItem(USER_DATA)) {
        return (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)
export default AppRoute