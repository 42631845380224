import { get, post } from './baseService';

export function getOpportunities() {
    return get('opportunities', true).then((response) => response)
}

export function getOpportunity(code) {
    return get(`opportunities/${code}`, true).then((response) => response)
}
export function getAllOpportunitiesAddedByUniversity(data) {
    return post('get-offer-added-by-university', data, false).then(response=>response);
}

export function getStudentFavoriteOpportunitiesList(data) {
    // console.log(data, 'get user opport')
    return post('users/get-favorite', data, true).then((response) => response)
}

export function deleteStudentFavoriteOpportunity(data) {
    return post('users/delete-favorite', data, true).then(response=>response)
}

export function addOpportunityInStudentFavoriteList(data) {
    return post('users/add-favorite', data, true).then(response=>{
        return response;
    })
}

export function getAllUniversities() {
    return post('get-all-universities', false).then(response=>response);
}

export function getAllCountries() {
    return post('get-countries', false).then(response=>response);
}

export function addOpportunity(data) {
    return post('add-opportunity', data, false).then(response=>response);
}
export function redeemOffer(data) {
    return post('redeem-offer', data, false).then(resp=>resp)
}