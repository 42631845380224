import React from 'react'
import PropTypes from 'prop-types'

const Leading = ({ leadingClasses, title, subtitle, children }) => {
    return (
        <div className={`leading ${leadingClasses}`}>
            {(title && title !== "") ? (
                <h1 className="text-white text-center"> {title} </h1>
            ) : null}

            {(subtitle && subtitle !== "") ? (
                <p className="text-white text-center"> {subtitle} </p>
            ) : null}

            {(children != null) && children}
        </div>
    )
}
Leading.propTypes = {
    leadingClasses: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.node
}
Leading.defaultProps = {
    leadingClasses: "",
    title: "",
    subtitle: "",
    children: null
}
export default Leading
