import React, { useState } from "react";
import PropTypes from 'prop-types';

// import components
import { Form, Col } from 'react-bootstrap';

// import custom components.
import Filter from "../common/Filter";
import Selecter from "../common/Selecter";

// import data
import { DIPLOMES, SECTORS } from "../../helpers/dummies";

const Searcher = ({ col }) => {

    const [data, setData] = useState({});

    const onSelected = (selected, option) => {
        setData({ ...data, [option]: [selected] })
    }

    const onChanged = (e) => {
        if (e.target) {
            let { name, value } = e.target
            setData({ ...data, [name]: value })
        }
    }

    const onSubmited = (e) => {
        e.preventDefault();
        console.log(data, "ON SUBMIT DATA")
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className={`col-lg-${col}`}>
                    <Filter withShadow onSubmited={onSubmited} submitLabel="Rechercher">
                        <Col lg={3} sm={12}>
                            <Form.Group className="mb-0">
                                <Selecter
                                    id="diplomas"
                                    name="diplomas"
                                    placeholder="Niveau d'étude"
                                    selecterClasses="filters-selects"
                                    selecterClassesPrefix="filters-selects-prefix"
                                    selected={data.diplomas || []}
                                    options={DIPLOMES}
                                    onSelected={onSelected}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4} sm={12}>
                            <Form.Group className="mb-0">
                                <Selecter
                                    id="sectors"
                                    name="sectors"
                                    placeholder="Filière"
                                    selecterClasses="filters-selects"
                                    selecterClassesPrefix="filters-selects-prefix"
                                    selected={data.sectors || []}
                                    options={SECTORS}
                                    onSelected={onSelected}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={3} sm={12}>
                            <Form.Group className="mb-0 h-100">
                                <Form.Control
                                    type="text"
                                    id="location"
                                    name="location"
                                    className="form-control"
                                    placeholder="Ville ou Pays"
                                    onChange={onChanged}
                                />
                            </Form.Group>
                        </Col>
                    </Filter>
                </div>
            </div>
        </div>
    )

}
Searcher.propTypes = {
    col: PropTypes.number,
}
Searcher.defaultProps = {
    col: 10,
}
export default Searcher