import React from 'react'
import PropTypes from 'prop-types'

// import router
import { Link, withRouter } from "react-router-dom"

// import components
import { Form, Col, Button } from 'react-bootstrap';

// import services
import { updatePasswordUsingToken, getTokenToResetPassword } from '../../services/authService';

// import constants
import { CREDENTIALS_ERROR, RESET_PASSWORD_ERROR, UNKNOWN_ERROR, USER_DATA } from '../../helpers/constants';

// import toaster
import { toast } from 'react-toastify';

// import common components
import Loader from '../../components/common/Loader';

// const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
// const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{4,})");

class ResetPassword extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            failure: "",
            success:"",
            backgroundColor: "#4285F4",
            passwordStrengthMessage:"",
            passwordError:"",
            confirmPasswordError:"",
            inputs: { token: "", new_password: "", confirm_password: "" }
        }
    }
    
    componentDidMount() {
        const searchParams = new URLSearchParams(document.location.search)
        let token = searchParams.get('token')
        this.setState(prevState => ({
            inputs: {
                ...prevState.inputs,
                token: token
            }
          }))
     }
 

    onChanged = (e) => {
        if (e.target) {
            let { name, value } = e.target
            this.setState({
                inputs: { ...this.state.inputs, [name]: value }
            })
        }
    }

    handleValidation = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        if(passwordInputFieldName === 'new_password'){
            const uppercaseRegExp   = /(?=.*?[A-Z])/;
            const lowercaseRegExp   = /(?=.*?[a-z])/;
            const digitsRegExp      = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp   = /.{8,}/;
            const passwordLength =      passwordInputValue.length;
            const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
            const digitsPassword =      digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
            let errMsg ="";

            if(passwordLength===0){
                    errMsg="Password is empty";
            }
            else if(!uppercasePassword){
                    errMsg="At least one Uppercase";
            }
            else if(!lowercasePassword){
                    errMsg="At least one Lowercase";
            }
            else if(!digitsPassword){
                    errMsg="At least one digit";
            }
            else if(!specialCharPassword){
                    errMsg="At least one Special Characters";
            }
            else if(!minLengthPassword){
                    errMsg="At least minumum 8 characters";
            }
            else{
                errMsg="";
            }
            this.setState({passwordError:errMsg,  backgroundColor:'#ff0000'});
        }
        
        if(passwordInputFieldName === "confirm_password" || (passwordInputFieldName === "new_password" && this.state.inputs.confirm_password.length>0)  ){        
            if(this.state.inputs.confirm_password!==this.state.inputs.new_password){
                this.setState({confirmPasswordError:"Confirm password is not matched", backgroundColor:'#ff0000'});
            }
            else{
                this.setState({confirmPasswordError:"", backgroundColor:'#4285f4'});
            }
        }
    }

    onSubmited = (e) => {
        e.preventDefault();

        // Update state
        this.setState({ loading: true, failure: "" })

        // Launch login
        this.onResetPassword(this.state.inputs)
    }

    onResetPassword(data) {
        setTimeout(()=>{
            this.state.inputs.token !=="" && this.state.inputs.new_password === this.state.inputs.confirm_password ?
                updatePasswordUsingToken(data).then((response) => {
                    if (!response?.errors) {
                        this.setState({ loading: false, failure: "", success: response[0].message, backgroundColor:"", passwordStrengthMessage:"" });
                        const {history} = this.props;
                        history.push("/login");
                    }
                    else if (response.errors && response.errors[0] && (response.errors[0] === RESET_PASSWORD_ERROR)) {
                        this.setState({ loading: false, failure: RESET_PASSWORD_ERROR, success: "", backgroundColor:"", passwordStrengthMessage:"" })
                    }
                    else {
                        this.setState({ loading: false, failure: UNKNOWN_ERROR, success: "", backgroundColor:"", passwordStrengthMessage:"" })
                    }
                }).catch((error) => {
                    this.setState({ loading: false, failure: UNKNOWN_ERROR })
                })
                :
            this.setState({...this.state, failure:"Password do not match"})
        },1000)
    }

    render() {

        const { loading, passwordStrengthMessage, inputs, backgroundColor, confirmPasswordError, passwordError, success, failure } = this.state;

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row flex-column align-items-center justify-content-center">
                        <div className="col-lg-6 pt-5">
                            <h3 className="text-center mb-1">
                                Se connecter sur ACE Orientation
                            </h3>
                            <center>
                                <small className="text-muted text-center">
                                    Bénéficiez des services ACE pour construire un projet
                                    académique gagnant en Afrique et dans le monde
                                </small>
                            </center>
                        </div>
                        <div className="col-lg-6 ofconfirm_passwordfset-lg-3">
                            <Form onSubmit={this.onSubmited} className="auth-form">
                                <Form.Group className="mb-3">
                                    <Form.Label>nouveau mot passe</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="new_password"
                                        name="new_password"
                                        placeholder="Entrez votre mot de passe"
                                        required
                                        onChange={this.onChanged}
                                        onKeyUp={this.handleValidation}
                                        style={{ borderColor: backgroundColor }}
                                    />
                                    <span style={{color:backgroundColor}}>{passwordError}</span>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>confirmer le nouveau mot de passe</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="confirm_password"
                                        name="confirm_password"
                                        placeholder="entrez votre mot de passe de confirmation"
                                        required
                                        onChange={this.onChanged}
                                        onKeyUp={this.handleValidation}
                                        style={{ borderColor: backgroundColor }}
                                    />
                                    <span style={{color:backgroundColor}}>{confirmPasswordError}</span>
                                </Form.Group>

                                {(failure !== "") ? (
                                    <div className="alert alert-danger" role="alert">
                                        {failure}
                                    </div>
                                ) : null}

                                {(success !== "") ? (
                                    <div className="alert alert-success" role="alert">
                                        {success}
                                    </div>
                                ) : null}

                                {loading ? (
                                    <Loader color="blue" />
                                ) : 
                                    <button className="btn-discover" type="submit" disabled={inputs.new_password !== inputs.confirm_password}>
                                        Réinitialiser le mot de passe
                                    </button>
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(ResetPassword)
