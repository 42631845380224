import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"

// import store
import { connect } from 'react-redux'

// import store actions
import { setAppTokenAsked, setAppTokenRetrieved, 
    setArticle, setFormation, togglePreloader 
} from '../../store/actions'

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment';
import 'moment/locale/fr';

// import services
import { getArticle, getOpportunity } from '../../services/opportunityService'
import { getFormation } from '../../services/formationService'

// import dummies
import { OPPORTUNITY } from '../../helpers/dummies'

// import common components
import Banner from '../../components/common/Banner'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import Section from '../../components/common/Section'
import Square from '../../components/common/Square'

// import constants
import { APP_TOKEN } from '../../helpers/constants'

// import utilities
import { isUnauthenticatedRequest } from '../../helpers/utilities'


class Article extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        try {
            this.props.onPreloaderToggled(true, "Chargement de l'article")
            this.onGetArticle()
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                if (this.props.tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    this.props.onAppTokenRetrieved(false)
                    this.onGetArticle()
                } else if (this.props.tokenFailed) {
                    this.props.onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        this.props.onSetArticle({})
    }

    /**
     * Get article.
     * 
     */
    onGetArticle() {
        let { articleCode } = this.props.match.params
        getOpportunity(articleCode).then((response) => {
            if (response && response.publicId && (response.publicId === articleCode)) {
                this.props.onSetArticle({
                    ...response,
                    image: response.image ? response.image : OPPORTUNITY.image,
                })
                this.onGetFormations()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET ARTICLE ERROR")
            }
        }).catch((error) => {
            console.error(error, "GET ARTICLE ERROR")
        });
    }

    /**
     * Get formations.
     * 
     */
    onGetFormations() {
        getFormation().then((response) => {
            if (response && response.data) {
                this.props.onSetFormations(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET FORMATIONS ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET FORMATIONS ERROR")
        });
    }

    render() {

        const { article, formations, isPreloader } = this.props

        return (
            <React.Fragment>
                <div id="article" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {(article && article.publicId) ? (
                        <div id="article-content">
                            {/* Banner */}
                            <Banner
                                bannerId="opportunity-banner"
                                bannnerInnerClasses={{ backgroundImage: `url(${article.image})` }}
                            />

                            <div className="container">
                                <div className="row">
                                    {/* Breadcrumbs */}
                                    <div className="col-lg-12">
                                        <Breadcrumbs
                                            subtitle="Articles"
                                            subtitleLink="/opportunites"
                                            currentTile="Détails d'un article"
                                        />
                                    </div>

                                    {/* Contenu */}
                                    <div className="col-lg-9 my-5">
                                        <div className="box-single single-shadow p-4">
                                            <article>
                                                <h2 className="single-title">
                                                    {article.name}
                                                </h2>

                                                <div dangerouslySetInnerHTML={{ __html: article.description }} />
                                                <div dangerouslySetInnerHTML={{ __html: article.observation }} />
                                            </article>

                                            <Section
                                                subtitle="Autres publications"
                                                sectionId="others-articles"
                                                sectionClasses="others-articles py-3"
                                                sectionInnerClasses=""
                                            >

                                            </Section>
                                        </div>
                                    </div>

                                    {/* Menu latéral */}
                                    <div className="col-lg-3 my-5">
                                        <div className="ads">
                                            <div className="rectangle center-box">
                                                PUB LATÉRAL ICI
                                            </div>
                                        </div>

                                        <Square squareClasses="my-3">
                                            <h3 className="square-title">Les dernières formations</h3>
                                            <div className="square-item square-recents">
                                                {(formations && formations.slice(0, 6).map((item, index) => (
                                                    <div key={`domain_item_${index}`} className="recent-item clearfix">
                                                        {item.image ? (
                                                            <img src={item.image} alt={`domain ${index}`} />
                                                        ) : null}
                                                        <h4>
                                                            <a href="#">
                                                                {(item.name.length > 25) ? (item.name.substr(0, 24) + "...") : item.name}
                                                            </a>
                                                        </h4>
                                                        <time dateTime={item.createdAt}>
                                                            {moment(item.createdAt, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY')}
                                                        </time>
                                                    </div>
                                                )))}
                                            </div>
                                        </Square>

                                        <div className="ads">
                                            <div className="interstitial center-box">
                                                PUB LATÉRAL ICI
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12">
                                    <center>
                                        <p>Cet article est introuvable.</p>
                                        <p className="mb-0">
                                            Continuer sur{" "}
                                            <a href="/">l'accueil</a> ou sur la liste des{" "}
                                            <a href="/opportunites">opportunités</a>.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }

}
Article.propTypes = {
    article: PropTypes.object,
    onSetArticle: PropTypes.func,
    onSetFormations: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
    onAppTokenAsked: PropTypes.func,
    onAppTokenRetrieved: PropTypes.func,
    onPreloaderToggled: PropTypes.func,
}
const mapStateToProps = state => ({
    article: state.article,
    formations: state.formations,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetArticle: data => dispatch(setArticle(data)),
    onSetFormations: data => dispatch(setFormation(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Article)