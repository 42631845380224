// import constants
import {
    ARTICLES_LIST, ARTICLES_SHOW, COUNTRIES_LIST, COUNTRIES_SHOW, DOMAINS_LIST, DOMAINS_SHOW, OPPORTUNITIES_LIST, OPPORTUNITIES_SHOW,
    SCHOOLS_LIST, SCHOOLS_SHOW, SUB_DOMAINS_LIST, SUB_DOMAINS_SHOW, FORMATIONS_LIST, FORMATIONS_SHOW, MEMBERS_LIST, SLIDERS_LIST,
    SOCIALS_LIST, VISIONS_LIST, APP_TOKEN_ASKED, APP_TOKEN_RETRIEVED, TOGGLE_PRELOADER, SCHOOLS_SEARCHES, FAVORITE_SCHOOLS_LIST, FAVORITE_OPPORTUNITY_LIST
} from "../constants/constants"

// import list constants
import { SLIDERS, SOCIALS, VISIONS } from "../../helpers/constants"

// Init state
const INITIAL_STATE = {
    sliders: SLIDERS, visions: VISIONS, socials: SOCIALS, members: [], searched_schools: [], schools: [], school: {},
    countries: [], country: {}, domains: [], domain: {}, formations: [], formation: {}, subDomains: [], subDomain: {},
    opportunities: [], opportunity: {}, articles: [], article: {}, tokenAsked: false, tokenRetrieved: false, tokenFailed: false,
    preloadMessage: "Chargement", isPreloader: false,
}

const rootReducer = (state = INITIAL_STATE, action) => {

    const { payload, type } = action

    switch (type) {
        //HOME
        case SLIDERS_LIST:
            return { ...state, sliders: payload.data }
        case VISIONS_LIST:
            return { ...state, visions: payload.data }
        case MEMBERS_LIST:
            return { ...state, members: payload.data }
        case SOCIALS_LIST:
            return { ...state, socials: payload.data }

        //SCHOOLS
        case SCHOOLS_LIST:
            return { ...state, schools: payload.data, searched_schools: payload.data }
        case SCHOOLS_SEARCHES:
            return { ...state, searched_schools: payload.data }
        case SCHOOLS_SHOW:
            return { ...state, school: payload.data }

        //SCHOOLS
        case FAVORITE_SCHOOLS_LIST:
            return { ...state, favorite_schools: payload.data }

        //SCHOOLS
        case FAVORITE_OPPORTUNITY_LIST:
            return { ...state, favorite_opportunity: payload.data }

        //COUNTRIES
        case COUNTRIES_LIST:
            return { ...state, countries: payload.data }
        case COUNTRIES_SHOW:
            return { ...state, country: payload.data }

        //DOMAINS    
        case DOMAINS_LIST:
            return { ...state, domains: payload.data }
        case DOMAINS_SHOW:
            return { ...state, domain: payload.data }

        //FORMATIONS    
        case FORMATIONS_LIST:
            return { ...state, formations: payload.data }
        case FORMATIONS_SHOW:
            return { ...state, formation: payload.data }

        //SUBDOMAINS    
        case SUB_DOMAINS_LIST:
            return { ...state, subDomains: payload.data }
        case SUB_DOMAINS_SHOW:
            return { ...state, subDomain: payload.data }

        //OPPORTUNITIES
        case OPPORTUNITIES_LIST:
            return { ...state, opportunities: payload.data }
        case OPPORTUNITIES_SHOW:
            return { ...state, opportunity: payload.data }

        //ARTICLES
        case ARTICLES_LIST:
            return { ...state, articles: payload.data }
        case ARTICLES_SHOW:
            return { ...state, article: payload.data }

        //APP TOKEN    
        case APP_TOKEN_ASKED:
            return { ...state, tokenAsked: payload.asked }
        case APP_TOKEN_RETRIEVED:
            return { ...state, tokenRetrieved: payload.retrieved, tokenFailed: payload.failed }

        //PRELOADER
        case TOGGLE_PRELOADER:
            return { ...state, isPreloader: payload.preload, preloadMessage: payload.message }

        //DEFAULT    
        default:
            return { ...state }
    }

}

export default rootReducer