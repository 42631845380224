import React, { useState } from 'react'

import { Form, Col } from 'react-bootstrap'

import Selecter from '../../common/Selecter'

import { CATEGORIES, LEVELS, LOCATIONS, REMAINING_TIME } from '../../../helpers/dummies'

import Filter from '../../common/Filter'

const FilterOpportunity = () => {

    const [data, setData] = useState({});

    const onSelected = (selected, option) => {
        setData({ ...data, [option]: [selected] })
    }

    const onSubmited = (e) => {
        e.preventDefault();
        console.log(data, "ON SUBMIT DATA")
    }

    return (
        <Filter withReset withShadow onSubmited={onSubmited}>
            <Col lg={2} sm={12}>
                <Form.Group className="mb-0">
                    <Selecter
                        id="categories"
                        name="categories"
                        placeholder="Catégorie"
                        selecterClasses="filters-selects"
                        selecterClassesPrefix="filters-selects-prefix"
                        selected={data.categories || []}
                        options={CATEGORIES}
                        onSelected={onSelected}
                    />
                </Form.Group>
            </Col>
            <Col lg={2} sm={12}>
                <Form.Group className="mb-0">
                    <Selecter
                        id="locations"
                        name="locations"
                        placeholder="Pays"
                        selecterClasses="filters-selects"
                        selecterClassesPrefix="filters-selects-prefix"
                        selected={data.locations || []}
                        options={LOCATIONS}
                        onSelected={onSelected}
                    />
                </Form.Group>
            </Col>
            <Col lg={2} sm={12}>
                <Form.Group className="mb-0">
                    <Selecter
                        id="levels"
                        name="levels"
                        placeholder="Éducation min."
                        selecterClasses="filters-selects"
                        selecterClassesPrefix="filters-selects-prefix"
                        selected={data.levels || []}
                        options={LEVELS}
                        onSelected={onSelected}
                    />
                </Form.Group>
            </Col>
            <Col lg={2} sm={12}>
                <Form.Group className="mb-0">
                    <Selecter
                        id="nationalities"
                        name="nationalities"
                        placeholder="Nationalité(s)"
                        selecterClasses="filters-selects"
                        selecterClassesPrefix="filters-selects-prefix"
                        selected={data.nationalities || []}
                        options={LOCATIONS}
                        onSelected={onSelected}
                    />
                </Form.Group>
            </Col>
            <Col lg={2} sm={12}>
                <Form.Group className="mb-0">
                    <Selecter
                        id="duedates"
                        name="duedates"
                        placeholder="Temps restant"
                        selecterClasses="filters-selects"
                        selecterClassesPrefix="filters-selects-prefix"
                        selected={data.duedates || []}
                        options={REMAINING_TIME}
                        onSelected={onSelected}
                    />
                </Form.Group>
            </Col>
        </Filter>
    )

}
export default FilterOpportunity