import { BOURSES_100, BOURSES_50, SLUG_FROM, SLUG_TO, TOKEN_ERROR } from "./constants"
import { FAQS } from "./dummies";

/**
 * Calculate col number based on value.
 * 
 * @returns int
 */
export const calculateCol = (value) => {
    return ((value > 0) ? Math.floor(12 / value) : 12)
}

/**
 * Random number.
 * 
 * @param {*} threshold 
 * @returns 
 */
export const randomlyNumber = (threshold = 10) => {
    return Math.floor((Math.random() * threshold) + 1);
}

/**
 * Check if request is unauthenticated.
 * 
 * @param {*} response 
 * @returns bool
 */
export const isUnauthenticatedRequest = (response) => {
    return (response && response.message && (response.message.toLowerCase() === TOKEN_ERROR.toLowerCase()));
}

/**
 * Get object[attribut] with [key]
 * 
 * @param {*} key 
 * @param {*} attribut 
 * @returns string
 */
export const getObjectByKey = (key, attribut = "") => {
    switch (key) {
        case BOURSES_100.key:
            return (attribut !== "") ? BOURSES_100[attribut] : BOURSES_100
        case BOURSES_50.key:
            return (attribut !== "") ? BOURSES_50[attribut] : BOURSES_50
        default:
            return ""
    }
}

/**
 * Slugify Me.
 * 
 * @param {*} text 
 * @returns 
 */
export const slugify = (text) => {
    try {
        return text
            .toString()               // Cast to string (optional)
            .normalize('NFKD')        // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
            .toLowerCase()            // Convert the string to lowercase letters
            .trim()                   // Remove whitespace from both sides of a string (optional)
            .replace(/\s+/g, '-')     // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-');  // Replace multiple - with single -
    } catch (error) {
        console.log(error)
        return ""
    }
}

/**
 * Format faqs for display.
 * 
 * @param {*} replaceValue 
 * @returns 
 */
export const formatFaqsForDisplay = (replaceValue) => {
    try {
        return FAQS.map((item) => ({
            question: item.question.replace("(X)", replaceValue)
        }))
    } catch (error) {
        console.log(error)
        return FAQS
    }
}