import { explore, compare, decide, apply, slider1, slider2, slider3, slider4 } from "../assets/images"

// App Name
export const APP_NAME = "ACE Orientation"


/**
 * Local Storage
 * 
 * User, Token, Language
 */
export const USER_DATA = "USER_DATA"
export const USER_TOKEN = "USER_TOKEN"
export const APP_TOKEN = "APP_TOKEN"
export const I18N_LANGUAGE = "I18N_LANGUAGE"
export const USER_EMAIL_VERIFY_CODE = "Code is not available or expired";


/**
 * Buttons
 */
export const SEE_ALL = "Voir tout"
export const SEE_MORE = "Voir plus"
export const DISCOVER = "Découvrir"


/**
 * List
 */
export const ITEMS_TO_DISPLAY_SMALL = 2
export const ITEMS_TO_DISPLAY_XS = 3
export const ITEMS_TO_DISPLAY_SM = 4
export const ITEMS_TO_DISPLAY_MD = 8
export const ITEMS_TO_DISPLAY_LG = 20
export const ITEMS_TO_DISPLAY_XL = 1


/**
 * Country
 */
// Links
export const COUNTRY_INFOS_LINK = "Infos pays"
export const COUNTRY_SCHOOLS_LINK = "Liste des universités"

// Regions
export const COUNTRY_AFRICAN = { key: "afrique", slug: "les-pays-africains", label: "Étudier en Afrique" }
export const COUNTRY_FOREIGN = { key: "foreign", slug: "les-pays-etrangers", label: "Etudier à l'étranger", subtitle: "Découvrir le profil des entreprises qui recrutent en Afrique" }
export const COUNTRY_WORLD = { key: "monde", slug: "les-pays-non-africains", label: "Étudier ailleurs dans le monde" }

// Tabs
export const COUNTRY_TABS = [
    { key: "presentation", label: "Présentation", icon: "slideshow" },
    { key: "trainingInformation", label: "Enseignement supérieur", icon: "detail" },
    { key: "studentLife", label: "Vie pratique & étudiante", icon: "group" },
    { key: "condition", label: "Conditions d'immigration et de séjour", icon: "building-house" },
    { key: "top10", label: "TOP 10", icon: "trophy" }
]


/**
 * Errors Messages
 * 
 * UNKNOWN, TOKEN_ERROR
 */
export const UNKNOWN_ERROR = "Une erreur est survenue. Veuillez réessayer."
export const CREDENTIALS_ERROR = "Identifiant(s) de connexion incorrect(s)."
export const TOKEN_ERROR = "Unauthenticated."
export const LOGIN_ERROR = "INCORRECT E-MAIL"
export const RESET_PASSWORD_ERROR = "Token Expired"
export const CONFLICT_ERROR = "L'email existe déjà."


/**
 * Opportunities
 */
export const BOURSES_100 = { key: "100", name: "bourses complètes" }
export const BOURSES_50 = { key: "50", name: "bourses partielles" }


/**
 * SOCIALS NETWOKS
 */
export const SOCIALS = [
    { name: "facebook", icon: "facebook", link: "https://www.facebook.com/aceorientation" },
    { name: "twitter", icon: "twitter", link: "https://twitter.com/aceorientation?lang=en" },
    { name: "youtube", icon: "youtube", link: "https://www.youtube.com/channel/UCkfutUuSlZx7-i8iRGkTa5Q" },
    { name: "linkedin", icon: "linkedin-square", link: "https://www.linkedin.com/company/ace-orientation" },
    { name: "instagram", icon: "instagram", link: "https://www.instagram.com/aceorientation" },
    { name: "telegram", icon: "telegram", link: "https://t.me/aceorientation" },
]


/**
 * VISIONS
 */
export const VISIONS = [
    { name: "S’orienter", image: explore, description: "Bénéficiez de nos services d’orientation pour construire des projets académiques et professionnels gagnants !" },
    { name: "Comparer", image: compare, description: "Retrouvez des informations sur les offres de formation des meilleurs établissements pour vous aider à faire un choix averti." },
    { name: "Décider", image: decide, description: "Entrez en contact avec les établissements, échangez avec d'autres étudiants et nos coachs au sein des groupes privés ACE Orientation !" },
    { name: "Postuler", image: apply, description: "Inscrivez-vous ou à l’international avec l’aide de  partenaires experts dans les projets d'études internationaux." }
]


// SLIDERS
export const SLIDERS = [
    { image: slider1, isVideo: false, alt: "home slider 1" },
    { image: slider2, isVideo: false, alt: "home slider 2" },
    { image: slider3, isVideo: false, alt: "home slider 3" },
    { image: slider4, isVideo: true }
]


//Student Status
export const STUDENT_STATUS = [
    'étudiant.e en formation initiale',
    'étudiant.e en formation continue',
    'retour à la formation après plusieurs année d’expérience professionnelle',
    'parent ou tuteur/trice',
    'professeur ou autre personnel académique',
    'un agent d’inscription ou assimilié'
];
// SIDEBAR MENU
export const SIDEBAR_MENU_ITEMS = [
    { label: "Mon profil", link: "/account", icon: "user", endIcon:'' },
    { label: "Messagerie", link: "", icon: "comment-dots", endIcon:'' },
    { label: "Mes documents", link: "", icon: "file", endIcon:'' },
    { label: "Carte des métiers", link: "", icon: "briefcase", endIcon:'' },
    { label: "Services d’orientation", link: "", icon: "user", endIcon:'' },
    { label: "Favoris", link: "/favorites", icon: "like", endIcon:'' },
    { label: "Candidatures en cours", link: "/", icon: "file", endIcon:'' },
    { label: "Gérer le compte et les abonnements", link: "/student-offers", icon: "user-pin", endIcon:'' },
    // { label: "Accueil compte", link: "/account", icon: "home", endIcon:'' },
    // { label: "Candidatures et favoris", link: "", icon: "heart", endIcon:'' },
    // { label: "Parcours orientation", link: "", icon: "hive", endIcon:'' },
    // { label: "Parcours projet professionel", link: "", icon: "sitemap", endIcon:'' },
    // { label: "Défis Pro", link: "", icon: "bolt-circle", endIcon:'' },
    // { label: "Communauté", link: "", icon: "group", endIcon:'' },
    // { label: "Comptes - Mes abonnements", link: "/student-offers", icon: "bookmarks", endIcon:'' },
    { label: "Se déconnecter", link: "", icon: "bookmarks", endIcon:'' }
]

// PARENT USEFUL LINKS
export const SIDEBAR_PARENT_LINKS = [
    { label: "Partagez vos commentaires avec ACE", link: "#" },
    { label: "Contactez ACE", link: "#" },
    { label: "Liens CGU/TDR", link: "#" },
    { label: "Contactez l'établissement", link: "#" }
]

// USER PROFILE
export const USER_PARENT = { key: "parent", label: "Parent.e d'élève" }
export const USER_STUDENT = { key: "student", label: "Étudiant.e" }
export const USER_TEACHER = { key: "teacher", label: "Enseignant.e" }
export const USER_EDUCATOR = { key: "educator", label: "Professionnel.le de l'éducation" }


// GCU
export const CONTACT_GCU_CONDITIONS = "J'ai lu les CGU et la Charte des données personnelles et j’accepte d'être contacté directement par l'établissement"


// CONTACT MESSAGE OBJECTS
export const CONTACT_OBJECTS = [
    { key: "1", label: "Demande de brochure" },
    { key: "2", label: "Dates du programme" },
    { key: "3", label: "Modalités d'inscription" },
    { key: "4", label: "Coût/Paiement/Financement" },
    { key: "5", label: "Services aux étudiants autres" },
]


// SLUG VALUES
export const SLUG_FROM = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
export const SLUG_TO = "aaaaaeeeeeiiiiooooouuuunc------"



// SCHOOL ACCORDIONS SECTIONS
export const SCHOOL_ACCORDIONS = [
    { key: "presentationDocument", label: "Présentation" },
    { key: "applicationDocuments", label: "Calendrier académique" },
    { key: "agreement", label: "Facilités et Services" },
    { key: "description", label: "Vie étudiante et logement" },
    { key: "professionalIntegrationSupport", label: "Soutien à l’insertion professionnelle" },
    { key: "agreementFile", label: "Partenaires académiques" }
]


// FORMATION_COST_DISCLAIMER 
export const FORMATION_COST_DISCLAIMER = "Attention le coût de cette formation est indicatif et peut varier, merci de le vérifier auprès de l'établissement"