import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from 'react-router-dom';

// import store
import { connect } from 'react-redux'

// import store actions
import { setAppTokenAsked, setAppTokenRetrieved, setSchool, togglePreloader } from '../../store/actions';

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment';
import 'moment/locale/fr';

// import flag
import Flag from 'react-flagkit';

// import dummies
import { COUNTRY, FOREIGNS, OPPORTUNITY, SCHOOL } from '../../helpers/dummies';

// import services
import { getSchool, getSchools, getAllOpportunities } from '../../services/schoolService';
import { addComment } from '../../services/commentService';
// import { addFaq } from '../../services/faqService';

// import common components
import Carousels from '../../components/common/Carousels';
import Banner from '../../components/common/Banner';
// import Leading from '../../components/common/Leading';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import Square from '../../components/common/Square';
import Mailer from '../../components/common/Mailer';
import Maper from '../../components/common/Maper';
import Commenter from '../../components/common/Commenter';
import Sliders, { RESPONSIVES } from '../../components/common/Sliders';
import Questioner from '../../components/common/Questioner';
import SocialShare from '../../components/common/SocialShare';

// import custom components
import ListFormation from '../../components/custom/schools/ListFormation';

// import constants
import { APP_TOKEN, SCHOOL_ACCORDIONS, UNKNOWN_ERROR, USER_DATA } from '../../helpers/constants';

// import utilities
import { formatFaqsForDisplay, isUnauthenticatedRequest, slugify } from '../../helpers/utilities';

// import marker image
import { icClassesRhythm, icDate, icDeliveryMode, icLanguage, icRank, icScholarship, mkSchool } from '../../assets/images';


// custom sliders options
const SLIDERS_RESPONSIVES = {
    ...RESPONSIVES,
    desktop: { ...RESPONSIVES.desktop, items: 3 },
    tablet: { ...RESPONSIVES.tablet, items: 2 }
}


class School extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            commenter: { loading: false, failure: "", success: false },
            mailer: { loading: false, failure: "", success: false },
            questioned: { loading: false, failure: "", success: false },
            showLoginLink: false,
            showSubsriptionLink: false,
            showCommentSection: false,
            showSocialShare: false,
            allOpportunities:[]
        }
    }

    componentDidMount() {
        try {
            //Get props
            console.log(this.props, 'props')
            let { onPreloaderToggled, location } = this.props

            // //Start preloader
            // onPreloaderToggled(true, "Chargement de l'établissement")

            // // Load school
            // this.onGetSchool()

            getAllOpportunities().then(res=>this.setState({allOpportunities:res?.opportunities}));

            if ((!location.state.isSchoolPublic) && (!localStorage.getItem(USER_DATA))) {
                this.setState({ showLoginLink: true })
            } else {
                //Start preloader
                onPreloaderToggled(true, "Chargement de l'établissement")

                // Load school
                this.onGetSchool()
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                //Get props
                let { tokenRetrieved, tokenFailed, onAppTokenRetrieved, onPreloaderToggled } = this.props

                //Handle props values
                if (tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    onAppTokenRetrieved(false)
                    this.onGetSchool()
                } else if (tokenFailed) {
                    onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {
            this.props.onSetSchool({})
            this.props.onSetSchool([])
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onModalToggled = (option = "showSocialShare", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On comment section showed.
     * 
     */
    onCommentSectionShowed = () => {
        this.setState(prevState => ({
            showCommentSection: !prevState.showCommentSection
        }))
    }

    /**
     * On get school.
     * 
     */
    onGetSchool() {
        let { schoolCode } = this.props.location.state

        console.log(this.props)

        getSchool(schoolCode).then((response) => {
            if (response && response.publicId && (response.publicId === schoolCode)) {
                let { schoolLogo, sliders, city, address, country, latitude, longitude, foreigns } = response

                this.props.onSetSchool({
                    ...response,
                    schoolLogo: (schoolLogo ? schoolLogo : SCHOOL.image),
                    sliders: (sliders ? sliders : SCHOOL.sliders),
                    city: (city ? city : COUNTRY.city),
                    address: (address ? address : COUNTRY.address),
                    country: country ? {
                        ...country, countryCode: (country.countryCode ? country.countryCode : COUNTRY.countryCode)
                    } : COUNTRY,
                    coordinates: {
                        lat: (latitude != null) ? latitude : OPPORTUNITY.coordinates.lat,
                        lng: (longitude != null) ? longitude : OPPORTUNITY.coordinates.lng
                    },
                    foreigns: (foreigns ? foreigns : FOREIGNS),
                    showSocials: this.canDisplaySocialsNetworks()
                })

                //Load similars schools
                this.onGetSchools()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET SCHOOL ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET SCHOOL ERROR")
        });
    }

    /**
     * On Get Schools.
     * 
     */
    onGetSchools = () => {
        getSchools().then((response) => {
            if (response && response.data) {
                this.props.onSetSchool(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET SCHOOLS ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET SCHOOLS ERROR")
        });
    }

    /**
     * On Commented.
     * 
     * @param {*} data 
     */
    onCommented = (data) => {
        try {
            console.log({ data }, "ON COMMENTED")

            //Get props
            let { school } = this.props

            //Start loader
            this.setState({ commenter: { loading: true, failure: "", success: false } })

            //Launch save comment's request
            let comment_data = {
                content: data.comments,
                internationalOpening: data.internationalOpening,
                professionalPreparation: data.professionalPreparation,
                teachingQuality: data.teachingQuality,
                recommendSchool: data.recommendSchool,
                title: data.title,
                schoolId: school.id
            }

            console.log({ comment_data }, "POST COMMENT DATA")

            addComment(comment_data).then((response) => {
                console.log({ response }, "POST COMMENT RESPONSE")
                if (response.id && response.school && response.school.id && (response.school.id === school.id)) {
                    this.setState({ commenter: { loading: false, failure: "", success: true } })
                } else {
                    console.error(response, "POST COMMENT ERROR")
                    this.setState({ commenter: { loading: false, failure: UNKNOWN_ERROR, success: false } })
                }
            }).catch((error) => {
                console.error(error, "POST COMMENT ERROR")
                this.setState({ commenter: { loading: false, failure: UNKNOWN_ERROR, success: false } })
            });
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Mailed.
     * 
     * @param {*} data 
     * @param {*} onCleared 
     */
    onMailed = (data, onCleared) => {
        try {
            console.log({ data }, "ON MAILED")
            this.setState({ mailer: { loading: true, failure: "", success: false } })
            setTimeout(() => {
                this.setState({
                    mailer: { loading: false, failure: "", success: true }
                }, () => {
                    onCleared()
                })
            }, 2000);
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Questioned.
     * 
     * @param {*} data 
     * @param {*} onCleared 
     */
    onQuestioned = (data, onCleared) => {
        try {
            console.log({ data }, "ON QUESTIONED")

            let { school } = this.props
            let user = JSON.parse(localStorage.getItem(USER_DATA))

            this.setState({ questioned: { loading: true, failure: "", success: false } })

            let faq_data = {
                userId: user.id,
                userDiploma: data.diplomas[0].label,
                question: ((data.faqs[0].question !== "Autres") ? data.faqs[0].question : data.otherQuestion),
                userProfile: data.profile,
                userEmail: data.useremail,
                countryId: data.residence[0].value,
                schoolId: school.id,
            }

            console.log({ faq_data }, "POST FAQ DATA")

            // addFaq(faq_data).then((response) => {
            //     console.log({ response }, "POST FAQ RESPONSE")
            //     if (response.id && response.school && response.school.id && (response.school.id === school.id)) {
            //         this.setState({ commenter: { loading: false, failure: "", success: true } })
            //     } else {
            //         console.error(response, "POST FAQ ERROR")
            //         this.setState({ commenter: { loading: false, failure: UNKNOWN_ERROR, success: false } })
            //     }
            // }).catch((error) => {
            //     console.error(error, "POST FAQ ERROR")
            //     this.setState({ commenter: { loading: false, failure: UNKNOWN_ERROR, success: false } })
            // });

            setTimeout(() => {
                this.setState({
                    questioned: { loading: false, failure: "", success: true }
                }, () => {
                    onCleared()
                })
            }, 2000);
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Can display socials  networks.
     * 
     * @returns 
     */
    canDisplaySocialsNetworks() {
        try {
            //Get school values from props
            let { facebookLink, instagramLink, youtubeLink, twitterLink, telegramLink } = this.props.school

            //Filter socials networks
            let atLeastSocial = [facebookLink, instagramLink, youtubeLink, twitterLink, telegramLink].find((item) => (item !== null))

            //Return results
            return ((atLeastSocial !== null) && (atLeastSocial !== undefined))
        } catch (error) {
            console.log(error)
            return false
        }
    }


    render() {

        const { school, schools, isPreloader, location } = this.props
        const { commenter, mailer, questioned, showLoginLink, showSubsriptionLink, showCommentSection, showSocialShare } = this.state

        return (
            <React.Fragment>
                <div id="school" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {(showLoginLink) ? (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12 py-5">
                                    <center>
                                        <p className="p-2 mb-0 fs-20">
                                            Pour consulter le profile de {" "}
                                            <strong> {location.state.schoolName} </strong>,
                                            {" "}  il faut te connecter.
                                        </p>
                                        <p className="p-2 mb-0 fs-15">
                                            <Link
                                                to={{ pathname: `/login`, state: { from: location } }}
                                                className="text-decoration-underline"
                                            >
                                                Je me connecte
                                            </Link> {" "} dès maintenant pour continuer !
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    ) : (showSubsriptionLink) ? (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12 py-5">
                                    <center>
                                        <p className="p-2 mb-0 fs-20">
                                            Pour consulter le profile de {" "}
                                            <strong> {location.state.schoolName} </strong>,
                                            {" "} il faut t'abonner.
                                        </p>
                                        <p className="p-2 mb-0 fs-15">
                                            <Link
                                                to={{ pathname: `#`, state: { from: location } }}
                                                className="text-decoration-underline"
                                            >
                                                Je m'abonne
                                            </Link> {" "} dès maintenant pour continuer.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    ) : (school && school.publicId) ? (
                        <div id="school-content">
                            {/* Banner */}
                            <Banner bannerId="school-banner" withBackground={false}>
                                <Carousels
                                    centerMode
                                    infiniteLooped
                                    items={school.sliders}
                                    carouselsClasses="banner-carousel"
                                    itemKey="school_carousel_item"
                                    withArrows={true}
                                    withIndicators={false}
                                    autoPlayed={false}
                                />
                            </Banner>

                            {/* Breadcrumbs */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Breadcrumbs
                                            extratitle={`${school.country.name}`}
                                            extratitleLink={`/pays/${school.country.publicId}`}
                                            subtitle="Établissements"
                                            subtitleLink="/etablissements"
                                            currentTile={`${school.name}`}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="box-single p-0 mb-0">
                                {/* Top Section */}
                                <div className="top-section shadow-sm py-5">
                                    <div className="container">
                                        <div className="d-flex">
                                            <div className="school-logo me-1">
                                                <img
                                                    src={school.schoolLogo}
                                                    className="img-fluid"
                                                    width={125} height={125}
                                                    alt={school.name.toLowerCase()}
                                                />
                                            </div>
                                            <div className="flex-grow-1 d-flex justify-content-between ms-1">
                                                <div className="first-column">
                                                    <h3 className="mb-0">
                                                        {school.name}
                                                    </h3>

                                                    <div className="d-flex align-items-center mb-3">
                                                        {school.country.name} {" "}
                                                        <Flag
                                                            country={school.country.countryCode.toUpperCase()}
                                                            className="ms-2 border border-dark"
                                                            height={16} width={22}
                                                        />
                                                    </div>

                                                    <div className="single-meta p-0">
                                                        <ul>
                                                            {(school.recognizedByState) ? (
                                                                <li>
                                                                    <span className="d-flex align-items-center">
                                                                        <i className='bx bxs-check-circle text-green me-1'></i>
                                                                        Reconnu par l'État
                                                                    </span>
                                                                </li>
                                                            ) : null}

                                                            {(school.admissionLink && (school.admissionLink !== null) && (school.admissionLink !== "")) ? (
                                                                <li>
                                                                    <a href={school.admissionLink}>
                                                                        <i className="bx bx-link-external"></i>
                                                                        Site internet
                                                                    </a>
                                                                </li>
                                                            ) : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="p-0 single-meta second-column">
                                                    <ul>
                                                        <li>
                                                            <span title="Date de la prochaine rentrée">
                                                                <img src={icDate} width={24} height={24} alt="date icon" />{" "}
                                                                {(school.annualBackToSchool !== 0) ? `${moment(new Date(school.annualBackToSchool)).format('DD MMM YYYY')}` : "Non renseigné"}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span title="Format d’apprentissage">
                                                                <img src={icDeliveryMode} width={24} height={24} alt="formats icon" />{" "}
                                                                {(school.teachingMode !== null) ? `${school.teachingMode}` : "Non renseigné"}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span title="Rythme de formation">
                                                                <img src={icClassesRhythm} width={24} height={24} alt="rythmes icon" />{" "}
                                                                {(school.formationRhythm !== null) ? `${school.formationRhythm}` : "Non renseigné"}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span title="Langue d’enseignement">
                                                                <img src={icLanguage} width={24} height={24} alt="langues icon" />{" "}
                                                                {(school.language !== null) ? `${school.language}` : "Non renseigné"}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="p-0 single-meta third-column">
                                                    <ul>
                                                        <li>
                                                            <span title="Classement International">
                                                                <img src={icRank} width={24} height={24} alt="rank icon" />{" "}
                                                                {(school.top20 !== null) ? "Top 20 Afrique ou Monde" : "Non renseigné"}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span title="Classement ACE">
                                                                <img src={icRank} width={24} height={24} alt="rank icon" />{" "}
                                                                {(school.top100 !== null) ? "Top 100 Afrique ou Monde" : "Non renseigné"}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span title="Bourses internes 1er cycle">
                                                                <img src={icScholarship} width={24} height={24} alt="scholarship icon" />{" "}
                                                                {(school.scholarship1) ? "Oui" : "Non"}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span title="Bourses internes 2nd cycle">
                                                                <img src={icScholarship} width={24} height={24} alt="scholarship icon" />{" "}
                                                                {(school.scholarship2) ? "Oui" : "Non"}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="fourth-column school-reviews">
                                                    <div className="reviews">
                                                        <div className="reviews-stars">
                                                            <i className='bx bxs-star text-muted'></i>
                                                            <i className='bx bxs-star text-muted'></i>
                                                            <i className='bx bxs-star text-muted'></i>
                                                            <i className='bx bxs-star text-muted'></i>
                                                            <i className='bx bxs-star text-muted'></i>
                                                            <span className="cursor-hand ms-1 text-muted">
                                                                {`(${school.note ? school.note : "0"})`}
                                                            </span>
                                                        </div>
                                                        <div className="reviews-actions justify-content-end mt-2">
                                                            <span className="px-2 py-1 d-flex align-items-center justify-content-center me-1">
                                                                <i className='bx bx-like me-1' /> J’aime
                                                            </span>
                                                            <span
                                                                className="px-2 py-1 d-flex align-items-center justify-content-center ms-1"
                                                                onClick={() => this.onModalToggled("showSocialShare", true)}
                                                            >
                                                                <i className='bx bx-share-alt me-1' /> Partager
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-end mt-3">
                                                        {((school.admissionLink !== null) && (school.admissionLink !== "")) ? (
                                                            <Link
                                                                id="btn-candidate"
                                                                to={school.admissionLink}
                                                                className="btn-apply"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Je candidate
                                                            </Link>
                                                        ) : (
                                                            <Link
                                                                id="btn-candidate" className="btn-apply"
                                                                to={{
                                                                    pathname: `/etablissements/${slugify(school.name)}/apply`,
                                                                    state: { schoolCode: school.publicId, isSchoolPublic: school.isPublic, schoolName: school.name }
                                                                }}
                                                            >
                                                                Je candidate
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Content Section */}
                                <div className="content-section py-4 mb-5">
                                    <div className="container">
                                        <div className="row g-0 gx-4">
                                            <div className="col-lg-3">
                                                <Square squareClasses="mb-3">
                                                    <h3 className="square-title">
                                                        {`Adresse de ${school.name}`}
                                                    </h3>
                                                    <div className="square-item square-recents mb-0">
                                                        <div className="mb-3">
                                                            {school.address}{`${school.city ? `, ${school.city}` : ""}`}
                                                            {(school.country && school.country.name) ? `, ${school.country.name}` : ""}
                                                        </div>
                                                        <Maper items={{ coordinates: school.coordinates }} size="300" icon={mkSchool} />
                                                    </div>
                                                </Square>

                                                {(school.foreigns && (school.foreigns.length > 0)) ? (
                                                    <Square squareClasses="mb-3">
                                                        <h3 className="square-title">
                                                            {`${school.name} est international !`}
                                                            <span className='d-block fs-13 text-muted fw-normal mt-2'>
                                                                Retrouvez chez nous des étudiants de toute l'Afrique
                                                            </span>
                                                        </h3>
                                                        <div className="square-item square-recents mb-0 foreigns-section">
                                                            <ul>
                                                                {school.foreigns.map((item, index) => (
                                                                    <li key={`school_foreign_${index}`} title={`${item.name}`}>
                                                                        <Flag
                                                                            country={item.code.toUpperCase()}
                                                                            className="border-0" size={64}
                                                                        />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </Square>
                                                ) : null}

                                                {(school.showSocials) ? (
                                                    <Square squareClasses="mb-3">
                                                        <h3 className="square-title">
                                                            {`Suivez ${school.name}`}
                                                        </h3>
                                                        <div className="square-item square-recents school-socials my-3">
                                                            <ul>
                                                                {(school.facebookLink !== null) ? (
                                                                    <li className="bg-facebook">
                                                                        <a href={`${school.facebookLink}`}>
                                                                            <i className='bx bxl-facebook'></i>
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {(school.whatsappLink !== null) ? (
                                                                    <li className="bg-whatsapp">
                                                                        <a href={`${school.whatsappLink}`}>
                                                                            <i className='bx bxl-whatsapp'></i>
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {(school.instagramLink !== null) ? (
                                                                    <li className="bg-instagram">
                                                                        <a href={`${school.instagramLink}`}>
                                                                            <i className='bx bxl-instagram'></i>
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {(school.twitterLink !== null) ? (
                                                                    <li className="bg-twitter">
                                                                        <a href={`${school.twitterLink}`}>
                                                                            <i className='bx bxl-twitter'></i>
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {(school.youtubeLink !== null) ? (
                                                                    <li className="bg-youtube">
                                                                        <a href={`${school.youtubeLink}`}>
                                                                            <i className='bx bxl-youtube'></i>
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                            </ul>
                                                        </div>
                                                    </Square>
                                                ) : null}

                                                <Square squareClasses="mb-3">
                                                    <h3 className="square-title">
                                                        Foire aux questions
                                                    </h3>
                                                    <div className="square-item square-recents mb-0">
                                                        {localStorage.getItem(USER_DATA) ? (
                                                            <Questioner
                                                                items={formatFaqsForDisplay(school.name)}
                                                                onQuestioned={this.onQuestioned}
                                                                {...questioned}
                                                            />
                                                        ) : (
                                                            <p className="m-0">
                                                                <Link
                                                                    to={{ pathname: `/login`, state: { from: location } }}
                                                                >
                                                                    Connectez-vous
                                                                </Link> {" "} à votre compte membre pour poser une question.
                                                            </p>
                                                        )}
                                                    </div>
                                                </Square>

                                                <Square squareClasses="mb-0">
                                                    <h3 className="square-title">
                                                        {`Contacter ${school.name}`}
                                                    </h3>
                                                    {localStorage.getItem(USER_DATA) ? (
                                                        <div className="square-item square-recents mb-0">
                                                            <div className="accordion mt-3" id="accordionSchoolContact">
                                                                <div className="accordion-item">
                                                                    <h5 className="accordion-header" id="headingSchoolContact">
                                                                        <button
                                                                            className="accordion-button collapsed"
                                                                            type="button" data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseSchoolContact"
                                                                            aria-expanded="false"
                                                                            aria-controls="collapseSchoolContact"
                                                                        >
                                                                            Envoyez un message ici
                                                                        </button>
                                                                    </h5>
                                                                    <div
                                                                        id="collapseSchoolContact"
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby="headingSchoolContact"
                                                                        data-bs-parent="#accordionSchoolContact"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <Mailer
                                                                                onMailed={this.onMailed}
                                                                                {...mailer}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <p className="m-0">
                                                            <Link
                                                                to={{ pathname: `/login`, state: { from: location } }}
                                                            >
                                                                Connectez-vous
                                                            </Link> {" "} à votre compte membre pour envoyer un message.
                                                        </p>
                                                    )}
                                                </Square>
                                            </div>
                                            <div className="col-lg-9 px-4">
                                                <div className="ads">
                                                    <div className="leaderboard center-box">
                                                        PUB MOYENNE ICI
                                                    </div>
                                                </div>

                                                <div className="row mt-5">
                                                    <div className="col-lg-12">
                                                        <div className="accordion content-accordion" id="accordionSchoolContent">
                                                            {SCHOOL_ACCORDIONS.map((item, index) => (
                                                                <div key={`school_accordion_${index}`} className="accordion-item">
                                                                    <h2 className="accordion-header" id={`heading${item.key}`}>
                                                                        <button
                                                                            className="accordion-button collapsed"
                                                                            type="button" data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse${item.key}`}
                                                                            aria-expanded="false" aria-controls={`collapse${item.key}`}
                                                                        >
                                                                            {item.label}
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`collapse${item.key}`}
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby={`heading${item.key}`}
                                                                        data-bs-parent="#accordionSchoolContent"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            {school[item.key] || "Non renseigné"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 availables-formations mt-5">
                                                        <h5 className="mb-0 fw-bold">
                                                            Formations disponibles
                                                        </h5>
                                                        {(school.formations && (school.formations.length > 0)) ? (
                                                            <ListFormation
                                                                listerClasses="g-3 mt-0"
                                                                items={school.formations.map((item) => ({
                                                                    ...item,
                                                                    school: school
                                                                }))}
                                                            />
                                                        ) : (
                                                            <p className="m-0 p-2">
                                                                Aucune formation disponible.
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="col-lg-12 schools-suggestions mt-5">
                                                        <h5 className="mb-0 fw-bold">
                                                            D’autres établissements qui pourraient vous intéresser
                                                        </h5>
                                                        {(schools && (schools.length > 0)) ? (
                                                            <div className="content">
                                                                <Sliders
                                                                    slidersClasses="suggestions-sliders"
                                                                    responsiveOptions={SLIDERS_RESPONSIVES}
                                                                >
                                                                    {schools.slice(0, 6).map((item, index) => (
                                                                        <div key={`other_school_item_${index}`} className="box">
                                                                            <a href={`#`}>
                                                                                <img
                                                                                    src={(item.image ? item.image : SCHOOL.image)}
                                                                                    alt={item.name.toLowerCase()}
                                                                                />
                                                                                <span className="d-flex flex-column">
                                                                                    <h5> {item.name} </h5>
                                                                                    <small className="text-muted">
                                                                                        {item.city || "Cotonou"}
                                                                                        {item.country ? `, ${item.country.name}` : "Bénin"}
                                                                                    </small>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </Sliders>
                                                            </div>
                                                        ) : (
                                                            <p className="m-0">
                                                                Aucune suggestion d'établissement similaire.
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="col-12">
                                                        {
                                                            this.state.allOpportunities?.map((opportunity, index)=>{
                                                                return <div key={index}>{opportunity?.name}</div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-lg-12 leave-comment mt-5">
                                                        <button
                                                            type="button" className="btn-leave-comment"
                                                            onClick={() => this.onCommentSectionShowed()}
                                                        >
                                                            {`${showCommentSection ? "Réduire" : "Laisser un commentaire"}`} {" "}
                                                            <i className={`bx bx-${showCommentSection ? "up" : "down"}-arrow-alt text-light`} />
                                                        </button>
                                                        {showCommentSection ? (
                                                            <div className="mt-3">
                                                                <h5 className="mb-2 fw-bold">
                                                                    Ancien.ne étudiant.e ? Partagez votre expérience en laissant un commentaire
                                                                </h5>
                                                                <div className="content">
                                                                    {localStorage.getItem(USER_DATA) ? (
                                                                        <Commenter
                                                                            onCommented={this.onCommented}
                                                                            {...commenter}
                                                                        />
                                                                    ) : (
                                                                        <p className="m-0">
                                                                            <Link
                                                                                to={{ pathname: `/login`, state: { from: location } }}
                                                                            >
                                                                                Connectez-vous
                                                                            </Link> {" "} à votre compte membre pour laisser un commentaire.
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12 py-5">
                                    <center>
                                        <p>Cet établissement est introuvable.</p>
                                        <p className="mb-0">
                                            Continuer sur{" "}
                                            <a href="/">l'accueil</a> ou sur la liste des{" "}
                                            <a href="/etablissements">établissements</a>.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {(showSocialShare && school && school.publicId) ? (
                    <SocialShare
                        opened={showSocialShare}
                        shareLink={`http://localhost:3000/etablissements/${slugify(school.name)}`}
                        shareMessage="Découvre cet établissement d'exception"
                        title="Partager un établissement"
                        subtitle={`Partager ${school.name} sur les réseaux sociaux et autres plateformes...`}
                        onClosed={this.onModalToggled}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
School.propTypes = {
    school: PropTypes.object,
    onSetSchool: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
    onAppTokenAsked: PropTypes.func,
    onAppTokenRetrieved: PropTypes.func,
    onPreloaderToggled: PropTypes.func,
}
const mapStateToProps = state => ({
    school: state.school,
    schools: state.schools,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetSchool: data => dispatch(setSchool(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(School)