import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBNnSDJr889GeHfl1RCu5YieKdVb89Iy-I",
  authDomain: "ace-orientation-ad4eb.firebaseapp.com",
  projectId: "ace-orientation-ad4eb",
  storageBucket: "ace-orientation-ad4eb.appspot.com",
  messagingSenderId: "740335888012",
  appId: "1:740335888012:web:aae6cfdb9e5d276673bda9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const providerFace = new FacebookAuthProvider();
export { auth, provider, providerFace };