import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import flag
import Flag from 'react-flagkit'

// import constants
import { ITEMS_TO_DISPLAY_SMALL, USER_DATA } from '../../../helpers/constants'

// import utilities
import { calculateCol, slugify } from '../../../helpers/utilities'

// import custom common components
import Lister from '../../common/Lister'

// import dummies
import { COUNTRY, SCHOOL } from '../../../helpers/dummies'

// import custom icons
import { icDate, icDeliveryMode, icLanguage } from '../../../assets/images'

const FavoriteListSchool = ({  items, itemsPerRow, listerClasses, withDiscover, withPagination, userPublicId, addFavoriteInStudentPanel, deleteUniversityFromStudentPanel, studentFavoriteUniversities }) => {
    return (
        <React.Fragment>
            <Lister
                listerClasses={`${listerClasses} schools`}
                withDiscover={withDiscover}
                withPagination={withPagination}
                discoverOptions={{ discoverLink: "/etablissements" }}
                >
                {items && items.map((item, index) => {
                    let itemSchool = {
                        ...item,
                        schoolLogo: item.schoolLogo ? item.schoolLogo : SCHOOL.image,
                        country: item.country ? {
                            ...item.country,
                            countryCode: item.country.countryCode ? item.country.countryCode : COUNTRY.countryCode
                        } : COUNTRY,
                        userPublicId:userPublicId,
                        universityInFavorite:studentFavoriteUniversities.length> 0 && studentFavoriteUniversities.includes(item.publicId)?true:false,
                        addFavoriteInStudentPanel:addFavoriteInStudentPanel,
                        deleteUniversityFromStudentPanel:deleteUniversityFromStudentPanel
                    }
                    return (
                        <SchoolItem
                            key={`school_item_${index}`}
                            col={calculateCol(itemsPerRow)}
                            {...itemSchool}
                        />
                    )
                })}
            </Lister>
        </React.Fragment>
    )

}

FavoriteListSchool.propTypes = {
    items: PropTypes.array,
    itemsPerRow: PropTypes.number,
    listerClasses: PropTypes.string,
    withPagination: PropTypes.bool,
    // studentFavoriteUniversities:PropTypes.array,
    // userPublicId:PropTypes.string,
}
FavoriteListSchool.defaultProps = {
    itemsPerRow: ITEMS_TO_DISPLAY_SMALL,
    listerClasses: "g-3",
    withDiscover: false,
    withPagination: false,
    // studentFavoriteUniversities:[],
    userPublicId:'',
}
export default FavoriteListSchool


/**
 * School Item.
 */
const SchoolItem = ({col, recognizedByState, schoolLogo, name, publicId, isPublic, country, annualBackToSchool, teachingMode, language, note, userPublicId, universityInFavorite, addFavoriteInStudentPanel, deleteUniversityFromStudentPanel }) => (
    <div className={`col-lg-${col} col-md-6 col-sm-12`}>
        <div className="box">
            <div className="box-score">
                {localStorage.getItem(USER_DATA) ? (
                    <div className="box-actions">
                          {
                            universityInFavorite ? <i className='bx bxs-like' onClick={()=>deleteUniversityFromStudentPanel({publicId:userPublicId, universityPublicId:publicId})}></i> :<i className='bx bx-like' onClick={()=>addFavoriteInStudentPanel({publicId:userPublicId, universityPublicId:publicId})}></i>
                        }
                        <span className="mx-1" />
                        <i className='bx bx-share-alt'></i>
                    </div>
                ) : null}
                <div className="box-reviews">
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <i className='bx bxs-star text-muted'></i>
                    <span className="text-muted">
                        {`(${note ? note : "0"})`}
                    </span>
                </div>
            </div>

            <div className="box-content mt-3">
                <div className="box-data">
                    <img src={schoolLogo} className="img-fluid" alt="school logo" width={125} height={125} />

                    <div className="box-name">
                        <h3>
                            {(name.length > 30) ? (name.substr(0, 29) + "...") : name}
                        </h3>
                        <div className="box-country">
                            <span className="me-1">
                                {(country.name.length > 25) ? (country.name.substr(0, 24) + "...") : country.name}
                            </span>
                            <Flag
                                country={country.countryCode.toUpperCase()}
                                className="ms-1 border border-dark"
                                height={16} width={22}
                            />
                        </div>
                    </div>
                </div>

                <div className="box-meta mt-3">
                    <ul>
                        {(recognizedByState) ? (
                            <li>
                                <span className="d-flex align-items-center">
                                    <i className='bx bxs-check-circle text-green' />
                                    Reconnu par l'État
                                </span>
                            </li>
                        ) : null}

                        <li>
                            <span title="Date de la prochaine rentrée">
                                <img src={icDate} width={24} height={24} alt="date icon" />{" "}
                                {(annualBackToSchool !== 0) ? moment(new Date(annualBackToSchool)).format('DD MMM YYYY') : "Non renseigné"}
                            </span>
                        </li>

                        <li>
                            <span title={`Format${((teachingMode !== null) && (teachingMode.length > 1)) ? "s" : ""} d’apprentissage`}>
                                <img src={icDeliveryMode} width={24} height={24} alt="formats icon" />{" "}
                                {(teachingMode !== null) ? `${teachingMode}` : "Non renseigné"}
                            </span>
                        </li>

                        <li>
                            <span title={`Langue${((language !== null) && (language.length > 1)) ? "s" : ""} d’enseignement`}>
                                <img src={icLanguage} width={24} height={24} alt="langues icon" />{" "}
                                {(language !== null) ? `${language}` : "Non renseigné"}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="box-more mt-3">
                <Link
                    to={{
                        pathname: `/etablissements/${slugify(name)}`,
                        state: { schoolCode: publicId, isSchoolPublic: isPublic, schoolName: name }
                    }}
                    className="see-more"
                >
                    Voir plus <i className='bx bx-right-arrow-alt'></i>
                </Link>
            </div>
        </div>
    </div>
);
