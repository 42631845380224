import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"
import {Button} from 'react-bootstrap';
// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import constants
import { ITEMS_TO_DISPLAY_XS, USER_DATA } from '../../../helpers/constants'

// import utilities
import { calculateCol, slugify } from '../../../helpers/utilities'

// import custom common components
import Lister from '../../common/Lister'

// import dummies
import { COUNTRY, SCHOOL } from '../../../helpers/dummies'

// import custom icons
import { icDate, icDeliveryMode, icLanguage } from '../../../assets/images'

const ListOffer = ({ offers, itemsPerRow, listerClasses, withDiscover, withPagination, setAcceptOffer}) => {
    return (
        <React.Fragment>
            <Lister
                listerClasses={`${listerClasses} schools`}
                withDiscover={withDiscover}
                withPagination={withPagination}
                discoverOptions={{ discoverLink: "/etablissements" }}
                >
                    {console.log(offers, 'offer')}
                {offers && offers.map((offer, index) => {
                    let itemOffer = {
                        ...offer,
                        setAcceptOffer:setAcceptOffer
                        // schoolLogo: item.schoolLogo ? item.schoolLogo : SCHOOL.image,
                        // country: item.country ? {
                        //     ...item.country,
                        //     countryCode: item.country.countryCode ? item.country.countryCode : COUNTRY.countryCode
                        // } : COUNTRY,
                    }
                    return (
                        <OfferItem
                            key={index}
                            col={calculateCol(itemsPerRow)}
                            {...itemOffer}
                        />
                    )
                })}
            </Lister>
        </React.Fragment>
    )

}

ListOffer.propTypes = {
    offers: PropTypes.array,
    itemsPerRow: PropTypes.number,
    listerClasses: PropTypes.string,
    withPagination: PropTypes.bool,
    studentFavoriteUniversities:PropTypes.array,
    userPublicId:PropTypes.string,
    setAcceptOffer:PropTypes.func,
}
ListOffer.defaultProps = {
    setAcceptOffer:PropTypes.func,
    itemsPerRow: ITEMS_TO_DISPLAY_XS,
    listerClasses: "g-3",
    withDiscover: false,
    withPagination: false,
    studentFavoriteUniversities:[],
    userPublicId:'',
}
export default ListOffer


/**
 * School Item.
 */
const OfferItem = ({ col, name, city, beginDate, endDate, advantages, conditions, description, image, publicId, setAcceptOffer, country }) => (
    <div className={`col-lg-${col} col-md-6 col-sm-12`}>
        <div className="box">
            
            {/* like and share */}
            {/* <div className="box-score">
                {localStorage.getItem(USER_DATA) ? (
                    <div className="box-actions">
                        <i className='bx bx-like'></i>
                        <span className="mx-1" />
                        <i className='bx bx-share-alt'></i>
                    </div>
                ) : null}
            </div> */}

            <div className="box-content mt-3">
                <div className="box-data">
                    <img src={image} className="img-fluid" alt="school logo" width={125} height={125} />

                    <div className="box-name">
                        <h3>
                            Name: {name} <div className='fs-6 fw-bold'>{publicId}</div>
                        </h3>
                        <div className="box-country">
                            <span className="me-1">
                                {country?.countryName}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="box-meta mt-3">
                     <ul>
                      {/*  {(recognizedByState) ? (
                            <li>
                                <span className="d-flex align-items-center">
                                    <i className='bx bxs-check-circle text-green' />
                                    Reconnu par l'État
                                </span>
                            </li>
                        ) : null}
                    */}
                        <li>
                            <span title="Date de la prochaine rentrée">
                                <img src={icDate} width={24} height={24} alt="date icon" /> {moment(beginDate).format('MM-DD-YYYY')}
                            </span>
                        </li>
                        <li>
                            <span title="Date de la prochaine rentrée">
                                <img src={icDate} width={24} height={24} alt="date icon" /> {moment(endDate).format('MM-DD-YYYY')}
                            </span>
                        </li>

                        {/* <li>
                            <span title={`Format${((teachingMode !== null) && (teachingMode.length > 1)) ? "s" : ""} d’apprentissage`}>
                                <img src={icDeliveryMode} width={24} height={24} alt="formats icon" />{" "}
                                {(teachingMode !== null) ? `${teachingMode}` : "Non renseigné"}
                            </span>
                        </li>

                        <li>
                            <span title={`Langue${((language !== null) && (language.length > 1)) ? "s" : ""} d’enseignement`}>
                                <img src={icLanguage} width={24} height={24} alt="langues icon" />{" "}
                                {(language !== null) ? `${language}` : "Non renseigné"}
                            </span>
                        </li> */}
                    </ul> 
                </div>
            </div>
            {
                advantages && 
                <div className='advantages text-left'>
                    <ul>
                        <li>
                            {advantages}
                        </li>
                    </ul>
                </div>
            }
            {
                conditions && 
                <div className='conditions text-left'>
                    <ul>
                        <li>
                            {conditions}
                        </li>
                    </ul>
                </div>
            }
            {
                description && 
                <div className='description text-left'>
                    {description}
                </div>
            }

            <div className="box-more mt-2">
                <Button className="see-more w-100" onClick={()=>setAcceptOffer({"opportunityPublicId":publicId})}>
                    Accepter l'offre <i className='bx bx-right-arrow-alt'></i>
                </Button>
            </div>
            {/* <div className="box-more mt-2">
                    <Link to="" className="see-more w-100">
                        Rejeter l'offre <i className='bx bx-right-arrow-alt'></i>
                    </Link> */}
                {/* <Link
                    to={{
                        pathname: `/etablissements/${slugify(name)}`,
                        state: { schoolCode: publicId, isSchoolPublic: isPublic, schoolName: name }
                    }}
                    className="see-more"
                >
                    Voir plus <i className='bx bx-right-arrow-alt'></i>
                </Link> */}
            {/* </div> */}
        </div>
    </div>
);
