import React from 'react'
import PropTypes from 'prop-types'

// import router
import { Link, withRouter } from "react-router-dom"

// import components
import { Form, Col, Button } from 'react-bootstrap';

// import services
import { forgotPass } from '../../services/authService';

// import constants
import { CREDENTIALS_ERROR, LOGIN_ERROR, UNKNOWN_ERROR, USER_DATA } from '../../helpers/constants';

// import toaster
import { toast } from 'react-toastify';

// import common components
import Loader from '../../components/common/Loader';


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            failure: "",
            success: "",
            inputs: { email: "" }
        }
    }

    onChanged = (e) => {
        if (e.target) {
            let { name, value } = e.target
            this.setState({
                inputs: { ...this.state.inputs, [name]: value }
            })
        }
    }

    onSubmited = (e) => {
        e.preventDefault();

        // Update state
        this.setState({ loading: true, failure: "", success: "" })

        // Launch login
        this.onForgot(this.state.inputs)
    }

    onForgot(data) {
        forgotPass(data).then((response) => {
            if (!response?.errors) {
                this.setState({ loading: false, failure:"", success: response[0].message })
                
                // let { location, history } = this.props
                // if (location.state && location.state.from) {
                //     history.push({pathname: location.state.from.pathname, state: location.state.from.state})
                // } else {
                //     toast.success("Vous êtes connecté ! Vous pouvez accéder à votre espace membre.")
                //     history.push("/account")
                // }
            }
            else if (response?.errors && response?.errors[0] && (response.errors[0] === LOGIN_ERROR)) {
                this.setState({ loading: false, failure: LOGIN_ERROR, success:"" })
            } 
            else {
                this.setState({ loading: false, failure: UNKNOWN_ERROR, success:"" })
            }
        }).catch((error) => {
            console.error(error, "LOGIN ERROR")
            this.setState({ loading: false, failure: UNKNOWN_ERROR, success:"" })
        });
    }

    render() {

        const { loading, failure, success } = this.state

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 pt-5">
                            <h3 className="text-center mb-1">
                                Se connecter sur ACE Orientation
                            </h3>
                            <center>
                                <small className="text-muted text-center">
                                    Bénéficiez des services ACE pour construire un projet
                                    académique gagnant en Afrique et dans le monde
                                </small>
                            </center>
                        </div>
                        <div className="col-lg-6 offset-lg-3">
                            <Form onSubmit={this.onSubmited} className="auth-form">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Entrez votre email"
                                        required onChange={this.onChanged}
                                    />
                                </Form.Group>

                                {(failure !== "") ? (
                                    <div className="alert alert-danger" role="alert">
                                        {failure}
                                    </div>
                                ) : null}
                                {(success !== "") ? (
                                    <div className="alert alert-success" role="alert">
                                        {success}
                                    </div>
                                ) : null}

                                {loading ? (
                                    <Loader color="blue" />
                                ) : (
                                    <button className="btn-discover" type="submit">
                                        Mot de passe oublié
                                    </button>
                                )}
                            </Form>
                        </div>
                        <div className="col-lg-6 offset-lg-3 pt-0 pb-5">
                            <center>
                                Déjà membre ? <Link to="/login">Se connecter.</Link>
                            </center>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default withRouter(ForgotPassword)
