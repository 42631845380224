import React from "react"

// import socials
import { SOCIALS } from "../../helpers/constants"

// import app logo
import logo from "../../assets/images/logos/logo.png"

// import app name
import { APP_NAME } from "../../helpers/constants"

const Footer = () => {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-6">
                            <img id="logo-app" src={logo} alt="app logo" height={90} width={90} />
                            <div className="footer-line" />
                            <div className="footer-links">
                                <div className="center-box">
                                    <ul>
                                        <li><a href="#">Annuaire</a></li>
                                        <li><a href="#">Opportunités</a></li>
                                        <li><a href="#">Orientation</a></li>
                                        <li><a href="#">Métiers</a></li>
                                        <li><a href="#">Partenariat</a></li>
                                        <li><a href="#">Mon compte</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-2">
                            <div className="footer-socials">
                                <div className="footer-contact">
                                    <h5 className="text-center mb-3">Suivez-nous sur les réseaux sociaux</h5>
                                    <div className="center-box">
                                        <div className="social-links">
                                            {SOCIALS.map((social, index) => (
                                                <a key={`social_network_${index}`} href={social.link} className={social.name}>
                                                    <i className={`bx bxl-${social.icon}`} />
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-line" />
                    </div>
                    <div className="row">
                        <div className="col-lg-12 footer-links">
                            <div className="center-box">
                                <ul>
                                    <li><a href="#">Calendrier</a></li>
                                    <li><a href="#">Quiz</a></li>
                                    <li><a href="#">Conseils</a></li>
                                    <li><a href="#">Groupes privés</a></li>
                                    <li><a href="#">Ambassadeurs ACE</a></li>
                                    <li><a href="#">Contact – FAQ</a></li>
                                    <li><a href="#">CGU</a></li>
                                    <li><a href="#">Politique de confidentialité</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    &copy; 2021 <strong>ACE Orientation</strong> Tous droits réservés - Reproduction du contenu interdite
                </div>
                <div className="credits">
                    Conçu par  <a href="https://google.com/">Logistify</a>
                </div>
            </div>
        </footer>
    )
}
export default Footer