import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { I18N_LANGUAGE } from "./helpers/constants"

import translationEn from "./locales/en/translation.json"
import translationFr from "./locales/fr/translation.json"

// the translations
const resources = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
}

if (!localStorage.getItem(I18N_LANGUAGE)) localStorage.setItem(I18N_LANGUAGE, "en")

i18n.use(detector).use(initReactI18next).init({// passes i18n down to react-i18next
  resources,
  lng: localStorage.getItem(I18N_LANGUAGE) || "en",
  fallbackLng: "en", // use en if detected lng is not available

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n