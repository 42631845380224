import React from 'react'
import PropTypes from 'prop-types'

// import common component
import Sliders from '../../common/Sliders'

// import dummies
import { SCHOOL } from '../../../helpers/dummies'
import { Link } from 'react-router-dom'
import { slugify } from '../../../helpers/utilities'


const EstablishmentList = ({ items, emptyMessage }) => {

    return (
        <React.Fragment>
            {(items.length > 0) ? (
                <Sliders slidersClasses="establishments-sliders">
                    {items.map((item, index) => (
                        <div key={`establishment_item_${index}`} className="box">
                            <Link
                                to={{
                                    pathname: `/etablissements/${slugify(item.name)}`,
                                    state: { schoolCode: item.publicId, isSchoolPublic: item.isPublic, schoolName: item.name }
                                }}
                                title={item.name}
                            >
                                <img
                                    src={(item.image ? item.image : SCHOOL.image)}
                                    alt={item.name.toLowerCase()}
                                />
                            </Link>
                        </div>
                    ))}
                </Sliders>
            ) : (
                <div className="empty-list text-center">
                    <p className="mb-0 p-2">
                        {(emptyMessage !== "") ? emptyMessage : "La liste de nos établissements de référence bientôt disponible."}
                    </p>
                </div>
            )}
        </React.Fragment>
    )

}
EstablishmentList.propTypes = {
    emptyMessage: PropTypes.string,
    items: PropTypes.array
}
EstablishmentList.defaultProps = {
    emptyMessage: ""
}
export default EstablishmentList