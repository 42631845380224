import React, { Component } from "react"
import PropTypes from 'prop-types'

// Import router
import { BrowserRouter as Router, Switch } from "react-router-dom"

// import redux
import { connect } from "react-redux"

// Import Routes
import { protectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// Import Layouts
import Container from "./components/layout/Container"
import Blocker from "./components/layout/Blocker"
import Header from "./components/layout/Header"
import Footer from "./components/layout/Footer"

// Import toast
import { ToastContainer, Slide } from 'react-toastify'

// Import Styles
import "./assets/styles"


class App extends Component {

  constructor(props) {
    super(props)

    // Init state
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, info) {
    // Affiche une UI de repli    
    this.setState({ hasError: true })

    // Handle error.
    console.error({ error, info }, "COMPONENT ERROR")
  }

  render() {
    const { isPreloader } = this.props
    
    return (
      <React.Fragment>
        <Router>
          {(!isPreloader) ? (<Header />) : null}
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                exact isProtected={false}
                key={idx} path={route.path}
                component={route.component}
                layout={Container}
              />
            ))}
            
            
            {
              // this.state.isUserVerified &&
            protectedRoutes.map((route, idx) => (
              <AppRoute
                exact isProtected={true}
                key={idx} path={route.path}
                component={route.component}
                layout={Blocker}
              />
            ))}
          </Switch>
          {(!isPreloader) ? (<Footer />) : null}
        </Router>
        <ToastContainer
          pauseOnHover newestOnTop hideProgressBar
          closeButton={false} icon={false} position="top-right"
          theme="colored" transition={Slide} autoClose={2000} limit={3}
        />
      </React.Fragment>
    )
  }

}
App.propTypes = {
  isPreloader: PropTypes.bool,
}
const mapStateToProps = state => ({
  isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(App)