import React from "react"

const VisionList = ({ items }) => {
    return (
        <div className="row g-3 mt-5">
            {items.map((item, index) => (
                <VisionItem key={`vision_item_${index}`} {...item} />
            ))}
        </div>
    )
}
export default VisionList

/**
 * Vision item.
 */
const VisionItem = ({ name, image, description }) => (
    <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="box">
            <a href="#">
                <img src={image} alt={name.toLowerCase()} />
            </a>
            <h4 className="text-green text-center">
                <a href="#">
                    <strong>{name}</strong>
                </a>
            </h4>
            <p className="text-white text-center">
                {description}
            </p>
        </div>
    </div>
);