import React from 'react'
import PropTypes from 'prop-types'

const Square = ({ squareClasses, children }) => {
    return (
        <div className={`square ${squareClasses}`}>
            {children}
        </div>
    )
}
Square.propTypes = {
    squareClasses: PropTypes.string,
    children: PropTypes.node
}
Square.defaultProps = {
    squareClasses: ""
}
export default Square
