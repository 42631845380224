import React from "react"
import PropTypes from 'prop-types'

// import router
import { withRouter } from "react-router-dom"

// import redux
import { connect } from "react-redux"

// import deep-equal
import equal from "deep-equal"

// import components
import Searcher from "./Searcher"

// import store actions
import { setAppTokenAsked, setAppTokenRetrieved } from "../../store/actions"

// import services
import { getAppToken } from "../../services/authService"

// import constants
import { APP_TOKEN } from "../../helpers/constants"


class Container extends React.Component {

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                if (this.props.tokenAsked) this.onGetAppToken()
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Get App Token.
     * 
     */
    onGetAppToken() {
        //Get props
        let { onAppTokenRetrieved, onAppTokenAsked } = this.props

        //Launch request to get app token
        getAppToken().then((response) => {
            if (response && response.api_token && response.user) {
                localStorage.setItem(APP_TOKEN, response.api_token)
                onAppTokenRetrieved(true, false)
                onAppTokenAsked(false)
            } else {
                onAppTokenRetrieved(false, true)
                onAppTokenAsked(false)
                console.error(response, "GET APP TOKEN ERROR")
            }
        }).catch((error) => {
            onAppTokenRetrieved(false, true)
            onAppTokenAsked(false)
            console.error(error, "GET APP TOKEN ERROR")
        });
    }

    render() {
        const { children, location, isPreloader, preloadMessage } = this.props

        return (
            <React.Fragment>
                <div id="preloader" className={`preloader${isPreloader ? "" : " d-none"}`}>
                    <div className="spinner">
                        <span className="spinner-message">
                            {`${preloadMessage}...`}
                        </span>
                        <div className="spinner-loader" />
                    </div>
                </div>

                {/* {(location.pathname !== "/") ? (
                    <div className={`header-filters${isPreloader ? " d-none" : ""}`}>
                        <Searcher />
                    </div>
                ) : null} */}

                <div className="container-content">
                    {children}
                </div>
            </React.Fragment>
        )
    }

}
Container.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    tokenAsked: PropTypes.bool,
    isPreloader: PropTypes.bool,
    preloadMessage: PropTypes.string
}
const mapStateToProps = state => ({
    isPreloader: state.isPreloader,
    preloadMessage: state.preloadMessage,
    tokenAsked: state.tokenAsked,
})
const mapDispatchToProps = dispatch => ({
    onAppTokenRetrieved: (retrieved, failed) => dispatch(setAppTokenRetrieved(retrieved, failed)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container))