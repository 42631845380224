import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"

import Paginator from './Paginator'

import { DISCOVER } from '../../helpers/constants'

const Lister = ({ children, listerClasses, withDiscover, discoverOptions, withPagination, paginationOptions }) => {
    return (
        <React.Fragment>
            <div className="overflow-hidden">
                <div className={`row ${listerClasses}`}>
                    {children}
                </div>
            </div>

            {(withDiscover) ? (
                <div className="center-box mt-5">
                    <Link to={discoverOptions.discoverLink} className="btn-discover">
                        {DISCOVER}
                    </Link>
                </div>
            ) : null}

            {(withPagination) ? (
                <Paginator />
            ) : null}
        </React.Fragment>
    )
}
Lister.propTypes = {
    children: PropTypes.node.isRequired,
    listerClasses: PropTypes.string,
    withDiscover: PropTypes.bool,
    discoverOptions: PropTypes.object,
    withPagination: PropTypes.bool,
    paginationOptions: PropTypes.object
}
Lister.defaultProps = {
    listerClasses: "g-3 mt-5",
    withDiscover: false,
    discoverOptions: {
        discoverLink: "#"
    },
    withPagination: false,
    paginationOptions: {},
}
export default Lister
