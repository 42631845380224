import React from 'react';
import PropTypes from 'prop-types';

// import select
import Select from "react-select"

const Selecter = ({ id, name, placeholder, multiple, loading, selected, options, onSelected, valueAttribut, labelAttribut, selecterClasses, selecterClassesPrefix }) => {

    const formatItemsWithValuesAndLabels = (items) => {
        return items.map((item) => {
            return ((item) && ((item.value && item.label) ? item : {
                ...item,
                value: item[valueAttribut],
                label: item[labelAttribut]
            }))
        })
    }

    return (
        <Select
            value={formatItemsWithValuesAndLabels(selected)}
            onChange={(selected) => onSelected(selected, name)}
            options={formatItemsWithValuesAndLabels(options)}
            loadingMessage={() => 'Chargement...'}
            noOptionsMessage={() => 'Aucune option'}
            className={selecterClasses}
            classNamePrefix={selecterClassesPrefix}
            isDisabled={false}
            isLoading={loading}
            isClearable={true}
            isSearchable={true}
            isMulti={multiple}
            placeholder={placeholder}
            name={name}
            id={id}
        />
    );

}
Selecter.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    loading: PropTypes.bool,
    selecterClasses: PropTypes.string,
    selecterClassesPrefix: PropTypes.string,
    options: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    onSelected: PropTypes.func.isRequired
}
Selecter.defaultProps = {
    id: "",
    name: "",
    placeholder: "Sélectionner...",
    multiple: false,
    loading: false,
    valueAttribut: "id",
    labelAttribut: "name",
    selecterClasses: "default-selects",
    selecterClassesPrefix: "default-selects-prefix"
}
export default Selecter;