import React from 'react'
import PropTypes from 'prop-types'

// import router
import { Link, withRouter } from "react-router-dom";

// import components
import { Form, Col, Button } from 'react-bootstrap';

// import phone input
import PhoneInput from 'react-phone-input-2';

// import services
import { register } from '../../services/authService';

// import constants
import { CONFLICT_ERROR, CREDENTIALS_ERROR, UNKNOWN_ERROR, USER_DATA, USER_PARENT, USER_STUDENT } from '../../helpers/constants';

// import toaster
import { toast } from 'react-toastify';

// import common components
import Loader from '../../components/common/Loader';

// import dummies
import { USER } from '../../helpers/dummies';


class Register extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            failure: "",
            inputs: { fullname: "", email: "", phone: "", password: "", isParent: false }
        }
    }

    onChanged = (e, option = "") => {
        if (option !== "") {
            this.setState({
                inputs: { ...this.state.inputs, [option]: e }
            })
        } else if (e.target) {
            let { name, value, type, checked, id } = e.target

            let input = (["checkbox", "radio"].includes(type) ? checked : value)

            this.setState({
                inputs: { ...this.state.inputs, [name]: input }
            })
        }
    }

    onSubmited = (e) => {
        e.preventDefault();

        this.setState({ loading: true, failure: "" })

        let { fullname, email, phone, password, isParent } = this.state.inputs

        console.log({ fullname, email, phone, password, isParent })

        this.onRegister({
            name: fullname,
            email: email,
            phone: phone,
            password: password,
            profile: (isParent) ? USER_PARENT.key : USER_STUDENT.key
        })
    }

    onRegister(data) {
        register(data).then((response) => {
            console.log(response, "REGISTER RESPONSE")
            if (response && response.id && (response.name === data.name)) {
                localStorage.setItem(USER_DATA, JSON.stringify(response))
                this.setState({ loading: false, failure: "" })

                toast.success("Vous compte est creé ! Vous pouvez accéder à votre espace membre.")
                this.props.history.push("/account")
            } else if (response.message && response.message.includes("Duplicate entry")) {
                this.setState({ loading: false, failure: CONFLICT_ERROR })
            } else {
                this.setState({ loading: false, failure: UNKNOWN_ERROR })
            }
        }).catch((error) => {
            console.error(error, "REGISTER ERROR")
            this.setState({ loading: false, failure: UNKNOWN_ERROR })
        });
    }

    render() {

        const { loading, failure, inputs } = this.state

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 pt-5">
                            <h3 className="text-center mb-1">
                                Créer un compte sur ACE Orientation
                            </h3>
                            <center>
                                <small className="text-muted text-center">
                                    Bénéficiez des services ACE pour construire un projet
                                    académique gagnant en Afrique et dans le monde
                                </small>
                            </center>
                        </div>
                        <div className="col-lg-6 offset-lg-3">
                            <Form onSubmit={this.onSubmited} className="auth-form">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Nom et Prénom
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        id="fullname"
                                        name="fullname"
                                        placeholder="Doe John"
                                        onChange={this.onChanged}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="john@gmail.com"
                                        onChange={this.onChanged}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Numéro de téléphone
                                    </Form.Label>
                                    <PhoneInput
                                        country={'bj'}
                                        value={inputs.phone || ""}
                                        onChange={(value) => this.onChanged(value, "phone")}
                                        placeholder=""
                                        inputProps={{
                                            id: 'phone',
                                            name: 'phone',
                                            required: true,
                                            className: "form-control"
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Mot de passe
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="******"
                                        onChange={this.onChanged}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Je suis </Form.Label>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Label className="card-radio-label">
                                                <Form.Control
                                                    type="radio"
                                                    name="isParent"
                                                    id="studentprofile"
                                                    className="card-radio-input"
                                                    onChange={this.onChanged}
                                                />

                                                <div className="card-radio">
                                                    <i className="bx bxs-graduation fs-24 text-green align-middle me-2" />{" "}
                                                    <span className="fs-14">Étudiant.e</span>
                                                </div>
                                            </Form.Label>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Label className="card-radio-label">
                                                <Form.Control
                                                    type="radio"
                                                    name="isParent"
                                                    id="parentprofile"
                                                    className="card-radio-input"
                                                    onChange={this.onChanged}
                                                />

                                                <div className="card-radio">
                                                    <i className="bx bxs-user-detail fs-24 text-green align-middle me-2" />{" "}
                                                    <span className="fs-14">Parent.e</span>
                                                </div>
                                            </Form.Label>
                                        </div>
                                    </div>
                                </Form.Group>

                                {(failure !== "") ? (
                                    <div className="alert alert-danger" role="alert">
                                        {failure}
                                    </div>
                                ) : null}

                                {loading ? (
                                    <Loader color="blue" />
                                ) : (
                                    <button className="btn-discover" type="submit">
                                        Inscription
                                    </button>
                                )}
                            </Form>
                        </div>
                        <div className="col-lg-6 offset-lg-3 pt-0 pb-5">
                            <center>
                                Déjà membre ? <Link to="/login">Se connecter.</Link>
                            </center>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(Register)