import { get, post } from './baseService';

export function getSchools() {
    return get('schools', true).then((response) => response)
}

export async function getSchool(code) {
    return await get(`schools/${code}`, true).then((response) => response)
}
export function addUniversityInStudentFavoriteList(data) {
    return post('users/add-favorite', data, true).then(response=>response)
}
export function getStudentFavoriteUniversityList(data) {
    return post('users/get-favorite', data, true).then(response=>response)
}
export function getStudentFavoriteOpportunityList(data) {
    return post('users/get-favorite', data, true).then(response=>response)
}
export function deleteStudentFavoriteUniversity(data) {
    return post('users/delete-favorite', data, true).then(response=>response)
}
export function deleteStudentFavoriteOpportunity(data) {
    return post('users/delete-favorite', data, true).then(response=>response)
}
export function getAllOpportunities(data) {
    return post('get-all-opportunities', data, true).then(response=>response)
}