import React from 'react'
import PropTypes from 'prop-types'

// import store
import { connect } from 'react-redux';
import { setCountry } from '../../store/actions';


class Countries extends React.Component {

    constructor(props) {
        super(props)

        this.state = { }
    }

    render() {

        const { countries } = this.props

        return (
            <React.Fragment>
                <div className='row justify-content-cent'>
                    <div className='col-8'>
                        <p className='p-3  m-0'>Countries</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
Countries.propTypes = {
    countries: PropTypes.object,
    onSetCountries: PropTypes.func,
}
const mapStateToProps = state => ({
    countries: state.countries,
})
const mapDispatchToProps = dispatch => ({
    onSetCountries: (data) => dispatch(setCountry(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Countries)
