// Home
import Home from "../pages/home/Home"

// Opportunities, Articles
import Opportunities from "../pages/opportunities/Opportunities"
import Opportunity from "../pages/opportunities/Opportunity"
import Article from "../pages/opportunities/Article"

// Countries
import Countries from "../pages/countries/Countries"
import Country from "../pages/countries/Country"

// Schools
import Schools from "../pages/schools/Schools"
import School from "../pages/schools/School"
import ApplyToSchool from "../pages/schools/ApplyToSchool"

// Domains, Formations
import Domains from "../pages/domains/Domains"
import Domain from "../pages/domains/Domain"
import Formation from "../pages/domains/Formation"

// Authentification
import Login from "../pages/authentification/Login"
import Register from "../pages/authentification/Register"
import ForgotPassword from "../pages/authentification/ForgotPassword"
import ResetPassword from "../pages/authentification/ResetPassword"

// Dashboard
import Dashboard from "../pages/dashbaord/Dashboard"
import Favorites from "../pages/dashbaord/Favorites"
import AddOpportunity from "../pages/opportunities/AddOpportunity"
import ListOpportunityByUniversity from "../pages/opportunities/ListOpportunityByUniversity"
import StudentOffers from "../pages/dashbaord/StudentOffers"


// Private routes
const protectedRoutes = [
    { path: "/account", component: Dashboard },
    { path: "/favorites", component: Favorites },
    { path: "/student-offers", component: StudentOffers },
]

// Public routes
const publicRoutes = [
    { path: "/opportunites/articles/:articleCode", component: Article },
    { path: "/opportunites/:opportunityCode", component: Opportunity },
    { path: "/opportunites", component: Opportunities },
    { path: "/add-opportunity", component: AddOpportunity },
    { path: "/list-opportunity-by-university", component: ListOpportunityByUniversity },

    { path: "/pays/:countryCode", component: Country },
    { path: "/pays/regions/:regionCode", component: Countries },

    { path: "/etablissements/:schoolCode/apply", component: ApplyToSchool },
    { path: "/etablissements/:schoolCode", component: School },
    { path: "/etablissements", component: Schools },

    { path: "/formations/:formationCode", component: Formation },
    { path: "/domaines/:domainCode", component: Domain },
    { path: "/domaines", component: Domains },

    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/forgot", component: ForgotPassword },
    { path: "/reset-password", component: ResetPassword },

    // this route should be at the end of all other routes
    { path: "/", component: Home },
]

export { protectedRoutes, publicRoutes }