import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
// import Banner from '../../components/common/Banner'
import { USER_DATA, APP_TOKEN } from '../../helpers/constants'
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import {getCurrentUser, getCurrentUserFavoriteUniversities, getCurrentUserFavoriteOpportunities} from '../../services/authService';
import { setAppTokenAsked, setAppTokenRetrieved, setStudentFavoriteSchoolList, setStudentFavoriteOfferList, togglePreloader } from '../../store/actions';
import FavoriteListSchool from '../../components/custom/schools/FavoriteListSchool';
import { getStudentFavoriteUniversityList, deleteStudentFavoriteUniversity, deleteStudentFavoriteOpportunity, getStudentFavoriteOpportunityList  } from '../../services/schoolService';
import { toast } from 'react-toastify';
import FavoriteListOpportunity from '../../components/custom/schools/FavoriteListOpportunity';

class Favorites extends React.Component {

    constructor(props) {
        super(props)

        // init state
        this.state = {
            userName: "",
            userData: {},
            isUserVerified:false,
            currentUser:{},
            favoriteUniversities:[],
            favoriteOpportunities:[],
            studentFavoriteUniversities:[],
            studentFavoriteOpportunities:[],
            userPublicId:'',
        }
        // this.addFavoriteInStudentPanel = this.addFavoriteInStudentPanel.bind(this);
        this.studentFavoriteUniversityList = this.studentFavoriteUniversityList.bind(this);
        this.studentFavoriteOpportunityList = this.studentFavoriteOpportunityList.bind(this);
        this.deleteUniversityFromStudentPanel = this.deleteUniversityFromStudentPanel.bind(this);
        this.deleteOpportunityFromStudentPanel = this.deleteOpportunityFromStudentPanel.bind(this);
        this.getCurrentUserInformation = this.getCurrentUserInformation.bind(this);
        this.getCurrentUserFavUniversity = this.getCurrentUserFavUniversity.bind(this);
        this.getCurrentUserFavOpportunity = this.getCurrentUserFavOpportunity.bind(this);
    }

    componentDidMount() {
        try {
            let user = JSON.parse(localStorage.getItem(USER_DATA));
            this.setState({userPublicId: user.publicId})

            let { onPreloaderToggled, onAppTokenAsked } = this.props

             //Load app token or Load schools
            if (!localStorage.getItem(APP_TOKEN)) {
                onAppTokenAsked(true)
            }
            else {
                // this.onGetSchools()
                this.studentFavoriteUniversityList(user.publicId);
                this.getCurrentUserInformation(user?.publicId);
                // Get Universities with full details to show in content
                
                this.getCurrentUserFavUniversity({publicId:user?.publicId, lookingFor:'university'})
                this.getCurrentUserFavOpportunity({publicId:user?.publicId, lookingFor:'opportunity'})
                
                let userPseudo = user.name.split(" ")
                this.setState({
                    userName: userPseudo[1],
                    userData: user,
                })           
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    getCurrentUserInformation(userId) {
        getCurrentUser(userId).then(response=>{
            this.setState({currentUser:response?.user});
            this.setState({isUserVerified: response.email_verified_at !== null ? true : false})           
        }).catch(err=>console.log(err));
    }


    getCurrentUserFavUniversity(data) {
        getCurrentUserFavoriteUniversities(data).then(res=>{
            this.setState({favoriteUniversities:res})
            this.props.studentFavoriteSchoolList(res)
        })
    }
    getCurrentUserFavOpportunity(data) {
        getCurrentUserFavoriteOpportunities(data).then(res=>{
            this.setState({studentFavoriteOpportunities:res})
            this.props.studentFavoriteOfferList(res)
        })
    }

    // All universities that a student added in favorite
    studentFavoriteUniversityList(userPublicId) {
        // Get universities publicID to just compare
        getStudentFavoriteUniversityList({
            publicId:userPublicId,
            lookingFor:"university"
        }).then(resp=>this.setState({studentFavoriteUniversities:resp})   
        ).catch(err=>console.log(err));
    }

    // All universities that a student added in favorite
    studentFavoriteOpportunityList(userPublicId) {
        // Get universities publicID to just compare
        getStudentFavoriteOpportunityList({
            publicId:userPublicId,
            lookingFor:"opportunity"
        }).then(resp=>this.setState({studentFavoriteOpportunities:resp})   
        ).catch(err=>console.log(err));
    }

    // Delete University from Favorite
    deleteUniversityFromStudentPanel(data) {
        deleteStudentFavoriteUniversity(data).then((res) => {
            toast.success("University is removed from the favorite.")
            getCurrentUserFavoriteUniversities({publicId:data?.publicId, lookingFor:'university'}).then(res=> this.props.studentFavoriteSchoolList(res))
        }
        ).catch(err=>console.log(err))
    }
  
    deleteOpportunityFromStudentPanel(data) {
        deleteStudentFavoriteOpportunity(data).then((res) => {
            toast.success("Opportunity is removed from the favorite.")
            getCurrentUserFavoriteOpportunities({publicId:data?.publicId, lookingFor:'opportunity'}).then(res=> this.props.studentFavoriteOfferList(res))
        }
        ).catch(err=>console.log(err))
    }

    render() {
        const { isPreloader, favorite_opportunity } = this.props
        const {currentUser, isUserVerified} = this.state
        return (
            <React.Fragment>
                {
                    isUserVerified && currentUser?.whatsapp_number != null &&                        
                    <>
                        {
                            this.props.favorite_schools && this.props.favorite_schools.length > 0 && 
                            <section className='favorite-list custom-section'>
                                <h2 className='title'>Mes établissements favoris</h2>
                                <Row><FavoriteListSchool items={this.props.favorite_schools} deleteUniversityFromStudentPanel={this.deleteUniversityFromStudentPanel} studentFavoriteUniversities={this.state.studentFavoriteUniversities} userPublicId={this.state.userPublicId} /></Row>
                            </section>
                        }
                        {
                            this.props.favorite_opportunity && this.props.favorite_opportunity.length > 0 && 
                                <section className='favorite-list custom-section'>
                                    <h2 className='title'>Mes opportunités favorites</h2>
                                    <Row><FavoriteListOpportunity items={this.props.favorite_opportunity} deleteOpportunityFromStudentPanel={this.deleteOpportunityFromStudentPanel} studentFavoriteOpportunities={this.state.studentFavoriteOpportunities} userPublicId={this.state.userPublicId} /></Row>
                                </section>
                        }
                            </>
                }            
            </React.Fragment>
        )
    }
}
Favorites.propTypes = {
    favorite_schools: PropTypes.array,
    favorite_opportunity: PropTypes.array,
    studentFavoriteSchoolList: PropTypes.func,
    studentFavoriteOfferList: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
}
const mapStateToProps = state => ({
    favorite_schools: state.favorite_schools,
    favorite_opportunity: state.favorite_opportunity,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    studentFavoriteSchoolList: (data) => dispatch(setStudentFavoriteSchoolList(data)),
    studentFavoriteOfferList: (data) => dispatch(setStudentFavoriteOfferList(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Favorites)