import React from 'react'
import PropTypes from 'prop-types'

// import store
import { connect } from 'react-redux'

// import deep-equal
import equal from "deep-equal"

// import store actions
import {
    setAppTokenAsked, setAppTokenRetrieved,
    setCountry, setSchool, togglePreloader
} from '../../store/actions'

// import services
import { getCountry } from '../../services/countryService'
import { getSchools } from '../../services/schoolService'

// import dummies
import { COUNTRY } from '../../helpers/dummies'

// import common components
import Banner from '../../components/common/Banner'
import Carousels from '../../components/common/Carousels'
import Leading from '../../components/common/Leading'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import Section from '../../components/common/Section'
import Sliders, { RESPONSIVES } from '../../components/common/Sliders'

// import custom components
import TabsCountry from '../../components/custom/country/TabsCountry'
import FilterSchool from '../../components/custom/schools/FilterSchool'
import ListSchool from '../../components/custom/schools/ListSchool'

// import constants
import { COUNTRY_TABS, APP_TOKEN } from '../../helpers/constants'
import { isUnauthenticatedRequest } from '../../helpers/utilities'

// custom sliders options
const SLIDERS_RESPONSIVES = {
    ...RESPONSIVES,
    desktop: { ...RESPONSIVES.desktop, items: 3 },
    tablet: { ...RESPONSIVES.tablet, items: 2 }
}


class Country extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        try {// Load country
            this.props.onPreloaderToggled(true, "Chargement du pays")
            this.onGetCountry()
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                if (this.props.tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    this.props.onAppTokenRetrieved(false)
                    this.onGetCountry()
                } else if (this.props.tokenFailed) {
                    this.props.onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        this.props.onSetCountry({})
    }

    /**
     * Get country.
     * 
     */
    onGetCountry() {
        let { countryCode } = this.props.match.params
        getCountry(countryCode).then((response) => {
            if (response && response.publicId && (response.publicId === countryCode)) {
                this.props.onSetCountry({
                    ...response,
                    code: response.code ? response.code : COUNTRY.code,
                    image: response.image ? response.image : COUNTRY.image,
                    sliders: response.sliders ? response.sliders : COUNTRY.sliders
                })
                this.onGetSchools()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET COUNTRY ERROR")
            }
        }).catch((error) => {
            console.error(error, "GET COUNTRY ERROR")
        });
    }

    /**
     * Get schools.
     * 
     */
    onGetSchools() {
        getSchools().then((response) => {
            if (response && response.data) {
                this.props.onSetSchools(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET SCHOOLS ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET SCHOOLS ERROR")
        });
    }

    /**
     * Format tabs items.
     * 
     * @returns 
     */
    formatTabsItems() {
        if (this.props.country == null) return [];
        return COUNTRY_TABS.map((ctx) => ({
            ...ctx,
            content: this.props.country[ctx.key]
        }))
    }

    render() {

        const { country, isPreloader, schools } = this.props

        return (
            <React.Fragment>
                <div id="country" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {(country && country.publicId) ? (
                        <div id="country-content">
                            {/* Banner, Leading */}
                            <Banner bannerId="country-banner" withBackground={false}>
                                <Carousels
                                    infiniteLooped
                                    items={country.sliders}
                                    carouselsClasses="banner-carousel"
                                    itemKey="country_carousel_item"
                                    withArrows={true}
                                    withIndicators={false}
                                    autoPlayed={false}
                                />
                                <Leading
                                    title={`Étudier au ${country.name}`}
                                    subtitle="Informations, Ressources et Établissements"
                                />
                            </Banner>

                            {/* Breadcrumbs */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Breadcrumbs
                                            subtitle="Étudier en afrique"
                                            currentTile={`Études ${country.name}`}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="ads mt-5">
                                <div className="leaderboard center-box">
                                    PUB MOYENNE ICI
                                </div>
                            </div>

                            {/* Country Tabs */}
                            <Section
                                sectionId="country-tabs"
                                sectionClasses="country-tabs py-5"
                            >
                                <TabsCountry
                                    items={this.formatTabsItems()}
                                    defaultActive={COUNTRY_TABS[0].key}
                                />
                            </Section>

                            {/* Country Livers */}
                            <Section
                                subtitle="Ils étudient ou travaillent au Bénin"
                                sectionId="country-livers"
                                sectionClasses="country-livers bg-white py-5"
                            >
                                <Sliders
                                    slidersClasses="country-sliders"
                                    responsiveOptions={SLIDERS_RESPONSIVES}
                                >
                                    {country.sliders.map((item, index) => (
                                        <div key={`country_slider_item_${index}`} className="box">
                                            <img src={item.image} alt={`country slider ${index}`} />
                                        </div>
                                    ))}
                                </Sliders>
                            </Section>

                            <div className="ads mt-5">
                                <div className="leaderboard center-box">
                                    PUB MOYENNE ICI
                                </div>
                            </div>

                            {/* Country Schools */}
                            <Section
                                subtitle="Établissements du pays"
                                sectionId="country-schools"
                                sectionClasses="country-schools py-5"
                            >
                                <FilterSchool />

                                <ListSchool listerClasses="g-3 mt-3" items={schools.slice(0, 4)} />
                            </Section>

                            <div className="ads mb-5">
                                <div className="hugeboard center-box">
                                    PUB EXTRA LARGE ICI
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12">
                                    <center>
                                        <p>Ce pays est introuvable.</p>
                                        <p className="mb-0">
                                            Continuer sur{" "}
                                            <a href="/">l'accueil</a> ou sur la liste des{" "}
                                            <a href="/">pays</a>.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }

}
Country.propTypes = {
    country: PropTypes.object,
    schools: PropTypes.array,
    onSetCountry: PropTypes.func,
    onSetSchools: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
    onAppTokenAsked: PropTypes.func,
    onAppTokenRetrieved: PropTypes.func,
    onPreloaderToggled: PropTypes.func,
}
const mapStateToProps = state => ({
    country: state.country,
    schools: state.schools,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetCountry: data => dispatch(setCountry(data)),
    onSetSchools: data => dispatch(setSchool(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Country)