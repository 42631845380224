import React, { useState } from "react";
import PropTypes from 'prop-types';

// import components
import { Form, Col, Row, Button } from 'react-bootstrap';

// import rating stars
import { Rating } from 'react-simple-star-rating';

// import loader
import Loader from "./Loader";


const Commenter = ({ onCommented, loading, failure, success }) => {

    const [data, setData] = useState({});

    const onChanged = (e) => {
        if (e.target) {
            let { name, value, type } = e.target
            setData({ ...data, [name]: value })
        }
    }

    const onRated = (rate, name) => {
        setData({ ...data, [name]: rate })
    }

    const onSubmited = (e) => {
        e.preventDefault();
        onCommented(data)
    }

    return (
        <React.Fragment>
            <Form onSubmit={onSubmited}>
                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>
                                Contenu de votre avis
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                id="comments"
                                name="comments"
                                required
                                onChange={onChanged}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="">
                            <Form.Label>
                                Titre de votre avis
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                placeholder=""
                                onChange={onChanged}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} className="my-3">
                        <p className="m-0 fw-bolder">À vous de noter</p>
                    </Col>
                    <Col sm={4}>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <span className="mb-2 text-center">
                                Qualité de l'enseignement
                            </span>
                            <Rating
                                allowHalfIcon
                                onClick={(rate) => onRated(rate, "teachingQuality")}
                                ratingValue={data.quality || 0}
                                size={25}
                                fillColorArray={['#f17a45', '#f1b345', '#f1d045']}
                            />
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <span className="mb-2 text-center">
                                Préparation au monde professionel
                            </span>
                            <Rating
                                allowHalfIcon
                                onClick={(rate) => onRated(rate, "professionalPreparation")}
                                ratingValue={data.quality || 0}
                                size={25}
                                fillColorArray={['#f17a45', '#f1b345', '#f1d045']}
                            />
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <span className="mb-2 text-center">
                                Ouverture sur l'internationale
                            </span>
                            <Rating
                                allowHalfIcon
                                onClick={(rate) => onRated(rate, "internationalOpening")}
                                ratingValue={data.quality || 0}
                                size={25}
                                fillColorArray={['#f17a45', '#f1b345', '#f1d045']}
                            />
                        </div>
                    </Col>
                    <Col sm={12} className="mt-4">
                        <span className="fw-bold me-2">
                            Recommanderiez-vous cet établissement ?
                        </span>
                        <Form.Check
                            inline
                            label="Oui"
                            value={"oui"}
                            type="radio"
                            name="recommendSchool"
                            id="recommendSchoolYes"
                            className="d-inline-flex align-items-center justify-content-center"
                            onChange={onChanged}
                        />
                        <Form.Check
                            inline
                            label="Non"
                            value={"non"}
                            type="radio"
                            name="recommendSchool"
                            id="recommendSchoolNo"
                            className="d-inline-flex align-items-center justify-content-center"
                            onChange={onChanged}
                        />
                    </Col>

                    {(failure && failure !== "") ? (
                        <Col sm={12} className="mt-5">
                            <small className="text-danger">{failure}</small>
                        </Col>
                    ) : null}

                    {(success) ? (
                        <Col sm={12} className="mt-4">
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                Votre commentaire a bien été envoyé.
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                            </div>
                        </Col>
                    ) : null}

                    <Col sm={12} className="mt-4">
                        {(loading) ? (
                            <Loader color="blue" />
                        ) : (
                            <Button type="submit" className="btn-submit btn-commenter">
                                {success ? "Modifier" : "Valider"} le commentaire
                            </Button>
                        )}
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )

}

export default Commenter