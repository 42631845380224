import React from 'react'
import PropTypes from 'prop-types'

// import carousel
import { Carousel } from 'react-responsive-carousel'

const Carousels = ({ items, carouselsClasses, itemKey, itemImageAlt, withArrows, withStatus, withIndicators, withThumbs, autoPlayed, infiniteLooped, centerMode, centerSlidePercentage }) => {

    const renderIndicator = (onClickHandler, isSelected, index, label) => {
        return (
            <li
                key={index}
                onClick={onClickHandler}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
                className={`control-dot${(isSelected) ? " active" : ""}`}
            />
        )
    }

    const renderArrowPrev = (onClickHandler, hasPrev, label) => {
        return (
            <>
                {(hasPrev) ? (
                    <span
                        className="control-arrows control-prev"
                        onClick={onClickHandler} title={label}
                    >
                        <i className='bx bx-left-arrow-alt'></i>
                    </span>
                ) : null}
            </>
        )
    }

    const renderArrowNext = (onClickHandler, hasNext, label) => {
        return (
            <>
                {(hasNext) ? (
                    <span
                        className="control-arrows control-next"
                        onClick={onClickHandler} title={label}
                    >
                        <i className='bx bx-right-arrow-alt'></i>
                    </span>
                ) : null}
            </>
        )
    }

    return (
        <Carousel
            autoPlay={autoPlayed} 
            infiniteLoop={infiniteLooped}
            showThumbs={withThumbs}
            showStatus={withStatus}
            showArrows={withArrows}
            showIndicators={withIndicators}
            centerMode={centerMode}
            centerSlidePercentage={centerSlidePercentage}
            interval={5000}
            className={`${carouselsClasses} carousels`}
            renderIndicator={(onClickHandler, isSelected, index, label) => renderIndicator(onClickHandler, isSelected, index, label)}
            renderArrowPrev={(onClickHandler, hasPrev, label) => renderArrowPrev(onClickHandler, hasPrev, label)}
            renderArrowNext={(onClickHandler, hasNext, label) => renderArrowNext(onClickHandler, hasNext, label)}
        >
            {items && items.map((sx, idx) => (
                <a key={`${itemKey}_${idx}`} href='#' className="carousels-item">
                    {(sx.isVideo) ? (
                        <video muted loop autoPlay>
                            <source src={sx.image} type="video/mp4" />
                            Votre navigateur ne prend pas en charge la balise vidéo.
                        </video>
                    ) : (
                        <img src={sx.image} alt={`${sx[itemImageAlt]} ${idx}`} />
                    )}
                </a>
            ))}
        </Carousel>
    )

}
Carousels.propTypes = {
    carouselsClasses: PropTypes.string,
    itemKey: PropTypes.string,
    itemImageAlt: PropTypes.string,
    withArrows: PropTypes.bool,
    withStatus: PropTypes.bool,
    withIndicators: PropTypes.bool,
    withThumbs: PropTypes.bool,
    autoPlayed: PropTypes.bool,
    infiniteLooped: PropTypes.bool,
    centerMode: PropTypes.bool,
    centerSlidePercentage: PropTypes.number,
    items: PropTypes.array.isRequired
}
Carousels.defaultProps = {
    carouselsClasses: "",
    itemKey: "carousel_item",
    itemImageAlt: "alt",
    withArrows: true,
    withStatus: false,
    withIndicators: true,
    withThumbs: false,
    autoPlayed: true,
    infiniteLooped: false,
    centerMode: false,
    centerSlidePercentage: 50
}
export default Carousels