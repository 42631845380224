import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"

// import store
import { connect } from 'react-redux'

// import store actions
import {
    setAppTokenAsked, setAppTokenRetrieved,
    setFormation, setOpportunity, togglePreloader
} from '../../store/actions'

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment';
import 'moment/locale/fr';

// import services
import { getOpportunities, getOpportunity } from '../../services/opportunityService'
import { getFormations } from '../../services/formationService'

// import dummies
import { OPPORTUNITY } from '../../helpers/dummies'

// import common components
import Banner from '../../components/common/Banner'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import Square from '../../components/common/Square'

// import constants
import { BOURSES_100, BOURSES_50, APP_TOKEN } from '../../helpers/constants'

// import utilities
import { getObjectByKey, isUnauthenticatedRequest } from '../../helpers/utilities'


class Opportunity extends React.Component {

    constructor(props) {
        super(props)

        this.state = { nextLink: "#", prevLink: "#" }
    }

    componentDidMount() {
        try {// Load opportunity
            this.props.onPreloaderToggled(true, "Chargement de l'opportunité")
            this.onGetOpportunity()
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                if (this.props.tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    this.props.onAppTokenRetrieved(false)
                    this.onGetOpportunity()
                } else if (this.props.tokenFailed) {
                    this.props.onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        this.props.onSetOpportunities({})
    }

    /**
     * Get opportunity.
     * 
     */
    onGetOpportunity() {
        let { opportunityCode } = this.props.match.params
        getOpportunity(opportunityCode).then((response) => {
            if (response && response.publicId && (response.publicId === opportunityCode)) {
                this.props.onSetOpportunities({
                    ...response,
                    image: response.image ? response.image : OPPORTUNITY.image,
                    officialLink: response.officialLink ? response.officialLink : OPPORTUNITY.officialLink,
                    country: response.country ? response.country : OPPORTUNITY.country,
                    city: response.city ? response.city : OPPORTUNITY.city,
                    medias: response.medias ? response.medias : OPPORTUNITY.medias
                })
                this.onGetOpportunities()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET OPPORTUNITY ERROR")
            }
        }).catch((error) => {
            console.error(error, "GET OPPORTUNITY ERROR")
        });
    }

    /**
     * Get opportunities.
     * 
     */
    onGetOpportunities() {
        getOpportunities().then((response) => {
            if (response && response.data) {
                let opportunites = response.data.map((ox) => ({
                    ...ox, image: ox.image ? ox.image : OPPORTUNITY.image
                }))
                this.props.onSetOpportunities(opportunites)
                this.onGetFormations()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET OPPORTUNITIES ERROR")
            }
        }).catch((error) => {
            console.error(error, "GET OPPORTUNITIES ERROR")
        });
    }

    /**
     * Get formations.
     * 
     */
    onGetFormations() {
        getFormations().then((response) => {
            if (response && response.data) {
                this.props.onSetFormations(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET FORMATIONS ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET FORMATIONS ERROR")
        });
    }

    render() {

        const { opportunity, formations, opportunities, isPreloader } = this.props
        const { nextLink, prevLink } = this.state

        console.log({ opportunities }, "OPPORTUNITUY")

        return (
            <React.Fragment>
                <div id="opportunity" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {(opportunity && opportunity.publicId) ? (
                        <div id="opportunity-content">
                            {/* Banner */}
                            <Banner
                                bannerId="opportunity-banner"
                                bannnerInnerClasses={{ backgroundImage: `url(${opportunity.image})` }}
                            />

                            <div className="container">
                                <div className="row">
                                    {/* Breadcrumbs */}
                                    <div className="col-lg-12">
                                        <Breadcrumbs
                                            subtitle="Opportunités"
                                            subtitleLink="/opportunites"
                                            currentTile="Détails d'une opportunité"
                                        />
                                    </div>

                                    {/* Contenu */}
                                    <div className="col-lg-9 my-5">
                                        {/* Opportunity Box */}
                                        <div className="box-single single-shadow p-4">
                                            <article>
                                                <h2 className="single-title">
                                                    {opportunity.name}
                                                </h2>

                                                <div className="single-meta meta-row">
                                                    <ul className="d-flex">
                                                        {(opportunity.country && opportunity.country != null) ? (
                                                            <li>
                                                                <a href="#" title="Pays hôte">
                                                                    <i className="bx bx-map-pin"></i>
                                                                    <span>
                                                                        {`${opportunity.country} ${opportunity.city}`}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        ) : null}

                                                        {(opportunity.nationality && opportunity.nationality != null) ? (
                                                            <li>
                                                                <a href="#" title="Nationalité(s) éligible(s)">
                                                                    <i className="bx bx-world"></i>
                                                                    <span>{opportunity.nationality}</span>
                                                                </a>
                                                            </li>
                                                        ) : null}

                                                        {(opportunity.maxAge && opportunity.minAge) ? (
                                                            <li>
                                                                <a href="#" title="Tranche d'âge éligible">
                                                                    <i className="bx bx-user"></i>
                                                                    <span>
                                                                        {`${opportunity.minAge}-${opportunity.maxAge}`}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        ) : null}

                                                        {(opportunity.priseCharge && opportunity.priseCharge != null) ? (
                                                            <li>
                                                                <a href="#" title="Type de financement">
                                                                    <i className="bx bxs-offer"></i>
                                                                    <span>
                                                                        {`${getObjectByKey(opportunity.priseCharge, "name")}`}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        ) : null}

                                                        {(opportunity.languages && opportunity.languages != null) ? (
                                                            <li>
                                                                <a href="#" title="Langue(s) de candidature">
                                                                    <i className="bx bxs-message-dots"></i>
                                                                    <span>{opportunity.languages}</span>
                                                                </a>
                                                            </li>
                                                        ) : null}

                                                        {(opportunity.endDate && opportunity.endDate != null) ? (
                                                            <li>
                                                                <a href="#" title="Date limite de candidature">
                                                                    <i className="bx bxs-hourglass"></i>
                                                                    <span>
                                                                        {moment(new Date(opportunity.endDate)).format('DD MMM YYYY')}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        ) : null}

                                                        {(opportunity.officialLink) ? (
                                                            <li>
                                                                <a href={opportunity.officialLink}>
                                                                    <i className="bx bx-link-external"></i>
                                                                    Lien officiel
                                                                </a>
                                                            </li>
                                                        ) : null}
                                                    </ul>
                                                </div>

                                                <div className="single-badges">
                                                    <ul>
                                                        {[opportunity.formationLevel, opportunity.mention].map((tag, index) => (
                                                            <li key={`opportunity_single_tag_item_${index}`} className="badge badge-soft-info box-badge">
                                                                {tag}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <ul>
                                                        {[opportunity.bourseDuree, getObjectByKey(opportunity.priseCharge, "name")].map((category, index) => (
                                                            <li key={`opportunity_single_category_item_${index}`} className="badge badge-soft-warning box-badge">
                                                                {category}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="single-content">
                                                    <div className="accordion" id="accordionOpportunity">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne"
                                                                    aria-expanded="false" aria-controls="collapseOne"
                                                                >
                                                                    Descriptif du programme
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionOpportunity"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div dangerouslySetInnerHTML={{ __html: opportunity.observation }} />
                                                                    <div dangerouslySetInnerHTML={{ __html: opportunity.description }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree"
                                                                    aria-expanded="false" aria-controls="collapseThree"
                                                                >
                                                                    Comment postuler ?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseThree"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingThree"
                                                                data-bs-parent="#accordionOpportunity"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div dangerouslySetInnerHTML={{ __html: opportunity.observation }} />
                                                                    <div dangerouslySetInnerHTML={{ __html: opportunity.description }} />                                                            </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFour">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFour"
                                                                    aria-expanded="false" aria-controls="collapseFour"
                                                                >
                                                                    FAQs
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFour"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingFour"
                                                                data-bs-parent="#accordionOpportunity"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div dangerouslySetInnerHTML={{ __html: opportunity.observation }} />
                                                                    <div dangerouslySetInnerHTML={{ __html: opportunity.description }} />                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-footer">
                                                    <ul>
                                                        {[opportunity.formationLevel, opportunity.mention].map((item, index) => (
                                                            <li key={`bs_footer_tag_item_${index}`}><span>{item}</span></li>
                                                        ))}
                                                        {[opportunity.bourseDuree, getObjectByKey(opportunity.priseCharge, "name")].map((item, index) => (
                                                            <li key={`bs_footer_category_item_${index}`}><span>{item}</span></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </article>
                                        </div>

                                        {/* Others opportunites */}
                                        <div className="others-opportunities">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="previous-link">
                                                        <Link to={prevLink} replace>
                                                            <i className='bx bx-left-arrow-alt'></i>
                                                            Opportunité précédente
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="next-link">
                                                        <Link to={nextLink} replace>
                                                            Opportunité suivante
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Menu latéral */}
                                    <div className="col-lg-3 my-5">
                                        <div className="ads">
                                            <div className="rectangle center-box">
                                                PUB LATÉRAL ICI
                                            </div>
                                        </div>

                                        <Square squareClasses="my-3">
                                            <h3 className="square-title">Les dernières formations</h3>
                                            <div className="square-item square-recents">
                                                {(formations && formations.slice(0, 6).map((item, index) => (
                                                    <div key={`domain_item_${index}`} className="recent-item clearfix">
                                                        {item.image ? (
                                                            <img src={item.image} alt={`domain ${index}`} />
                                                        ) : null}
                                                        <h4 className={`${item.image ? "" : "ms-0"}`}>
                                                            <a href="#">
                                                                {(item.name.length > 25) ? (item.name.substr(0, 24) + "...") : item.name}
                                                            </a>
                                                        </h4>
                                                        <time className={`${item.image ? "" : "ms-0"}`} dateTime={item.createdAt}>
                                                            {moment(item.createdAt, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY')}
                                                        </time>
                                                    </div>
                                                )))}
                                            </div>
                                        </Square>

                                        <div className="ads">
                                            <div className="interstitial center-box">
                                                PUB LATÉRAL ICI
                                            </div>
                                        </div>

                                        <Square squareClasses="my-3">
                                            <h3 className="square-title">Les dernières bourses</h3>
                                            <div className="square-item square-recents">
                                                {(opportunities && opportunities.slice(0, 6).map((item, index) => (
                                                    <div key={`recent_item_${index}`} className="recent-item clearfix">
                                                        <img src={item.image} alt={`recent image ${index}`} />
                                                        <h4>
                                                            <a href="#">
                                                                {(item.name.length > 25) ? (item.name.substr(0, 24) + "...") : item.name}
                                                            </a>
                                                        </h4>
                                                        <time dateTime={item.beginDate}>
                                                            {moment(new Date(item.beginDate)).format('DD MMM YYYY')}
                                                        </time>
                                                    </div>
                                                )))}
                                            </div>
                                        </Square>

                                        <div className="ads">
                                            <div className="half-page center-box">
                                                PUB LATÉRAL ICI
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <div className="row py-5">
                                <div className="col-lg-12">
                                    <center>
                                        <p>Cette opportunité est introuvable.</p>
                                        <p className="mb-0">
                                            Continuer sur{" "}
                                            <a href="/">l'accueil</a> ou sur la liste des{" "}
                                            <a href="/opportunites">opportunités</a>.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }

}
Opportunity.propTypes = {
    opportunity: PropTypes.object,
    onSetOpportunity: PropTypes.func,
    onSetFormations: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
    onAppTokenAsked: PropTypes.func,
    onAppTokenRetrieved: PropTypes.func,
    onPreloaderToggled: PropTypes.func,
}
const mapStateToProps = state => ({
    opportunity: state.opportunity,
    opportunities: state.opportunities,
    formations: state.formations,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetOpportunity: data => dispatch(setOpportunity(data)),
    onSetOpportunities: data => dispatch(setOpportunity(data)),
    onSetFormations: data => dispatch(setFormation(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Opportunity)