import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"

// import moment
import moment from 'moment';
import 'moment/locale/fr';

// import common components
import Lister from '../../common/Lister';

// import utilities
import { calculateCol, getObjectByKey } from '../../../helpers/utilities';

// import dummies
import { COUNTRY, OPPORTUNITY } from '../../../helpers/dummies';

// import constants
import { ITEMS_TO_DISPLAY_XS } from '../../../helpers/constants';


const ListOpportunity = ({ items, itemsPerRow, listerClasses, withDiscover, withPagination, deleteOpportunityFromStudentPanel, userPublicId, addFavoriteInStudentPanel, studentFavoriteOpportunities }) => {
    return (
        <React.Fragment>
            <Lister
                listerClasses={`${listerClasses} opportunities`}
                withPagination={withPagination}
                withDiscover={withDiscover}
                discoverOptions={{ discoverLink: "/opportunites" }}
            >
                {items && items.map((item, index) => {
                    let itemOpportunity = {
                        ...item,
                        image: item.image ? item.image : OPPORTUNITY.image,
                        officialLink: item.officialLink ? item.officialLink : OPPORTUNITY.officialLink,
                        coordinates: item.coordinates ? item.coordinates : OPPORTUNITY.coordinates,
                        country: item.country ? item.country : COUNTRY,
                        userPublicId:userPublicId,
                        opportunitesInFavorite:studentFavoriteOpportunities.length>0 && studentFavoriteOpportunities.includes(item.publicId)?true:false,
                        addFavoriteInStudentPanel:addFavoriteInStudentPanel,
                        deleteOpportunityFromStudentPanel:deleteOpportunityFromStudentPanel,
                    }
                    return (
                        <OpportunityItem
                            key={`bourse_item_${index}`}
                            col={calculateCol(itemsPerRow)}
                            {...itemOpportunity}
                        />
                    )
                })}
            </Lister>
        </React.Fragment>
    )

}
ListOpportunity.propTypes = {
    items: PropTypes.array,
    itemsPerRow: PropTypes.number,
    listerClasses: PropTypes.string,
    withPagination: PropTypes.bool
}
ListOpportunity.defaultProps = {
    itemsPerRow: ITEMS_TO_DISPLAY_XS,
    listerClasses: "g-3 mt-5",
    withDiscover: false,
    withPagination: false
}
export default ListOpportunity


/**
 * Opportunity item.
 */
const OpportunityItem = ({ col, image, publicId, officialLink, name, mention, bourseDuree, priseCharge, formationLevel, endDate, minAge, maxAge, city, country, deleteOpportunityFromStudentPanel, userPublicId, addFavoriteInStudentPanel, opportunitesInFavorite }) => (
    <div className={`col-lg-${col} col-md-6 col-sm-12`}>
        <article className="box">
            <div className="box-image">
                <img src={image} alt="" className="img-fluid" />
            </div>

            <h2 className="box-title">
                <Link to={`/opportunites/${publicId}`}>
                    {(name.length > 70) ? (name.substr(0, 69) + "...") : name}
                </Link>
            </h2>

            <div className="box-badges">
                <ul>
                    {[formationLevel, mention].map((tag, index) => (
                        <li key={`bourse_tag_item_${index}`} className="badge badge-soft-info box-badge">
                            {tag}
                        </li>
                    ))}
                    {[bourseDuree, getObjectByKey(priseCharge, "name")].map((category, index) => (
                        <li key={`bourse_category_item_${index}`} className="badge badge-soft-warning box-badge">
                            {category}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="box-meta">
                <ul>
                    <li>
                        
                    {
                        opportunitesInFavorite ? 
                            <i className='bx bxs-like' onClick={()=>deleteOpportunityFromStudentPanel({publicId:userPublicId, opportunityPublicId:publicId})}></i> :
                            <i className='bx bx-like' onClick={()=>addFavoriteInStudentPanel({publicId:userPublicId, opportunityPublicId:publicId})}></i>
                    }
                        
                    </li>
                    <li>
                        <a href="#" title="Pays hôte">
                            <i className="bx bx-map-pin"></i>
                            <span>{`${country.name} ${country.city}`}</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" title="Tranche d'âge éligible">
                            <i className="bx bx-user"></i>
                            <span>{`${minAge}-${maxAge}`}</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" title="Date limite de candidature">
                            <i className="bx bxs-hourglass"></i>
                            <span>{moment(new Date(endDate)).format('DD MMM YYYY')}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="box-content">
                <div className="d-flex align-items-center justify-content-between mt-4">
                    <div className="official-link">
                        <a href={officialLink}>Lien officiel</a>
                    </div>
                    <div className="read-more">
                        <Link to={`/opportunites/${publicId}`}>Lire plus</Link>
                    </div>
                </div>
            </div>
        </article>
    </div>
)