import React from 'react';
import PropTypes from 'prop-types';

import { Form, Button, Row, Col } from 'react-bootstrap';

export const FILTER_VERTICAL = "vertical"
export const FILTER_HORIZONTAL = "horizontal"
export const FILTER_WHITE = "white"
export const FILTER_BEIGE = "beige"

const Filter = ({ children, onSubmited, onReseted, alignement, submitLabel, bgColor, withReset, withShadow, withBorder }) => {

    const getLg = () => {
        if (alignement === FILTER_VERTICAL) {
            return ((withReset) ? 3 : 4)
        } else {
            return ((withReset) ? 1 : 2)
        }
    }

    const getMd = () => {
        if (alignement === FILTER_VERTICAL) {
            return ((withReset) ? 3 : 4)
        } else {
            return ((withReset) ? 1 : 3)
        }
    }

    const getXs = () => {
        if (alignement === FILTER_VERTICAL) {
            return ((withReset) ? 3 : 4)
        } else {
            return ((withReset) ? 2 : 6)
        }
    }

    return (
        <div className={`filters filters-${bgColor}${withShadow ? " filters-shadow" : ""}${withBorder ? " filters-border" : ""}`}>
            <div className={`filters-${alignement}`}>
                <Form onSubmit={onSubmited}>
                    <Row className="g-2">
                        {children}

                        {(withReset) ? (
                            <Col lg={getLg()} md={getMd()} xs={getXs()}>
                                <Button type="button" onClick={() => { if (onReseted) onReseted() }}>
                                    <i className='bx bx-reset'></i>
                                </Button>
                            </Col>
                        ) : null}

                        <Col lg={getLg()} md={getMd()} xs={getXs()}>
                            <Button type="submit">
                                <i className='bx bx-search-alt'></i>
                                {((!withReset) && (submitLabel && submitLabel !== "")) ? (
                                    <span id="submit-label">{submitLabel}</span>
                                ) : null}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}
Filter.propTypes = {
    alignement: PropTypes.string,
    submitLabel: PropTypes.string,
    bgColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    onSubmited: PropTypes.func.isRequired,
    withReset: PropTypes.bool,
    withShadow: PropTypes.bool,
    withBorder: PropTypes.bool
}
Filter.defaultProps = {
    alignement: FILTER_HORIZONTAL,
    withShadow: false,
    withReset: false,
    withBorder: false,
    submitLabel: "",
    bgColor: FILTER_WHITE
}
export default Filter
