import React from "react"

// import country list
import ListCountry from "../country/ListCountry"

// import constants
import { COUNTRY_AFRICAN, ITEMS_TO_DISPLAY_SM } from "../../../helpers/constants"


const StudyList = ({ items, option }) => {

    return (
        <React.Fragment>
            {(items.length > 0) ? (
                <ListCountry
                    items={items.slice(0, ITEMS_TO_DISPLAY_SM)}
                    withDiscover={(items.length > ITEMS_TO_DISPLAY_SM)}
                    discoverOptions={{ discoverLink: `#` }}
                    // discoverOptions={{ discoverLink: `/pays/regions/${option.slug}` }}
                />
            ) : (
                <div className="empty-list text-center">
                    <p className="mb-0 p-2">
                        {`La liste des pays ${(option.key !== COUNTRY_AFRICAN.key) ? "non" : ""} africains bientôt disponible.`}
                    </p>
                </div>
            )}
        </React.Fragment>
    )

}
export default StudyList