import { Component } from "react";
import PropTypes from 'prop-types';
import { APP_TOKEN } from '../../helpers/constants';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {getAllUniversities} from '../../services/opportunityService';
import Banner from '../../components/common/Banner';
import Article from '../../assets/images/placeholders/article.jpg';
import { setAppTokenAsked} from '../../store/actions';
import { connect } from 'react-redux';
import {getAllOpportunitiesAddedByUniversity, redeemOffer} from '../../services/opportunityService';
import ListOffer from "../../components/custom/opportunities/ListOffer";

class ListOpportunityByUniversity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            getAllUniversityState:[],
            getAllUniversityOfferState:[],
            universityPublicId:'',
            university:'',
            noRecordFound:'',
            opportunityPublicId:'',
            message:''
        };
        this.getAllUniversity = this.getAllUniversity.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setAcceptOffer = this.setAcceptOffer.bind(this);
    }

    componentDidMount() {
        try {
            let { onAppTokenAsked } = this.props;
            if (!localStorage.getItem(APP_TOKEN)) {
                onAppTokenAsked(true)
            }
            else {
                this.getAllUniversity();
            }
        }
        catch(error) {
            console.log(error)
        }
    }

    getAllUniversity() {
        getAllUniversities().then(response=>this.setState({getAllUniversityState:response?.universities})).catch(error=>console.log(error));
    }

    onSubmit(e) {
        e.preventDefault(); 
        getAllOpportunitiesAddedByUniversity({publicId:this.state.universityPublicId, universityId:this.state.university}).then(response=> this.setState({getAllUniversityOfferState:response})).catch(error=>console.log(error))
    }

    setAcceptOffer(data) {

        data['totalAmount']=200;
        data['discountAmount']=20;
        data['studentPublicId']='6YQxJxUKK1';
        data['universityPublicId']=this.state.universityPublicId;
        data['universityId']=this.state.university;

        redeemOffer(data).then(resp=>this.setState({message:resp})).catch(error=>console.log(error));
    }
    
    render() {
        return(
            <>
            {/* {console.log(this.state.universityPublicId, 'this.state.universityPublicId', 'universityId:', this.state.university, 'opportunityPublicId:', )} */}
                <Banner
                    bannerId="opportunity-banner"
                    bannnerInnerClasses={{ backgroundImage: `url(${Article})` }}
                    />
                <Container>
                    <h1 className='text-center my-5'>Opportunités universitaires</h1>  
                    <Form onSubmit={this.onSubmit} className='mb-5'>
                        <Row className="align-items-end">
                            <Col xs="4">
                                <Form.Group className='mb-0'>
                                    <Form.Label>Après sélectionnée?</Form.Label>
                                    <Form.Control id="university-public-id" name="university-public-id" required onChange={(e)=>this.setState({universityPublicId:e.target.value})} />
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Form.Group className='mb-0'>
                                    <Form.Label>université</Form.Label>
                                    <Form.Control as="select" required custom onChange={(e)=>this.setState({university:e.target.value})} name="university" id="university">
                                        <option value="">Veuillez sélectionner l'université</option>
                                        {this.state.getAllUniversityState?.map((university)=>(<option key={university.id} value={university.id}>{university.name}</option>))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs="4">
                                <Button type="submit" className="w-100 offer-btn">See Offers  <i className='bx bx-right-arrow-alt'></i></Button>
                            </Col>
                        </Row>
                    </Form>
                    {
                        this.state.message && 
                        <div className="text-success fw-bold mb-3">{this.state.message?.message}</div>
                    }
                    {
                        this.state.getAllUniversityOfferState?.errors && this.state.getAllUniversityOfferState?.errors[0] ? <div className="text-danger fw-bold mb-5">{this.state.getAllUniversityOfferState?.errors[0]}</div> :
                        this.state.getAllUniversityOfferState && this.state.getAllUniversityOfferState.length > 0 && 
                        <Row className="mb-5">
                            <Col>
                                <ListOffer offers={this.state.getAllUniversityOfferState} setAcceptOffer={this.setAcceptOffer} />
                            </Col>
                        </Row>
                    }
                </Container>
            </>
        );
    }
}
ListOpportunityByUniversity.propTypes = {
    onAppTokenAsked: PropTypes.func,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
}
const mapStateToProps = state => ({
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
})
const mapDispatchToProps = dispatch => ({
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ListOpportunityByUniversity)
// export default ListOpportunityByUniversity;