import React from 'react';
import PropTypes from 'prop-types';

// import deep-equal
import equal from "deep-equal"

// import store
import { connect } from 'react-redux';

// import store actions
import {
    setAppTokenAsked,
    setAppTokenRetrieved,
    setArticle, setOpportunity,
    togglePreloader
} from '../../store/actions';

// import constants
import { APP_TOKEN, USER_DATA } from '../../helpers/constants';
import { toast } from 'react-toastify';
// import services
import { getOpportunities, getStudentFavoriteOpportunitiesList, deleteStudentFavoriteOpportunity, addOpportunityInStudentFavoriteList } from '../../services/opportunityService';

// import utilities
import { isUnauthenticatedRequest } from '../../helpers/utilities';

// import common components
import Banner from '../../components/common/Banner';
import Leading from '../../components/common/Leading';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import Section from '../../components/common/Section';
import Maper from '../../components/common/Maper';

// import custom components
import ListArticle from '../../components/custom/opportunities/ListArticle';
import ListOpportunity from '../../components/custom/opportunities/ListOpportunity';
import FilterOpportunity from '../../components/custom/opportunities/FilterOpportunity';

// import marker image
import { mkOpportunity } from '../../assets/images';


class Opportunities extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            studentFavoriteOpportunities:[],
            userPublicId:'',
        }

        this.addFavoriteInStudentPanel = this.addFavoriteInStudentPanel.bind(this);
        this.studentFavoriteOpportunitiesList = this.studentFavoriteOpportunitiesList.bind(this);
        this.deleteOpportunityFromStudentPanel = this.deleteOpportunityFromStudentPanel.bind(this);
    }

    componentDidMount() {
        try {
            let user = JSON.parse(localStorage.getItem(USER_DATA));    
            this.setState({userPublicId: user.publicId})

            this.props.onPreloaderToggled(true, "Chargement des opportunités")
            if (!localStorage.getItem(APP_TOKEN)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.onGetOpportunities()
            }
            this.studentFavoriteOpportunitiesList(user?.publicId);
        } catch (error) {
            console.log(error)
        }
    }

    deleteOpportunityFromStudentPanel(data) {
        deleteStudentFavoriteOpportunity(data).then(res=> {
            toast.success("Opportunity is removed from the favorite.")
            this.studentFavoriteOpportunitiesList(data?.publicId)
        }
        ).catch(err=>console.log(err))
    }

    
    addFavoriteInStudentPanel(data) {
        addOpportunityInStudentFavoriteList(data).then(resp=> {
            toast.success("Opportunity is added in the favorite.")
            this.studentFavoriteOpportunitiesList(data?.publicId)
        }
        ).catch(err=>console.log(err))
    }
    
    studentFavoriteOpportunitiesList(userPublicId) {
        getStudentFavoriteOpportunitiesList({publicId:userPublicId, lookingFor:"opportunity"}).then(resp=>
            {                
                console.log(resp, 'studentFavoriteOpportunities')
                this.setState({studentFavoriteOpportunities:resp})
            }
        ).catch(err=>console.log(err));
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                if (this.props.tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    this.props.onAppTokenRetrieved(false)
                    this.onGetOpportunities()
                } else if (this.props.tokenFailed) {
                    this.props.onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Get opportunities.
     * 
     */
    onGetOpportunities() {
        getOpportunities().then((response) => {
            if (response && response.data) {
                this.props.onSetOpportunities(response.data)
                this.onGetArticles()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET OPPORTUNITIES ERROR")
            }
        }).catch((error) => {
            console.error(error, "GET OPPORTUNITIES ERROR")
        });
    }

    /**
     * Get articles.
     * 
     */
    onGetArticles() {
        getOpportunities().then((response) => {
            if (response && response.data) {
                this.props.onSetArticles(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET ARTICLES ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET ARTICLES ERROR")
        });
    }

    render() {

        const { opportunities, articles, isPreloader } = this.props

        return (
            <React.Fragment>
                <div id="opportunities" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {/* Banner */}
                    <Banner bannerId="opportunities-banner">
                        <Leading
                            title="Nos opportunités"
                            subtitle="Ressources et conseils"
                        />
                    </Banner>

                    {/* Breadcrumbs */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Breadcrumbs
                                    subtitle="Opportunités"
                                    subtitleLink="/opportunites"
                                    currentTile="Toutes les opportunités"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Articles */}
                    <Section
                        subtitle="Conseils & Ressources"
                        sectionId="opportunities-articles"
                        sectionClasses="opportunities-articles py-5"
                    >
                        <div className="row">
                            {(articles.length > 0) ? (
                                <div className="col-lg-12">
                                    <ListArticle items={articles} />
                                </div>
                            ) : (
                                <div className="empty-list text-center">
                                    <p className="mb-0 p-2">
                                        La liste de nos articles bientôt disponible.
                                    </p>
                                </div>
                            )}
                        </div>
                    </Section>

                    <div className="ads">
                        <div className="leaderboard center-box">
                            PUB MOYENNE ICI
                        </div>
                    </div>

                    {/* Opportunities, Map */}
                    <Section
                        subtitle="Les Dernières Opportunités"
                        sectionId="opportunities-last"
                        sectionClasses="opportunities-last py-5"
                        sectionInnerClasses=""
                    >
                        {(opportunities.length > 0) ? (
                            <>
                                <div className="my-5">
                                    <Maper
                                        withPopup
                                        icon={mkOpportunity}
                                        items={opportunities}
                                        redirectLink="/opportunites"
                                    />
                                </div>

                                <div className="ads">
                                    <div className="x-leaderboard center-box">
                                        PUB LARGE ICI
                                    </div>
                                </div>

                                <div className="container mt-5">
                                    <div className="row">
                                        <div className="col-lg-12 mt-2 opportunities-filters">
                                            <FilterOpportunity />
                                        </div>
                                        <div className="col-lg-12">
                                            <ListOpportunity items={opportunities} deleteOpportunityFromStudentPanel={this.deleteOpportunityFromStudentPanel} studentFavoriteOpportunities={this.state.studentFavoriteOpportunities} userPublicId={this.state.userPublicId} addFavoriteInStudentPanel={this.addFavoriteInStudentPanel} listerClasses="g-3 mt-5" />
                                            {/* <ListOpportunity items={opportunities} studentFavoriteOpportunities={this.state.studentFavoriteOpportunities} userPublicId={this.state.userPublicId} addFavoriteInStudentPanel={this.addFavoriteInStudentPanel} listerClasses="g-3 mt-5" /> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="empty-list text-center">
                                <p className="mb-0 p-2">
                                    La liste de nos opportunités, à ne pas rater, bientôt disponible.
                                </p>
                            </div>
                        )}
                    </Section>

                    <div className="ads my-5">
                        <div className="hugeboard center-box">
                            PUB EXTRA LARGE ICI
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
Opportunities.propTypes = {
    opportunites: PropTypes.array,
    articles: PropTypes.array,
    onSetOpportunities: PropTypes.func,
    onSetArticles: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
}
const mapStateToProps = state => ({
    opportunities: state.opportunities,
    articles: state.articles,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetOpportunities: (data) => dispatch(setOpportunity(data)),
    onSetArticles: (data) => dispatch(setArticle(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Opportunities)