import React from 'react'
import PropTypes from 'prop-types'

import { Tab, Nav, Row, Col, Card } from 'react-bootstrap'

const TabsCountry = ({ tabId, items, defaultActive }) => {

    return (
        <Tab.Container id={tabId} defaultActiveKey={defaultActive}>
            <Row>
                <Col lg={12} sm={12}>
                    <Nav variant="pills">
                        {items && items.map((nx, idx) => (
                            <Nav.Item key={`tab_nav_item_${idx}`}>
                                <Nav.Link eventKey={nx.key}>
                                    <i className={`bx bx-${nx.icon} nav-icon d-block mb-2`} />
                                    <p className="font-weight-bold mb-0">{nx.label}</p>
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
                <Col lg={12} sm={12}>
                    <Card className="mb-0">
                        <Card.Body>
                            <Tab.Content>
                                {items && items.map((tpx, idx) => (
                                    <Tab.Pane key={`tab_pane_item_${idx}`} eventKey={tpx.key}>
                                        <h4 className="card-title">
                                            {tpx.label}
                                        </h4>
                                        <Card.Body>
                                            <div className="card-text">
                                                <div dangerouslySetInnerHTML={{ __html: tpx.content }} />
                                            </div>
                                        </Card.Body>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Tab.Container>
    )

}
TabsCountry.propTypes = {
    tabId: PropTypes.string,
    defaultActive: PropTypes.string,
    items: PropTypes.array,
}
TabsCountry.defaultProps = {
    tabId: "tabs-country",
    defaultActive: "",
}
export default TabsCountry