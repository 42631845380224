import React from 'react'
import PropTypes from 'prop-types'

// import router
import { Link } from "react-router-dom"

// import lister
import Lister from '../../common/Lister'

// import constants
import { COUNTRY_INFOS_LINK, COUNTRY_SCHOOLS_LINK, ITEMS_TO_DISPLAY_SM } from '../../../helpers/constants'

// import utilities
import { calculateCol } from '../../../helpers/utilities'
import { COUNTRY } from '../../../helpers/dummies'


const ListCountry = ({ items, itemsPerRow, listerClasses, withDiscover, discoverOptions, withPagination }) => {

    return (
        <React.Fragment>
            <Lister
                listerClasses={`${listerClasses} countries`}
                withDiscover={withDiscover}
                withPagination={withPagination}
                discoverOptions={discoverOptions}
            >
                {items && items.map((item, index) => {
                    let itemCountry = {
                        ...item,
                        image: item.image ? item.image : COUNTRY.image,
                        name: item.name ? item.name : COUNTRY.name
                    }
                    return (
                        <CountryItem
                            key={`country_item_${index}`}
                            col={calculateCol(itemsPerRow)}
                            {...itemCountry}
                        />
                    )
                })}
            </Lister>
        </React.Fragment>
    )

}
ListCountry.propTypes = {
    items: PropTypes.array,
    itemsPerRow: PropTypes.number,
    listerClasses: PropTypes.string,
    discoverOptions: PropTypes.object,
    withDiscover: PropTypes.bool,
    withPagination: PropTypes.bool
}
ListCountry.defaultProps = {
    itemsPerRow: ITEMS_TO_DISPLAY_SM,
    listerClasses: "g-3 mt-3",
    withDiscover: false,
    withPagination: false
}
export default ListCountry


/**
 * Country item.
 */
const CountryItem = ({ col, image, name, publicId }) => (
    <div className={`col-lg-${col} col-md-6 col-sm-12`}>
        <div className="box">
            <img src={image} className="img-fluid" alt="" />
            <div className="box-data">
                <h4>{name}</h4>
            </div>
            <div className="box-info">
                <h4>{name}</h4>
                <div className="box-links">
                    <Link to={`/pays/${publicId}`} title={COUNTRY_INFOS_LINK}>
                        <i className='bx bx-show-alt'></i>
                    </Link>
                    <Link to={`/pays/${publicId}`} title={COUNTRY_SCHOOLS_LINK}>
                        <i className='bx bxs-school'></i>
                    </Link>
                </div>
            </div>
        </div>
    </div>
);