import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { USER_DATA, APP_TOKEN } from '../../helpers/constants'
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { setAppTokenAsked, setAppTokenRetrieved, togglePreloader } from '../../store/actions';
import { Component } from "react";
import {getStudentOffers, getCurrentUser} from '../../services/authService';
import AcceptedOffers from '../../components/custom/opportunities/AcceptedOffers';

class StudentOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser:{},
            studentOffersList:[]
        };
        this.getOffers = this.getOffers.bind(this);
        this.getCurrentUserInformation = this.getCurrentUserInformation.bind(this);
    }

    componentDidMount() {
        try {
            let user = JSON.parse(localStorage.getItem(USER_DATA));
            let { onPreloaderToggled, onAppTokenAsked } = this.props

             //Load app token or Load schools
            if (!localStorage.getItem(APP_TOKEN)) {
                onAppTokenAsked(true)
            }
            else {
                // this.onGetSchools()
                this.getCurrentUserInformation(user?.publicId);
                this.getOffers(user?.publicId);
                                
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    getCurrentUserInformation(userId) {
        getCurrentUser(userId).then(response=>{
            this.setState({currentUser:response?.user});
        }).catch(err=>console.log(err));
    }

    getOffers(userPublicId) {
        getStudentOffers({"studentPublicId":userPublicId}).then(res=>this.setState({studentOffersList:res}))
    }

    render() {
        return(
            <>
            {console.log(this.state.studentOffersList, 'this.state.studentOffersList')}
            {
                this.state.studentOffersList && this.state.studentOffersList.length > 0 && <AcceptedOffers offers={this.state.studentOffersList} />
            }
            </>
        );
    }
}
StudentOffers.propTypes = {
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
}
const mapStateToProps = state => ({
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(StudentOffers)