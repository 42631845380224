import React, { useState } from "react"
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'react-bootstrap';
// import router
import { Link, withRouter, useHistory } from "react-router-dom"
// import { Link, withRouter, useHistory } from "react-router-dom"

// import common components
import Sidebar from "./Sidebar"
import Banner from "../common/Banner"
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Logo from '../../assets/images/logos/logo.png';
// import constants
import { USER_DATA } from "../../helpers/constants"

const Blocker = ({ children, history }) => {

    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem(USER_DATA)))
    const [profileCompletePercentage, setProfileCompletePercentage] = useState(100);
    let profilePercentage = profileCompletePercentage == 20 ? '#F5CD28' : profileCompletePercentage == 40 ? '#E5F528' : profileCompletePercentage == 60 ? '#12CFEF' : profileCompletePercentage == 80 ? '#9DC828' : profileCompletePercentage == 100 ? '#437B29' : '';
    return (
        <React.Fragment>
            <div className="account-main">
                <Banner bannerId="account-banner" />

                <div className="leave-links d-flex align-items-center justify-content-between">
                    <div onClick={() => history.goBack()}>
                        <i className='bx bx-arrow-back me-1'></i>
                        Page précédente
                    </div>
                    <Link to="/">
                        <i className='bx bxs-home me-1'></i>
                        Accueil
                    </Link>
                </div>

                <Container className="py-4 px-2" style={{maxWidth: '992px'}} >
                    <Row className="row">
                        <Col xs="2">
                            {(userData && userData.id) ? (
                                <>
                                    <div className="avatar-xl mx-auto mb-3 position-relative">
                                        <div style={{ width: '100%', height: '100%', margin:'auto' }}>
                                            <CircularProgressbar value={profileCompletePercentage} text={`${profileCompletePercentage}%`} strokeWidth="3" className="student-profile"                                            
                                            styles={{
                                                path: {
                                                    transform: 'rotate(0.30turn)',
                                                    stroke: `${profilePercentage}`,
                                                    transformOrigin: 'center center',
                                                },
                                              }}
                                              />
                                            <div className="profile-camera"><i className='bx bx-camera'></i></div>
                                        </div>
                                        {!userData.avatar ? (
                                            <span className="avatar-title rounded-circle sidebar-avatar">
                                                {(userData.name.charAt(0).toUpperCase() || "") + (userData.name.charAt(1).toUpperCase() || "")}
                                            </span>
                                        ) : (
                                            <img className="rounded-circle avatar-md user-profile-image" src={Logo} alt="user avatar" />
                                            )}
                                    </div>
                            </>
                        ) : null}
                            <h5 className={`profile-complete-percentage ${profileCompletePercentage == 100 && 'profile-completed'}`}>{`${profileCompletePercentage <= 80 ? 'Profil en cours' : 'Félicitations! Votre profil est complet!'}`}</h5>
                            {
                                profileCompletePercentage !== 100 &&
                                <h3 className='profile-percentage' style={{color:profilePercentage}}>{`${profileCompletePercentage}%`}</h3>
                            }
                            {/* <h5 className="text-success text-center" style={{fontSize:'12px'}}>Félicitations!<br/> Votre profil est complet!</h5> */}
                        </Col>
                        <Col>
                            <h5 style={{
                                color: '#005073',
                                fontSize: '26px',
                                fontWeight: 500,
                                letterSpacing: '0.65px',
                                paddingTop: '20px',
                                marginBottom: '10px',
                            }}>{userData.name}</h5>
                            <span style={{
                                olor: '#005073',
                                fontFamily: 'Montserrat',
                                fontSize: '14px',
                                borderRadius: '25px',
                                background: 'rgba(18, 207, 239, 0.12)',
                                display: 'inline-block',
                                padding:'5px 12px',
                                fontWeight: 500,
                                letterSpacing: '0.35px'
                            }}>ID: {userData.publicId}</span>
                        </Col>
                    </Row>
                    <Row className="row justify-content-between mt-4 mb-5">
                        <Col className="custom-sidebar">
                            <Sidebar user={userData} />
                        </Col>
                        <Col className="custom-sidebar-content">
                            {children}
                        </Col>
                        {/* <Col className="col-lg-2">
                            <p className="mb-0 p-2">
                                Ads and Informations
                            </p>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
Blocker.propTypes = {
    children: PropTypes.node
}
export default withRouter(Blocker)
