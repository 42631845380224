import React from 'react'
import PropTypes from 'prop-types'

// import router
import { Link, withRouter } from "react-router-dom"

// import components
import { Form, Col, Button } from 'react-bootstrap';

// import deep-equal
import equal from "deep-equal";

// import toaster
import { toast } from 'react-toastify';

// import phone input
import PhoneInput from 'react-phone-input-2';

// import common components
import Loader from '../../components/common/Loader';

// import store and actions
import { connect } from 'react-redux'
import { setAppTokenAsked, setAppTokenRetrieved, setSchool, togglePreloader } from '../../store/actions';

// import constants
import { CREDENTIALS_ERROR, LOGIN_ERROR, UNKNOWN_ERROR, USER_DATA } from '../../helpers/constants';

// import services
import { } from '../../services/schoolService';
import { DIPLOMES, LOCATIONS, SECTORS } from '../../helpers/dummies';
import Selecter from '../../components/common/Selecter';
import { slugify } from '../../helpers/utilities';


class ApplyToSchool extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            userData: {}, inputs: {},
            loading: false, failure: "", success: false
        }
    }

    componentDidMount() {
        try {
            let user = JSON.parse(localStorage.getItem(USER_DATA))

            this.setState({
                userData: JSON.parse(localStorage.getItem(USER_DATA))
            })
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if ((!equal(prevState, this.state))) {
                let { location, history } = this.props

                if (this.state.success) {
                    toast.success(
                        "Votre candidature a bien été envoyée.",
                        {
                            onClose: () => {
                                if (location.state) {
                                    history.push({
                                        pathname: `/etablissements/${slugify(location.state.schoolName)}`,
                                        state: location.state
                                    })
                                }
                            }
                        }
                    )
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    onChanged = (e) => {
        if (e.target) {
            let { name, value } = e.target
            this.setState({
                inputs: { ...this.state.inputs, [name]: value }
            })
        }
    }

    onSelected = (selected, option) => {
        this.setState({
            inputs: { ...this.state.inputs, [option]: [selected] }
        })
    }

    onSubmited = (e) => {
        e.preventDefault();

        // Update state
        this.setState({ loading: true, failure: "" })

        let { location, history } = this.props

        setTimeout(() => {
            this.setState({
                loading: false, failure: "", success: true
            }, () => {
                if (location.state) {
                    history.push({
                        pathname: `/etablissements/${slugify(location.state.schoolName)}`,
                        state: location.state
                    })
                }
            })
        }, 2000);
    }

    render() {
        const { loading, failure, success, inputs } = this.state
        const { location } = this.props

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 pt-5">
                            <h3 className="text-center mb-1">
                                Envoyer votre candidature à
                                {location.state ? ` ${location.state.schoolName}` : " cet établissement"}
                            </h3>
                            <center>
                                <small className="text-muted text-center">
                                    Complétez le formulaire ci-dessous pour envoyer une
                                    demande d'inscription à cet établissement
                                </small>
                            </center>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <Form onSubmit={this.onSubmited} className="auth-form">
                                <div className="text-muted mb-2">
                                    Vos informations personnelles
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Nom
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Entrez votre nom"
                                                required onChange={this.onChanged}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Entrez votre email"
                                                required onChange={this.onChanged}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label> Contact téléphonique </Form.Label>
                                            <PhoneInput
                                                country={'us'}
                                                value={inputs.phone || ""}
                                                onChange={this.onChanged}
                                                placeholder="Entrez votre numéro de téléphone"
                                                inputProps={{
                                                    id: 'phone',
                                                    name: 'phone',
                                                    required: true,
                                                    className: "form-control"
                                                }}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Pays de résidence</Form.Label>
                                            <Selecter
                                                id="residence"
                                                name="residence"
                                                placeholder="Sélectionne un pays..."
                                                selecterClasses="apply-selects"
                                                selecterClassesPrefix="apply-selects-prefix"
                                                selected={inputs.residence || []}
                                                options={LOCATIONS}
                                                onSelected={this.onSelected}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Niveau d'étude actuel</Form.Label>
                                            <Selecter
                                                id="currentDiploma"
                                                name="currentDiploma"
                                                placeholder="Sélectionnez un niveau..."
                                                selecterClasses="apply-selects"
                                                selecterClassesPrefix="apply-selects-prefix"
                                                selected={inputs.currentDiploma || []}
                                                options={DIPLOMES}
                                                onSelected={this.onSelected}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Formation actuelle</Form.Label>
                                            <Selecter
                                                id="currentFormation"
                                                name="currentFormation"
                                                placeholder="Sélectionnez une formation..."
                                                selecterClasses="apply-selects"
                                                selecterClassesPrefix="apply-selects-prefix"
                                                selected={inputs.currentFormation || []}
                                                options={SECTORS}
                                                onSelected={this.onSelected}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="text-muted mt-3 mb-2">
                                    Informations de la candidature
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Niveau d'étude</Form.Label>
                                            <Selecter
                                                id="wantedDiploma"
                                                name="wantedDiploma"
                                                placeholder="Sélectionnez un niveau..."
                                                selecterClasses="apply-selects"
                                                selecterClassesPrefix="apply-selects-prefix"
                                                selected={inputs.wantedDiploma || []}
                                                options={DIPLOMES}
                                                onSelected={this.onSelected}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Formation</Form.Label>
                                            <Selecter
                                                id="wantedFormation"
                                                name="wantedFormation"
                                                placeholder="Sélectionnez une formation..."
                                                selecterClasses="apply-selects"
                                                selecterClassesPrefix="apply-selects-prefix"
                                                selected={inputs.wantedFormation || []}
                                                options={SECTORS}
                                                onSelected={this.onSelected}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Autres informations</Form.Label>
                                            <Form.Control
                                                as="textarea" rows={5}
                                                onChange={this.onChanged}
                                                id="moreInformations"
                                                name="moreInformations"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                {(failure !== "") ? (
                                    <div className="alert alert-danger" role="alert">
                                        {failure}
                                    </div>
                                ) : null}

                                {(success) ? (
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        Votre candidature a bien été envoyée.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                                    </div>
                                ) : null}

                                {loading ? (
                                    <Loader color="blue" />
                                ) : (
                                    <button className="btn-discover" type="submit">
                                        Envoyer
                                    </button>
                                )}
                            </Form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
ApplyToSchool.propTypes = {
    school: PropTypes.object,
    onSetSchool: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
    onAppTokenAsked: PropTypes.func,
    onAppTokenRetrieved: PropTypes.func,
    onPreloaderToggled: PropTypes.func,
}
const mapStateToProps = state => ({
    school: state.school,
    schools: state.schools,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetSchool: data => dispatch(setSchool(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ApplyToSchool)
