import React from "react"

// import router, link
import { withRouter, Link } from "react-router-dom"

// import store
import { connect } from 'react-redux'

// import deep-equal
import equal from "deep-equal"

// import logos
import logo from "../../assets/images/logos/logo.png"
import logoAnimated from "../../assets/images/logos/logo.gif"

// import constants
import { USER_DATA } from "../../helpers/constants"
import Searcher from "./Searcher"


class Header extends React.Component {

    componentDidMount() {
        //Header Scroll
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                elementId.classList.add("header-scrolled");
            } else {
                elementId.classList.remove("header-scrolled");
            }
        });
        window.scrollTo(0, 0);
    }

    /**
     * Nav Toggle.
     * 
     */
    onNavToggled = () => {
        let navbarElement = document.getElementById("navbar");
        let togglerElement = document.getElementById("toggler");
        if (navbarElement) {
            navbarElement.classList.toggle('navbar-mobile')
        }
        if (togglerElement) {
            togglerElement.classList.toggle('bx-menu')
            togglerElement.classList.toggle('bx-x')
        }
    }

    /**
     * Logout user.
     * 
     */
    onLogout = () => {
        try {
            localStorage.removeItem(USER_DATA)
            this.props.history.push("/")
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { pathname } = this.props.location

        return (
            <header id="header" className={`fixed-top d-flex align-items-center header-${(pathname === "/") ? "transparent" : "white"}`}>
                <div className="container-fluid">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <Link className="navbar-brand" to="/">
                            <img id="logo-simple" src={logo} alt="app logo" />
                            <img id="logo-animated" src={logoAnimated} alt="app logo animated" />
                        </Link>
                        {(pathname !== "/") ? (
                            <div className="header-filters flex-grow-1 mx-1 mx-xxl-4">
                                <Searcher col={12} />
                            </div>
                        ) : null}
                        <nav id="navbar" className="navbar">
                            {(localStorage.getItem(USER_DATA) && ["/help", "/messages", "/notifications", "/account"].includes(pathname)) ? (
                                <ul>
                                    <li>
                                        <Link to="/help" className={`nav-link${(pathname.includes("/help")) ? " active" : ""} nav-account`}>
                                            <i className='bx bx-help-circle bx-sm lh-1'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/messages" className={`nav-link${pathname.includes("/messages") ? " active" : ""} nav-account`}>
                                            <i className='bx bxs-message-dots bx-sm lh-1'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notifications" className={`nav-link${(pathname.includes("/notifications")) ? " active" : ""} nav-account`}>
                                            <i className='bx bxs-bell bx-sm lh-1'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className={`nav-link nav-account`} onClick={() => this.onLogout()}>
                                            <i className='bx bx-log-out-circle bx-sm lh-1'></i>
                                        </Link>
                                    </li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <Link to="/etablissements" className={`nav-link${(pathname.includes("/etablissements")) ? " active" : ""}`}>
                                            Annuaire
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/opportunites" className={`nav-link${(pathname.includes("/opportunites")) ? " active" : ""}`}>
                                            Opportunités
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="" className={`nav-link${(pathname === "") ? " active" : ""}`}>
                                            Orientation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="" className={`nav-link${(pathname === "") ? " active" : ""}`}>
                                            Métiers
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="" className={`nav-link${(pathname === "") ? " active" : ""}`}>
                                            Partenariat
                                        </Link>
                                    </li>
                                    <li>
                                        {localStorage.getItem(USER_DATA) ? (
                                            <Link to="/account" className={`nav-link${(["/account"].includes(pathname)) ? " active" : ""} nav-account`}>
                                                <i className='bx bxs-user-circle bx-sm lh-1'></i>
                                            </Link>
                                        ) : (
                                            <Link to="/login" className={`nav-link${(["/login", "/register"].includes(pathname)) ? " active" : ""} nav-account`}>
                                                <i className='bx bx-user-circle bx-sm lh-1'></i>
                                            </Link>
                                        )}
                                    </li>
                                </ul>
                            )}

                            <i
                                id="toggler"
                                className="bx bx-menu mobile-nav-toggle"
                                onClick={this.onNavToggled}
                            />
                        </nav>
                    </div>
                </div>
            </header>
        )
    }

}
const mapStateToProps = state => ({

})
const mapDispatchToProps = dispatch => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))