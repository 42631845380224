import React from 'react'
import PropTypes from 'prop-types'
import BannerDashboard from '../../components/common/BannerDashboard'
import { USER_DATA, USER_PARENT, USER_EMAIL_VERIFY_CODE, STUDENT_STATUS } from '../../helpers/constants'
import { DIPLOMES } from "../../helpers/dummies";
import Sidebar from '../../components/layout/Sidebar'
import { Button, Modal, ModalBody, ModalTitle, Form, Container, Row, Col, Tab, Nav, Accordion, Card } from 'react-bootstrap';
import {sendCodeToUserToVerifyEmail, submitCodeToVerifyEmail, getCurrentUser, getCurrentUserFavoriteUniversities, getCurrentUserFavoriteOpportunities, getCurrentUserFavoriteUniverisityOpportunitiesCount} from '../../services/authService';
import {getCountries, submitUserMandatoryFields} from '../../services/userMandatoryFields';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import VerificationInProgress from '../../assets/images/placeholders/verification-in-process.png';
import VerificationExpired from '../../assets/images/placeholders/verification-expired.png';
import VerificationSuccessfull from '../../assets/images/placeholders/verification-successfull.png';

class Dashboard extends React.Component {

    constructor(props) {
        super(props)

        // init state
        this.state = {
            userName: "",
            userData: {},
            emailCodeWithPublicId:{verificationCode:'', publicId:''},
            failure:"",
            areAllFieldSelected:false,
            thankMessage:false,
            success:"",
            isEmailVerified: false,
            isUserVerified:false,
            codeSentEmail:false,
            currentUser:{},
            countryList:[],
            wait:'',
            mandatoryFields:{publicId:'', countryId:"", whatsapp_number:null, education_level:null, student_status:null},
            studentStatus:STUDENT_STATUS,
            educationLevel:DIPLOMES,
        }
        this.onClosed = this.onClosed.bind(this);
        this.sendCodeToEmailAddress = this.sendCodeToEmailAddress.bind(this);
        this.submitCodeToEmailAddress = this.submitCodeToEmailAddress.bind(this);
        this.selectMandatoryFieldsValue = this.selectMandatoryFieldsValue.bind(this);
        this.submitMandatoryFields = this.submitMandatoryFields.bind(this);
        this.getCurrentUserInformation = this.getCurrentUserInformation.bind(this);
    }

    componentDidMount() {
        try {

            let user = JSON.parse(localStorage.getItem(USER_DATA))

            this.getCurrentUserInformation(user?.publicId);
                        
            let userPseudo = user.name.split(" ")
            this.setState({
                userName: userPseudo[1],
                userData: user,
                mandatoryFields:{publicId:user?.publicId, countryId:"", whatsapp_number:"", education_level:"", student_status:""},
            })           

            getCountries().then(resp=>this.setState({countryList:resp}));
            getCurrentUserFavoriteUniversities({publicId:user?.publicId, lookingFor:'university'}).then(res=>{this.setState({favoriteUniversities:res})})
            getCurrentUserFavoriteOpportunities({publicId:user?.publicId, lookingFor:'opportunity'}).then(res=>{this.setState({favoriteOpportunities:res})})
        } catch (error) {
            console.log(error)
        }
    }

    getUserData() {
        try {
            return JSON.parse(localStorage.getItem(USER_DATA))
        } catch (error) {
            console.log(error)
            return ""
        }
    }

    getCurrentUserInformation(userPublicId) {
        getCurrentUser(userPublicId).then(response=>
            {
                console.log(response, 'response?.email_verified_at')
                this.setState({currentUser:response});
                this.setState({
                    isUserVerified: response.email_verified_at != null ? true : false,
                    isEmailVerified: response.email_verified_at != null ? false : true
                })           
            }
        ).catch(err=>console.log(err));
    }

    onClosed(){
        this.setState({isEmailVerified:false})
    }

    sendCodeToEmailAddress() {
        let publicId = this.state.userData?.publicId;
        this.setState({wait:'Please wait...'});
        sendCodeToUserToVerifyEmail(publicId).then((response)=>{
            this.setState({wait:''});   
            this.setState({codeSentEmail:true})
        }).catch(err=>console.log(err, 'error'))
    }
        
    onChange = (e) => {
        if (e.target) {
            this.setState({emailCodeWithPublicId: {...this.state.emailCodeWithPublicId, verificationCode:e.target.value, publicId:this.state.userData?.publicId}})
        }
    }
        
    submitCodeToEmailAddress(e) {
            e.preventDefault();
            submitCodeToVerifyEmail(this.state.emailCodeWithPublicId).then((response)=>{
                if (response.errors && response.errors[0] && (response?.errors[0] == USER_EMAIL_VERIFY_CODE)) {
                    this.setState({failure: response.errors[0], success:"" })
                }
                else if(!response.errors){
                    this.setState({failure: "", success:"You are verified." })
                    this.getCurrentUserInformation(this.state.currentUser?.user?.publicId);
                    setTimeout(()=>{
                        this.setState({failure: "", emailCodeWithPublicId:{verificationCode:""} })
                        this.setState({isEmailVerified:false})
                        this.setState({codeSentEmail:false})
                    },1000)
            }
        }).catch(err=>console.log(err, 'error'))
    }

    selectMandatoryFieldsValue(e) {
        if(e.target) {
            let {name, value} = e.target;
            this.setState({mandatoryFields:{...this.state.mandatoryFields, [name]:value}})
        }
    }

    submitMandatoryFields(e) {
        e.preventDefault();
        if(this.state.mandatoryFields.countryId == '' || this.state.mandatoryFields.education_level == '' || this.state.mandatoryFields.student_status == '' || this.state.mandatoryFields.whatsapp_number == '') {
            this.setState({areAllFieldSelected: true});
        }
        else {
            submitUserMandatoryFields(this.state.mandatoryFields).then(() => {
                this.setState({mandatoryFields:{publicId:"", countryId:"", whatsapp_number:"", education_level:"", student_status:""}});
                this.setState({thankMessage: true});
                const {history} = this.props;
                history.push('/favorites')
            }).catch(error=>console.log("this is the error", error))
        }
        setTimeout(()=>{
            this.setState({areAllFieldSelected:false});
            this.setState({thankMessage:false});
        },2000)
    }

    render() {

        const { userData, isEmailVerified, codeSentEmail, failure, success, countryList, studentStatus, educationLevel, mandatoryFields, areAllFieldSelected, thankMessage, currentUser, wait} = this.state
        return (
            <React.Fragment>
                <BannerDashboard />

                {/* Form with Verification Email Content */}
                {
                isEmailVerified && 
                    <div className="get-verified">
                        <Row className='align-items-center'>
                            <Col xs="7" className="get-verified-text">
                                Vérifiez votre statut étudiant pour accéder à du contenu et des offres exclusives!
                                {
                                    codeSentEmail && <div className='text-success my-2 fs-6'>Kindly check your email to fill the code below in the form.</div>
                                }
                            </Col>
                            <Col xs="5" className='text-end'>
                                <button disabled={this.state.wait} className='get-verified-btn' onClick={this.sendCodeToEmailAddress}>Être vérifié
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none" className='position-absolute '>
                                        <path d="M1.16907 6.00006H14.1495" stroke="#FDFDFD" strokeWidth="1.68421" strokeMiterlimit="10" strokeLinecap="round"/>
                                        <path d="M10.0584 0.947388L15.0286 6.00002L10.0584 11.0527" stroke="#FDFDFD" strokeWidth="1.68421" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <p className='text-danger mt-3 text-center'>{this.state.wait}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                            {
                                codeSentEmail && 
                                    <Container className='p-0'>
                                        <Form.Label>Enter Email Code</Form.Label>
                                        <Form>
                                            <Row>
                                                <Col className='col-12'>
                                                    <Form.Control
                                                        type="text"
                                                        id="verification-code"
                                                        name="verification-code"
                                                        onChange={this.onChange}
                                                        value={this.state.emailCodeWithPublicId.verificationCode}
                                                        />
                                                </Col>
                                                {failure !== "" && <Col className="text-danger col-12 mt-2">{ failure}</Col>}
                                                {success !== "" && <Col className="text-success col-12 mt-2">{success}</Col>}
                                            </Row>
                                            <Row className='mx-0 mt-3'>
                                                <Button disabled={this.state.emailCodeWithPublicId.verificationCode === ""} className='btn-discover' type="submit" onClick={this.submitCodeToEmailAddress}>Submit</Button>
                                            </Row>
                                        </Form>
                                    </Container>
                            }
                            </Col>
                        </Row>
                    </div>
                    }

                    {/* In Process Message Content */}
                    {
                        !this.state.isEmailVerified && currentUser?.user?.checkedAccountAt == null && currentUser?.user?.reject_reason == null &&
                        <div className="get-verified verification-in-process">
                                <Row className='align-items-center'>
                                    <Col xs="7" className="get-verified-text">La vérification de votre profil est en cours</Col>
                                    <Col xs="5" className='text-end'>
                                    <img src={VerificationInProgress} alt="Verification In Progress" className='img-fluid' />
                                    </Col>
                                </Row>
                            </div>
                    }

                    {/* Success Message Content */}
                    {
                       !this.state.isEmailVerified && currentUser?.user?.checkedAccountAt != null && currentUser?.user?.reject_reason == null &&
                            <div className="get-verified verification-successfull">
                                <Row className='align-items-center'>
                                    <Col xs="7" className="get-verified-text">
                                        <h3>Félicitations!</h3>
                                        Votre profil étudiant est vérifié!
                                        <p>Demandez à mettre à jour votre profil</p>
                                    </Col>
                                    <Col xs="5" className='text-end'>
                                    <img src={VerificationSuccessfull} alt="Verification In Progress" className='img-fluid' />
                                    </Col>
                                </Row>
                            </div>
                    }
                    
                    {/* failure Message Content */}
                    {
                        !this.state.isEmailVerified && currentUser?.user?.checkedAccountAt == null && currentUser?.user?.reject_reason != null &&
                            <div className="get-verified verification-expired">
                                <Row className='align-items-center'>
                                    <Col xs="7" className="get-verified-text">
                                        {currentUser?.user?.reject_reason}
                                        {/* Heureux que vous soyez elle mais nous ne pouvons pas vous vérifier en tant qu'étudiant actuel pour le moment, contactez-nous s'il y a un changement dans votre situation */}
                                    </Col>
                                    <Col xs="5" className='text-end'>
                                    <img src={VerificationExpired} alt="Verification In Progress" className='img-fluid' />
                                    </Col>
                                </Row>
                            </div>
                    }
                
                    {/* Form for All mendatory fields */}
                    {
                        !isEmailVerified &&  currentUser?.user?.whatsapp_number == null && currentUser?.user?.student_status == null && currentUser?.user?.education_level == null && (
                            <div className="accordion content-accordion mt-4" id="accordionSchoolContent" defaultActiveKey>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={`heading`}>
                                        <button
                                            className="accordion-button"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target={`#collapse`}
                                            aria-expanded="true" aria-controls={`collapse`}
                                        >
                                            Mandatory Fields
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse`}
                                        className="accordion-collapse collapse show"
                                        aria-labelledby={`heading`}
                                        data-bs-parent="#accordionSchoolContent"
                                    >
                                        <div className="accordion-body">
                                            <Container className='p-0'>
                                                {areAllFieldSelected && <div className='text-danger fw-bold'>All the fields are required.</div>}
                                                {thankMessage && <div className='text-success fw-bold'>Your data saved successfully.</div>}
                                                <Form>
                                                    <Form.Row>                          
                                                        <Form.Group as={Col} controlId="formGridState" className="col-12">
                                                            <Form.Label>Country</Form.Label>
                                                            <Form.Control as="select" required custom onChange={this.selectMandatoryFieldsValue} name="countryId">
                                                                <option value="" selected={mandatoryFields.countryId==""&&'selected'}>Please select country</option>
                                                                {
                                                                    countryList && countryList.length > 0 && countryList?.map(country=>(
                                                                        <option key={country.id} value={country.id}>{country.name}</option>
                                                                    ))
                                                                }
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridState" className="col-12">
                                                            <Form.Label>Education Level</Form.Label>
                                                            <Form.Control as="select" required custom onChange={this.selectMandatoryFieldsValue} name="education_level">
                                                                <option value="" selected={mandatoryFields.education_level==""&&'selected'}>Please select Education Level</option>
                                                                {educationLevel?.map((level)=>(<option key={level} value={level.value}>{level.label}</option>))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridState" className="col-12">
                                                            <Form.Label>Student Status</Form.Label>
                                                            <Form.Control as="select" required custom onChange={this.selectMandatoryFieldsValue} name="student_status">
                                                                <option value="" selected={mandatoryFields.student_status=="" && 'selected'}>Please select Student Status</option>
                                                                {studentStatus?.map(status=>(<option key={status} value={status}>{status}</option>))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridState" className="col-12">
                                                            <Form.Label>WhatsApp Number</Form.Label>
                                                            <PhoneInput country={'fr'} value={this.state.mandatoryFields.whatsapp_number} onChange={whatsapp_number => this.setState({mandatoryFields:{...this.state.mandatoryFields, whatsapp_number:whatsapp_number}})} required  inputProps={{name: 'whatsapp_number'}} />
                                                        </Form.Group>                        
                                                    </Form.Row>
                                                    <Button variant="primary" type="submit" onClick={this.submitMandatoryFields}>Submit</Button>
                                                </Form>                    
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
            </React.Fragment>
        )
    }
}
export default Dashboard
