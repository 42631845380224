import React from "react";

// import countup
import CountUp from 'react-countup';

// import common components
import Maper from "../../common/Maper";

// import marker image
import member from '../../../assets/images/markers/member.png';


const Community = ({ items }) => {

    return (
        <React.Fragment>
            {(items.length > 0) ? (
                <div className="community-wrapper">
                    <div className="community-counter">
                        <CountUp
                            className="community-size"
                            duration={2}
                            end={items.length}
                            suffix=" membres"
                        />
                    </div>
                    <div className="community-map">
                        <Maper items={items} icon={member} withPopup={false} size="400" />
                    </div>
                </div>
            ) : (
                <div className="empty-list text-center">
                    <p className="mb-0 p-2 text-white">
                        Un aperçu de notre communauté grandissante bientôt disponible.
                    </p>
                </div>
            )}
        </React.Fragment>
    )

}
export default Community