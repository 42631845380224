import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';

// import share components
import {
    FacebookShareButton, LinkedinShareButton, TwitterShareButton,
    WhatsappShareButton, TelegramShareButton, EmailShareButton,
    EmailIcon, FacebookIcon, LinkedinIcon, TelegramIcon, TwitterIcon, WhatsappIcon
} from 'react-share';


const SocialShare = ({ opened, title, subtitle, shareLink, shareMessage, onClosed }) => {

    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const onCopyClicked = (copyText) => {
        copyTextToClipboard(copyText)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <React.Fragment>
            <Modal
                show={opened} onHide={onClosed} centered backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" as={"h5"}>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(subtitle && (subtitle !== "")) ? (
                        <p className="text-muted m-0 p-2">
                            {subtitle}
                        </p>
                    ) : null}

                    <div className="row g-3 mb-2 align-items-center justify-content-center">
                        <div className="col-lg-4 text-center">
                            <FacebookShareButton
                                url={shareLink}
                                title={shareMessage}
                            >
                                <FacebookIcon size={35} round={true} />
                            </FacebookShareButton>
                        </div>
                        <div className="col-lg-4 text-center">
                            <TwitterShareButton
                                url={shareLink}
                                title={shareMessage}
                            >
                                <TwitterIcon size={35} round={true} />
                            </TwitterShareButton>
                        </div>
                        <div className="col-lg-4 text-center">
                            <LinkedinShareButton
                                url={shareLink}
                                title={shareMessage}
                            >
                                <LinkedinIcon size={35} round={true} />
                            </LinkedinShareButton>
                        </div>
                        <div className="col-lg-4 text-center">
                            <WhatsappShareButton
                                url={shareLink}
                                title={shareMessage}
                            >
                                <WhatsappIcon size={35} round={true} />
                            </WhatsappShareButton>
                        </div>
                        <div className="col-lg-4 text-center">
                            <TelegramShareButton
                                url={shareLink}
                                title={shareMessage}
                            >
                                <TelegramIcon size={35} round={true} />
                            </TelegramShareButton>
                        </div>
                        <div className="col-lg-4 text-center">
                            <EmailShareButton
                                url={shareLink}
                                title={shareMessage}
                            >
                                <EmailIcon size={35} round={true} />
                            </EmailShareButton>
                        </div>
                    </div>

                    {isCopied ? (
                        <div className="mt-3">
                            <small className="p-2 text-green fw-bold">
                                * Copié dans le presse-papier !
                            </small>
                        </div>
                    ) : null}

                    <div className="input-group my-3">
                        <input
                            value={shareLink}
                            readOnly type="text"
                            className="form-control"
                            aria-label="shareLink"
                            aria-describedby="basic-addon1"
                        />
                        <span
                            className="input-group-text cursor-hand"
                            id="basic-addon1"
                            onClick={() => onCopyClicked(shareLink)}
                        >
                            <i className='bx bx-copy-alt'></i>
                        </span>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )

}
SocialShare.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    shareLink: PropTypes.string,
    shareMessage: PropTypes.string,
    opened: PropTypes.bool
}
SocialShare.defaultProps = {
    title: "Partager",
    subtitle: "Partager sur...",
    shareLink: "#",
    shareMessage: "",
    opened: false
}
export default SocialShare
