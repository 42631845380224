import 'moment/locale/fr'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {Button} from 'react-bootstrap';
import { ITEMS_TO_DISPLAY_XS, USER_DATA } from '../../../helpers/constants'
import { calculateCol, slugify } from '../../../helpers/utilities'
import Lister from '../../common/Lister'
import { COUNTRY, SCHOOL } from '../../../helpers/dummies'
import { icDate } from '../../../assets/images'

const AcceptedOffers = ({ offers, itemsPerRow, listerClasses, withDiscover, withPagination}) => {
    {console.log(offers, 'here are')}
    return (
        <React.Fragment>
            <Lister
                listerClasses={`${listerClasses} schools`}
                withDiscover={withDiscover}
                withPagination={withPagination}
                discoverOptions={{ discoverLink: "/etablissements" }}
                >
                {offers && offers.map((offer, index) => {
                    let itemOffer = {
                        ...offer
                    }
                    return (
                        <OfferItem
                            key={index}
                            col={calculateCol(itemsPerRow)}
                            {...itemOffer}
                        />
                    )
                })}
            </Lister>
        </React.Fragment>
    )

}

AcceptedOffers.propTypes = {
    offers: PropTypes.array,
    itemsPerRow: PropTypes.number,
    listerClasses: PropTypes.string,
    withPagination: PropTypes.bool,
}
AcceptedOffers.defaultProps = {
    itemsPerRow: ITEMS_TO_DISPLAY_XS,
    listerClasses: "g-3",
    withDiscover: false,
    withPagination: false,
}
export default AcceptedOffers

const OfferItem = ({ col, offers:{name, beginDate, endDate, advantages, conditions, description, image, publicId, country} }) => (
    <div className={`col-lg-${col} col-md-6 col-sm-12`}>
        <div className="box">
            <div className="box-content mt-3">
                <div className="box-data">
                    <img src={image} className="img-fluid" alt="school logo" width={125} height={125} />

                    <div className="box-name">
                        <h3>Name: {name} <div className='fs-6 fw-bold'>{publicId}</div></h3>
                        <div className="box-country"><span className="me-1">{country?.countryName}</span></div>
                    </div>
                </div>

                <div className="box-meta mt-3">
                     <ul>
                        <li>
                            <span title="Date de la prochaine rentrée">
                                <img src={icDate} width={24} height={24} alt="date icon" /> {moment(beginDate).format('MM-DD-YYYY')}
                            </span>
                        </li>
                        <li>
                            <span title="Date de la prochaine rentrée">
                                <img src={icDate} width={24} height={24} alt="date icon" /> {moment(endDate).format('MM-DD-YYYY')}
                            </span>
                        </li>
                    </ul> 
                </div>
            </div>
            {advantages && <div className='advantages text-left'><ul><li>{advantages}</li></ul></div>}
            {conditions &&  <div className='conditions text-left'><ul><li>{conditions}</li></ul></div>}
            {description && <div className='description text-left'>{description}</div>}
        </div>
    </div>
);