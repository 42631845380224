// OPPORTUNITIES 
export const OPPORTUNITIES_LIST = "OPPORTUNITIES_LIST"
export const OPPORTUNITIES_SHOW = "OPPORTUNITIES_SHOW"

// ARTICLES
export const ARTICLES_LIST = "ARTICLES_LIST"
export const ARTICLES_SHOW = "ARTICLES_SHOW"

// COUNTRIES
export const COUNTRIES_LIST = "COUNTRIES_LIST"
export const COUNTRIES_SHOW = "COUNTRIES_SHOW"

// DOMAINS
export const DOMAINS_LIST = "DOMAINS_LIST"
export const DOMAINS_SHOW = "DOMAINS_SHOW"

// SUBDOMAINS
export const SUB_DOMAINS_LIST = "SUB_DOMAINS_LIST"
export const SUB_DOMAINS_SHOW = "SUB_DOMAINS_SHOW"

// FORMATIONS
export const FORMATIONS_LIST = "FORMATIONS_LIST"
export const FORMATIONS_SHOW = "FORMATIONS_SHOW"

// HOME
export const SLIDERS_LIST = "SLIDERS_LIST"
export const VISIONS_LIST = "VISIONS_LIST"
export const MEMBERS_LIST = "MEMBERS_LIST"
export const SOCIALS_LIST = "SOCIALS_LIST"
export const COMMUNITY_DATA = "COMMUNITY_DATA"

// SCHOOLS
export const SCHOOLS_LIST = "SCHOOLS_LIST"
export const SCHOOLS_SEARCHES = "SCHOOLS_SEARCHES"
export const SCHOOLS_SHOW = "SCHOOLS_SHOW"

//Student Favorite Schools
export const FAVORITE_SCHOOLS_LIST = "FAVORITE_SCHOOLS_LIST"
export const FAVORITE_OPPORTUNITY_LIST = "FAVORITE_OPPORTUNITY_LIST"

// APP TOKEN
export const APP_TOKEN_ASKED = "APP_TOKEN_ASKED"
export const APP_TOKEN_RETRIEVED = "APP_TOKEN_RETRIEVED"

// PRELOADER
export const TOGGLE_PRELOADER = "TOGGLE_PRELOADER"