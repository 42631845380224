import React from "react"
import PropTypes from 'prop-types'

const Loader = ({ color }) => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className={"spinner-grow text-" + color} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}
Loader.propTypes = {
    color: PropTypes.string
}
Loader.defaultProps = {
    color: "primary"
}
export default Loader