import React from 'react'
import PropTypes from 'prop-types'

// import store
import { connect } from 'react-redux'
import { setDomain } from '../../store/actions'

// import deep-equal
import equal from "deep-equal"


class Domains extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { domains } = this.props

        return (
            <React.Fragment>
                <div className='row justify-content-cent'>
                    <div className='col-8'>
                        <p className='p-3  m-0'>Domains</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
Domains.propTypes = {
    domains: PropTypes.object,
    onSetDomains: PropTypes.func,
}
const mapStateToProps = state => ({
    domains: state.domains,
})
const mapDispatchToProps = dispatch => ({
    onSetDomains: data => dispatch(setDomain(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Domains)