import { get } from './baseService';

export function getDomains() {
    return get('domains', true).then((response) => response)
}

export function getDomain(code) {
    return get(`domains/${code}`, true).then((response) => response)
}

export function getDomainSubs(code) {
    return get(`domains/${code}/sub-domains`, true).then((response) => response)
}

