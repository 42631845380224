import React from 'react'
import PropTypes from 'prop-types'

// import router, Link
import { Link, withRouter } from 'react-router-dom'

// import constants
import { SIDEBAR_MENU_ITEMS, SIDEBAR_PARENT_LINKS, USER_PARENT, USER_STUDENT } from '../../helpers/constants'


const Sidebar = ({ user, location }) => {

    return (
        <div id="sidebar" className="sidebar">

            <div className="sidebar-head">
                {/* {(user && user.id) ? (
                    <>
                        {!user.avatar ? (
                            <div className="avatar-xl mx-auto mb-3">
                                <span className="avatar-title rounded-circle sidebar-avatar">
                                    {(user.name.charAt(0).toUpperCase() || "") + (user.name.charAt(1).toUpperCase() || "")}
                                </span>
                            </div>
                        ) : (
                            <div className="mb-3">
                                <img
                                    className="rounded-circle avatar-md"
                                    src={user.avatar}
                                    alt="user avatar"
                                />
                            </div>
                        )}
                    </>
                ) : null}

                <h5 className="text-center">{user.name}</h5> */}

                {/* {(user.profile && (user.profile === USER_STUDENT.key)) ? (
                    <>
                        <div className="text-center">
                            <span className="badge bg-secondary mb-1">0 points</span>
                        </div>
                        <div className="text-center">
                            <Link to="#">
                                Gagner des points !
                            </Link>
                        </div>
                    </>
                ) : null} */}
            </div>

            <div className="sidebar-content">
                <ul>
                        {(user.profile && (user.profile === USER_STUDENT.key)) ? (
                            <>
                            {SIDEBAR_MENU_ITEMS.map((menu, index) => (
                                <li key={`sidebar_menu_item_${index}`}>
                                    <Link to={menu.link} className={`${(location.pathname === menu.link) ? "active" : ""}`}>
                                        <i className={`bx bx-${menu.icon}`} />
                                        <span>{menu.label}</span>
                                        <span>{menu.endIcon}</span>
                                    </Link>
                                </li>
                            ))}
                        </>
                    ) : (user.profile && (user.profile === USER_PARENT.key)) ? (
                        <>
                         {console.log(user.profile, 'user.profile', USER_STUDENT.key)}
                            {(user.students && (user.students.length > 0)) ? (
                                <>
                                    {user.students.map((student, index) => (
                                        <li key={`sidebar_parent_student_item_${index}`}>
                                            <Link to="#" className={``}>
                                                {student.name}
                                            </Link>
                                        </li>
                                    ))}
                                </>
                            ) : (
                                
                                <center>
                                     {console.log(user.profile, 'user.profile')}
                                    <p className="mb-0 p-2 text-muted">
                                        Aucun.e étudiant.e enregistré.e
                                    </p>
                                </center>
                            )}
                            <center>
                                <Link to="#" className="link-student-account mt-2">
                                    + Lier un compte élève/étudiant
                                </Link>
                            </center>
                        </>
                    ) : null}
                </ul>
            </div>

            <div className="sidebar-foot">
                {(user.profile && (user.profile === USER_PARENT.key)) ? (
                    <div className="parent-useful-links">
                        <ul>
                            {SIDEBAR_PARENT_LINKS.map((link, index) => (
                                <li key={`sidebar_parent_link_${index}`}>
                                    <Link to={`${link.link}`}>
                                        {link.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>

        </div>
    )

}
export default withRouter(Sidebar)
