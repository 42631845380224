import React from "react"

// import store
import { connect } from 'react-redux'

// import deep-equal
import equal from "deep-equal"

// import store actions
import { setAppTokenAsked, setAppTokenRetrieved, setCountry, setDomain, setSchool, togglePreloader } from "../../store/actions"

// import constants
import { COUNTRY_AFRICAN, COUNTRY_WORLD, COUNTRY_FOREIGN, ITEMS_TO_DISPLAY_SM, TOKEN_ERROR, APP_TOKEN } from "../../helpers/constants"

// import utilities
import { isUnauthenticatedRequest } from "../../helpers/utilities"

// import services
import { getSchools } from "../../services/schoolService"
import { getDomains } from "../../services/domainService"
import { getCountries } from "../../services/countryService"

// import dummies
import { COUNTRY, DOMAIN, SCHOOL } from "../../helpers/dummies"

// import common custom components
import Carousels from "../../components/common/Carousels"
import Leading from "../../components/common/Leading"
import Section from "../../components/common/Section"
import Cta from "../../components/common/Cta"
import Snack from "../../components/common/Snack"

// import searcher
import Searcher from "../../components/layout/Searcher"

// import custom components
import VisionList from "../../components/custom/home/VisionList"
import DomainList from "../../components/custom/home/DomainList"
import EstablishmentList from "../../components/custom/home/EstablishmentList"
import ListCountry from "../../components/custom/country/ListCountry"
import StudyList from "../../components/custom/home/StudyList"
import Community from "../../components/custom/home/Community"


class Home extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        try {
            //Get props
            let { onPreloaderToggled, onAppTokenAsked } = this.props

            //Start Preload
            onPreloaderToggled(true, "Chargement")

            //Load app token or Load schools
            if (!localStorage.getItem(APP_TOKEN)) {
                onAppTokenAsked(true)
            } else {
                this.onGetSchools()
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                //Get props
                let { tokenRetrieved, tokenFailed, onAppTokenRetrieved, onPreloaderToggled } = this.props

                //Handle props values
                if (tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    onAppTokenRetrieved(false)
                    this.onGetSchools()
                } else if (tokenFailed) {
                    onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Get Schools.
     * 
     */
    onGetSchools = () => {
        getSchools().then((response) => {
            if (response && response.data) {
                this.props.onSetSchools(response.data)
                this.onGetDomains()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET SCHOOLS ERROR")
                this.onGetDomains()
            }
        }).catch((error) => {
            console.error(error, "GET SCHOOLS ERROR")
            this.onGetDomains()
        });
    }

    /**
     * On Get Domains.
     * 
     */
    onGetDomains = () => {
        getDomains().then((response) => {
            if (response && response.data) {
                this.props.onSetDomains(response.data)
                this.onGetCountries()
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                console.error(response, "GET DOMAINS ERROR")
                this.onGetCountries()
            }
        }).catch((error) => {
            console.error(error, "GET DOMAINS ERROR")
            this.onGetCountries()
        });
    }

    /**
     * On Get Countries.
     * 
     */
    onGetCountries = () => {
        getCountries().then((response) => {
            if (response && response.data) {
                this.props.onSetCountries(response.data)
                this.props.onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                this.props.onAppTokenAsked(true)
            } else {
                this.props.onPreloaderToggled(false, "")
                console.error(response, "GET COUNTRIES ERROR")
            }
        }).catch((error) => {
            this.props.onPreloaderToggled(false, "")
            console.error(error, "GET COUNTRIES ERROR")
        });
    }

    render() {
        const { sliders, visions, schools, countries, domains, members, isPreloader } = this.props

        return (
            <React.Fragment>
                <div id="home-page" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {/* Banner Section: Carousels, Leading, Searcher */}
                    <Carousels
                        items={sliders} infiniteLooped
                        carouselsClasses="home-carousel"
                        itemKey="home_carousel_item"
                        withArrows={false}
                    />
                    <Leading
                        title="Comment allez-vous changer le Monde ?"
                        subtitle="Bien s'orienter et réussir : Ressources, conseils et opportunités"
                    >
                        <Searcher />
                    </Leading>

                    {/* Visions Section */}
                    <Section
                        subtitle="ACE Orientation vous accompagne dans vos projets d'études"
                        sectionId="visions"
                        sectionClasses="visions bg-blue py-5"
                        textColor="white"
                    >
                        <VisionList items={visions} />
                    </Section>

                    {/* Establishments Section */}
                    <Section
                        subtitle="Découvrez les profils des derniers établissements référencés"
                        sectionId="establishments"
                        sectionClasses="establishments bg-white py-5"
                        textColor="blue"
                    >
                        <EstablishmentList items={schools} />
                    </Section>

                    {/* Domains Section */}
                    <Section
                        title="Domaines d’études"
                        subtitle="Explorez les filières et les formations dans votre domaine d’études"
                        sectionId="domains"
                        sectionClasses="domains bg-blue py-5"
                        textColor="green"
                    >
                        <DomainList items={domains} />
                    </Section>

                    {/* CTA Section */}
                    <Cta />

                    {/* Studies In Africa Section */}
                    <Section
                        subtitle={COUNTRY_FOREIGN.label}
                        sectionId="studies"
                        sectionClasses="studies bg-white py-5"
                    >
                        <StudyList
                            option={COUNTRY_FOREIGN}
                            items={countries.filter((c) => (c.continent === COUNTRY_AFRICAN.key))}
                        />
                    </Section>

                    {/* Studies Out Africa Section */}
                    <Section
                        subtitle={COUNTRY_FOREIGN.subtitle}
                        sectionId="studies"
                        sectionClasses="studies bg-white py-5"
                    >
                        <EstablishmentList items={schools} emptyMessage="Liste des entreprises qui recrutent bientôt disponible." />
                    </Section>

                    {/* Community Section */}
                    <Section
                        subtitle="Rejoignez gratuitement la Communauté ACE Orientation et accédez à toutes les ressources de la plate-forme !"
                        sectionId="community"
                        sectionClasses="community bg-blue py-5"
                        textColor="white"
                    >
                        <Community items={members} />
                    </Section>
                </div>
            </React.Fragment>
        )
    }

}
const mapStateToProps = state => ({
    sliders: state.sliders,
    visions: state.visions,
    members: state.members,
    countries: state.countries,
    schools: state.schools,
    domains: state.domains,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetSchools: (data) => dispatch(setSchool(data)),
    onSetDomains: (data) => dispatch(setDomain(data)),
    onSetCountries: (data) => dispatch(setCountry(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Home)