import React from 'react'
import PropTypes from 'prop-types'

const Snack = ({ message, onClicked, snackClasses }) => {
    return (
        <div className={`snack ${snackClasses}`} onClick={() => { if (onClicked) onClicked() }}>
            <p className="mb-0 p-2">
                {message}
            </p>
        </div>
    )
}

Snack.propTypes = {
    message: PropTypes.string,
    snackClasses: PropTypes.string,
    onClicked: PropTypes.func
}
Snack.defaultProps = {
    message: "",
    snackClasses: ""
}
export default Snack