import React from 'react'

import Carousel from "react-multi-carousel";

import moment from 'moment';
import 'moment/locale/fr';

// import dummies
import { OPPORTUNITY } from '../../../helpers/dummies';

const RESPONSIVES = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ListArticle = ({ items }) => {

    const CustomButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <div className="articles-arrows">
                <span className={`control-arrow arrow-prev`} onClick={() => previous()}>
                    <i className='bx bx-chevron-left' />
                </span>
                <span className={`control-arrow arrow-next`} onClick={() => next()}>
                    <i className='bx bx-chevron-right' />
                </span>
            </div>
        );
    };

    return (
        <Carousel
            responsive={RESPONSIVES}
            containerClass="flex-column-reverse"
            sliderClass="articles"
            arrows={false}
            customButtonGroup={<CustomButtonGroup />}
            removeArrowOnDeviceType={["tablet", "mobile"]}
        >
            {items.map((item, index) => {
                let itemArticle = {
                    ...item,
                    image: item.image ? item.image : OPPORTUNITY.image
                }
                return (
                    <ArticleItem
                        key={`article_item_${index}`}
                        {...itemArticle}
                    />
                )
            })}
        </Carousel>
    )

}
export default ListArticle


const ArticleItem = ({ image, publicId, createdAt, description }) => (
    <div className={`mx-1 post`}>
        <div
            className="post-background"
            style={{ backgroundImage: `url(${image})` }}
        />

        <div className="post-content">
            <span className="post-date">
                {moment(createdAt, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY')}
            </span>

            <h3 className="post-title">
                {(description.length > 100) ? (description.substr(0, 99) + "...") : description}
            </h3>

            <a href={`/opportunites/articles/${publicId}`} className="readmore stretched-link mt-auto">
                <span>Lire plus</span>
                <i className='bx bx-right-arrow-alt'></i>
            </a>
        </div>
    </div>
)