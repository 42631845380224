import { post } from './baseService';

export function getAppToken() {
    let credentials = { email: "john@example.com", password: "John@123!" }
    return post('login', credentials, false).then((response) => response)
}

export function login(data) {
    return post('login', data, false).then((response) => response)
}
export function forgotPass(data) {
    return post('forget-password', data, false).then((response) => response)
}

export function register(data) {
    return post('users', data, true).then((response) => response)
}

export function updatePasswordUsingToken(data) {
    return post('update-password-using-token', data, true).then((response) => response)
}

export function loginWithGoogleAPI(data) {
    return post('google-login', data, false).then((response) => response)
}
export function loginWithFacebookAPI(data) {
    return post('facebook-login', data, false).then((response) => response)
}
export function sendCodeToUserToVerifyEmail(publicId) {
    return post('users/emailVerificationRequest', {publicId}, true).then((response) => response)
}
export function submitCodeToVerifyEmail(data) {
    return post('users/email-verification-code-process', data, true).then((response) => response)
}
export function getCurrentUser(publicId) {
    return post('users/get-current-user', {publicId}, true).then((response) => response)
}

export function getCurrentUserFavoriteUniversities(data) {
    return post('users/get-favorite-complete-details', data, true).then((response) => response)
}

export function getCurrentUserFavoriteOpportunities(data) {
    return post('users/get-favorite-complete-details', data, true).then((response) => response)
}
export function getCurrentUserFavoriteUniverisityOpportunitiesCount(data) {
    return post('users/count-favorite', data, true).then((response) => response)
}

export function getStudentOffers(data) {
    return post('users/student-redeem-offers', data, true).then(resp=>resp)
}