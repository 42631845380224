import React from "react"
import PropTypes from 'prop-types'

const Section = ({ title, subtitle, description, textColor, sectionId, sectionClasses, sectionInnerClasses, children }) => {
    return (
        <section id={sectionId} className={sectionClasses}>
            <div className={sectionInnerClasses}>
                <header className="section-header">
                    {(title && title !== "") ? (
                        <h3 className={`text-center text-${textColor}`}> {title} </h3>
                    ) : null}
                    {(subtitle && subtitle !== "") ? (
                        <h4 className={`fw-bold text-center text-${textColor}`}> {subtitle} </h4>
                    ) : null}
                    {(description && description !== "") ? (
                        <p className={`text-center text-${textColor}`}> {description} </p>
                    ) : null}
                </header>
                {children}
            </div>
        </section>
    )
}
Section.propTypes = {
    sectionClasses: PropTypes.string,
    sectionInnerClasses: PropTypes.string,
    sectionId: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    textColor: PropTypes.string,
    children: PropTypes.node
}
Section.defaultProps = {
    title: "",
    subtitle: "",
    description: "",
    sectionInnerClasses: "container",
    sectionClasses: "",
    sectionId:"",
    textColor: "dark"
}
export default Section