import React from 'react'
import PropTypes from 'prop-types'

import { Breadcrumb } from 'react-bootstrap';

const Breadcrumbs = ({ title, titleLink, subtitle, subtitleLink, extratitle, extratitleLink, currentTile }) => {
    return (
        <Breadcrumb>
            {(title && title !== "") ? (
                <Breadcrumb.Item href={titleLink}>
                    {title}
                </Breadcrumb.Item>
            ) : null}
            {(extratitle && extratitle !== "") ? (
                <Breadcrumb.Item href={extratitleLink}>
                    {extratitle}
                </Breadcrumb.Item>
            ) : null}
            {(subtitle && subtitle !== "") ? (
                <Breadcrumb.Item href={subtitleLink}>
                    {subtitle}
                </Breadcrumb.Item>
            ) : null}
            <Breadcrumb.Item active>
                {currentTile}
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}
Breadcrumbs.propTypes = {
    title: PropTypes.string,
    titleLink: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleLink: PropTypes.string,
    extratitle: PropTypes.string,
    extratitleLink: PropTypes.string,
}
Breadcrumbs.defaultProps = {
    title: "Accueil",
    titleLink: "/",
    subtitle: "",
    subtitleLink: "",
    extratitle: "",
    extratitleLink: ""
}
export default Breadcrumbs
