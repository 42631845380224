import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';

import { FORMATION_COST_DISCLAIMER } from '../../helpers/constants';

const ConvertCost = ({ cost, onClosed, opened, title, subtitle }) => {

    return (
        <React.Fragment>
            <Modal
                show={opened} onHide={onClosed} centered backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" as={"h5"}>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>{`XOF ${cost}`}</strong> {" "} {`approximativement converti`}

                    <div className="row g-3 my-3 align-items-center justify-content-center">
                        <div className="col-lg-3 text-center">
                            {`${(cost * 652.67)} EUR`}
                        </div>
                        <div className="col-lg-3 text-center">
                            {`${(cost * 649.78)} USD`}
                        </div>
                        <div className="col-lg-3 text-center">
                            {`${(cost * 499.86)} CAD`}
                        </div>
                        <div className="col-lg-3 text-center">
                            {`${(cost * 773.32)} GBP`}
                        </div>
                        <div className="col-lg-3 text-center">
                            {`${(cost * 1.56)} NGN`}
                        </div>
                        <div className="col-lg-3 text-center">
                            {`${(cost * 80.17)} GHS`}
                        </div>
                    </div>

                    {(subtitle && (subtitle !== "")) ? (
                        <small className="d-block text-danger text-center fs-11 m-0 p-2">
                            *{subtitle}
                        </small>
                    ) : null}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )

}

ConvertCost.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    opened: PropTypes.bool
}
ConvertCost.defaultProps = {
    title: "Conversion",
    subtitle: FORMATION_COST_DISCLAIMER,
    opened: false
}
export default ConvertCost