import React, { useState } from "react";
import PropTypes from 'prop-types';

// import phone input
import PhoneInput from 'react-phone-input-2';

// import components
import { Form, Col, Row, Button } from 'react-bootstrap';

// import custom components.
import Selecter from "../common/Selecter";
import Loader from "./Loader";

// import constants
import { CONTACT_GCU_CONDITIONS, CONTACT_OBJECTS } from "../../helpers/constants";


const Mailer = ({ loading, failure, success, onMailed }) => {

    const [data, setData] = useState({});

    const onSelected = (selected, option) => {
        setData({ ...data, [option]: [selected] })
    }

    const onChanged = (e) => {
        if (e.target) {
            let { name, value } = e.target
            setData({ ...data, [name]: value })
        }
    }

    const onCleared = () => {
        console.log("ON CLEARED")
        setData({})
        document.getElementById("username").value = ""
        document.getElementById("useremail").value = ""
        document.getElementById("phone").value = ""
        document.getElementById("object").value = ""
        document.getElementById("content").value = ""
        document.getElementById("aggreedwithConditions").checked = false
    }


    const onSubmited = (e) => {
        e.preventDefault();
        console.log(data, "ON SUBMIT DATA")
        onMailed(data, onCleared)
    }


    return (
        <React.Fragment>
            <Form onSubmit={onSubmited}>
                <Row>
                    <Col sm={12}>
                        <Form.Group className="">
                            <Form.Label>
                                Votre nom
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                id="username"
                                name="username"
                                className="form-control"
                                placeholder="John Doe"
                                onChange={onChanged}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="">
                            <Form.Label>
                                Votre email
                            </Form.Label>
                            <Form.Control
                                required
                                type="email"
                                id="useremail"
                                name="useremail"
                                className="form-control"
                                placeholder="johndoe@gmail.com"
                                onChange={onChanged}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="">
                            <Form.Label>
                                Votre contact téléphonique
                            </Form.Label>

                            <PhoneInput
                                country={'us'}
                                value={data.phone || ""}
                                onChange={onChanged}
                                placeholder="Entrez le numéro de téléphone"
                                inputProps={{
                                    id: 'phone',
                                    name: 'phone',
                                    required: true,
                                    className: "form-control"
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Objet</Form.Label>

                            <Form.Control
                                as="select" custom required
                                id="object" name="object"
                                onChange={onChanged}
                            >
                                <option>---</option>
                                {CONTACT_OBJECTS.map((object, index) => (
                                    <option key={`contact_object_item_${index}`} value={object.key}>
                                        {object.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Contenu</Form.Label>
                            <Form.Control
                                as="textarea" required rows={10}
                                onChange={onChanged}
                                id="content" name="content"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group controlId="aggreedwithConditions">
                            <Form.Check type="checkbox" label={CONTACT_GCU_CONDITIONS} required />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        {(failure && failure !== "") ? (
                            <small className="text-danger">{failure}</small>
                        ) : null}
                    </Col>

                    {(success) ? (
                        <Col sm={12}>
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                Votre message a bien été envoyé.
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                            </div>
                        </Col>
                    ) : null}

                    <Col sm={12}>
                        {(loading) ? (
                            <Loader color="blue" />
                        ) : (
                            <button type="submit" className="btn-submit btn-mailer">
                                Envoyer
                            </button>
                        )}
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )

}

export default Mailer