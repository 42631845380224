import React from "react"

// import dummies
import { DOMAIN } from "../../../helpers/dummies";


const DomainList = ({ items }) => {
    return (
        <React.Fragment>
            {(items.length > 0) ? (
                <ul className="mt-5">
                    {items.map((item, index) => {
                        let itemDomain = {
                            ...item,
                            name: item.name ? item.name : DOMAIN.name,
                            icon: item.icon ? item.icon : DOMAIN.icon,
                            tag: item.tag ? item.tag : DOMAIN.tag
                        }
                        return (
                            <DomainItem
                                key={`domain_item_${index}`}
                                index={index}
                                {...itemDomain}
                            />
                        )
                    })}
                </ul>
            ) : (
                <div className="empty-list text-center">
                    <p className="mb-0 p-2">
                        La liste de nos domaines d’études bientôt disponible.
                    </p>
                </div>
            )}
        </React.Fragment>
    )
}
export default DomainList

/**
 * Domain item.
 */
const DomainItem = ({ index, icon, tag, publicId, name }) => (
    <li className={`${((index + 1) % 5 === 0) ? "no-animate" : ""}`}>
        <a href={`/domaines/${publicId}`}>
            <img src={icon} alt={name.toLowerCase()} />
            <h4> {name} </h4>
            <p> {tag} </p>
        </a>
    </li>
);