import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom"
import { Form, Col, Button } from 'react-bootstrap';
import { login, loginWithGoogleAPI, loginWithFacebookAPI } from '../../services/authService';
import { CREDENTIALS_ERROR, LOGIN_ERROR, UNKNOWN_ERROR, USER_DATA } from '../../helpers/constants';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import { auth, provider, providerFace } from '../../firebase.config';
import {signInWithPopup} from 'firebase/auth'


class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            failure: "",
            googleUserData:{
                name:'',
                email:'',
                profile_photo_url:'',
                login_type:'google',                
                google_id:'',
                phone: undefined,
            },
            facebookUserData:{
                name:'',
                email:'',
                profile_photo_url:'',
                login_type:'facebook',                
                facebook_id:'',
                phone: undefined,
            },
            inputs: { email: "", password: "" },
        }

        this.loginWithGoogle = this.loginWithGoogle.bind(this);
        this.loginWithFacebook = this.loginWithFacebook.bind(this);
    }

    onChanged = (e) => {
        if (e.target) {
            let { name, value } = e.target
            this.setState({
                inputs: { ...this.state.inputs, [name]: value }
            })
        }
    }

    onSubmited = (e) => {
        e.preventDefault();

        // Update state
        this.setState({ loading: true, failure: "" })

        // Launch login
        this.onLogin(this.state.inputs)
    }

    onLogin(data) {
        login(data).then((response) => {
            console.log(response, "LOGIN RESPONSE")
            if (response && response.api_token && response.user) {
                let user = { ...response.user, token: response.api_token, email_verified_at:response.email_verified_at }
                localStorage.setItem(USER_DATA, JSON.stringify(user))
                this.setState({ loading: false, failure: "" })
                
                let { location, history } = this.props
                if (location.state && location.state.from) {
                    history.push({
                        pathname: location.state.from.pathname,
                        state: location.state.from.state
                    })
                } else {
                    toast.success("Vous êtes connecté ! Vous pouvez accéder à votre espace membre.")
                    history.push("/account")
                }
            } else if (response.errors && response.errors[0] && (response.errors[0] === LOGIN_ERROR)) {
                this.setState({ loading: false, failure: CREDENTIALS_ERROR })
            } else {
                this.setState({ loading: false, failure: UNKNOWN_ERROR })
            }
        }).catch((error) => {
            console.error(error, "LOGIN ERROR")
            this.setState({ loading: false, failure: UNKNOWN_ERROR })
        });
    }

    loginWithGoogle(){
        signInWithPopup(auth, provider).then(data => {
            this.setState({ googleUserData: {name:data.user.displayName, email:data.user.email, profile_photo_url:data.user.photoURL, login_type:'google', google_id:data.user.uid  } })
            loginWithGoogleAPI(this.state.googleUserData).then(response=>{
                console.log(response, 'data is')
                if (response && response.api_token && response.user) {
                    let user = { ...response.user, token: response.api_token }
                    localStorage.setItem(USER_DATA, JSON.stringify(user))
                    this.setState({ loading: false, failure: "" })
                
                    let { location, history } = this.props
                    if (location.state && location.state.from) {
                        history.push({
                            pathname: location.state.from.pathname,
                            state: location.state.from.state
                        })
                    } else {
                        toast.success("Vous êtes connecté ! Vous pouvez accéder à votre espace membre.")
                        history.push("/account")
                    }
                } else if (response.errors && response.errors[0] && (response.errors[0] === LOGIN_ERROR)) {
                    this.setState({ loading: false, failure: CREDENTIALS_ERROR })
                } else {
                    this.setState({ loading: false, failure: UNKNOWN_ERROR })
                }
            })
        })
    }

    loginWithFacebook(){
        signInWithPopup(auth, providerFace).then(data => {
            this.setState({ facebookUserData: {name:data.user.displayName, email:data.user.email, profile_photo_url:data.user.photoURL, login_type:'facebook', facebook_id:data.user.uid}})
            loginWithFacebookAPI(this.state.facebookUserData).then(response=>{
                if (response && response.api_token && response.user) {
                    let user = { ...response.user, token: response.api_token }
                    localStorage.setItem(USER_DATA, JSON.stringify(user))
                    this.setState({ loading: false, failure: "" })
                
                    let { location, history } = this.props
                    if (location.state && location.state.from) {
                        history.push({
                            pathname: location.state.from.pathname,
                            state: location.state.from.state
                        })
                    } else {
                        toast.success("Vous êtes connecté ! Vous pouvez accéder à votre espace membre.")
                        history.push("/account")
                    }
                } else if (response.errors && response.errors[0] && (response.errors[0] === LOGIN_ERROR)) {
                    this.setState({ loading: false, failure: CREDENTIALS_ERROR })
                } else {
                    this.setState({ loading: false, failure: UNKNOWN_ERROR })
                }
            }).catch(error=>{
                console.log(error.message);
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // const email = error.customData.email;
                // const credential = FacebookAuthProvider.credentialFromError(error);
            })            
            
        })
    }

    logout() {
        localStorage.clear();
        // window.location.reload();
    }

    componentDidMount() {
        // localStorage.setItem('google-user', this.state.googleUserData);
    }

    render() {

        const { loading, failure } = this.state;

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 pt-5">
                            <h3 className="text-center mb-1">Se connecter sur ACE Orientation</h3>
                            <center>
                                <small className="text-muted text-center">
                                    Bénéficiez des services ACE pour construire un projet
                                    académique gagnant en Afrique et dans le monde
                                </small>
                            </center>
                        </div>
                        <div className="col-lg-6 offset-lg-3">
                            <Form onSubmit={this.onSubmited} className="auth-form">
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" id="email" name="email" placeholder="Entrez votre email" required onChange={this.onChanged} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Mot de passe</Form.Label>
                                    <Form.Control type="password" id="password" name="password" placeholder="Entrez votre mot de passe" required onChange={this.onChanged} />
                                </Form.Group>

                                <Form.Group className="mb-3"><Link to="/forgot">Mot de passe oublié?</Link></Form.Group>

                                {(failure !== "") ? (<div className="alert alert-danger" role="alert">{failure}</div>) : null}
                                {loading ? (<Loader color="blue" />) : (<button className="btn-discover" type="submit">Connexion</button>)}

                                <div style={{marginTop: '16px', display:'flex', flexWrap:'wrap', alignItems:'center', justifyContent:'space-between'}}>
                                {
                                    // this.state.googleUserData.length > 0 ? <button onClick={this.logout}>Logout</button> : <Button className="btn-discover" onClick={this.loginWithGoogle}>Login with Google</Button>
                                }
                                <Button className="btn-discover" onClick={this.loginWithGoogle}>Login with Google</Button>
                                    <Button className="btn-discover" onClick={this.loginWithFacebook}>Login with Facebook</Button>
                                </div>
                            </Form>
                        </div>
                        <div className="col-lg-6 offset-lg-3 pt-0 pb-5">
                            <center>
                                Pas encore de compte ? <Link to="/register">Devenir membre.</Link>
                            </center>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default withRouter(Login)
