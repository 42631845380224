import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"

// import store, actions
import { connect } from 'react-redux'
import { setAppTokenAsked, setAppTokenRetrieved, setFormation, togglePreloader } from '../../store/actions'

// import deep-equal
import equal from "deep-equal"

// import Flag
import Flag from 'react-flagkit';

// import moment
import moment from 'moment';
import 'moment/locale/fr';

// import services
import { getFormation } from '../../services/formationService'

// import utilities
import { isUnauthenticatedRequest, slugify } from '../../helpers/utilities'

// import dummies
import { COUNTRY, OPPORTUNITY, SCHOOL, TESTIMONY } from '../../helpers/dummies'

// import constants
import { APP_TOKEN, FORMATION_COST_DISCLAIMER, SCHOOL_ACCORDIONS, UNKNOWN_ERROR, USER_DATA } from '../../helpers/constants'

// import custom common components
import Banner from '../../components/common/Banner'
import Square from '../../components/common/Square'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import { icClassesRhythm, icDate, icDeadline, icDeliveryMode, icDuration, icLanguage, icMoney, mkSchool } from '../../assets/images'
import Maper from '../../components/common/Maper'
import ListFormation from '../../components/custom/schools/ListFormation'
import Commenter from '../../components/common/Commenter'
import { addComment } from '../../services/commentService'
import SocialShare from '../../components/common/SocialShare'
import ConvertCost from '../../components/common/ConvertCost'


class Formation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            commenter: { loading: false, failure: "", success: false },
            showLoginLink: false,
            showSubsriptionLink: false,
            showCommentSection: false,
            showSocialShare: false,
            showCostConvert: false
        }
    }

    componentDidMount() {
        try {
            //Get props
            let { onPreloaderToggled, location } = this.props

            //Start preloader
            onPreloaderToggled(true, "Chargement de la formation")

            // Load formation
            this.onGetFormation()

            // if ((!location.state.isSchoolPublic) && (!localStorage.getItem(USER_DATA))) {
            //     this.setState({ showLoginLink: true })
            // } else {
            //     //Start preloader
            //     onPreloaderToggled(true, "Chargement de la formation")

            //     // Load school
            //     this.onGetFormation()
            // }
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check props
            if (!equal(prevProps, this.props)) {
                //Get props
                let { tokenRetrieved, tokenFailed, onAppTokenRetrieved, onPreloaderToggled } = this.props

                //Handle props values
                if (tokenRetrieved && localStorage.getItem(APP_TOKEN)) {
                    onAppTokenRetrieved(false)
                    this.onGetFormation()
                } else if (tokenFailed) {
                    onPreloaderToggled(false, "")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        this.props.onSetFormation({})
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onModalToggled = (option = "showSocialShare", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onConvertToggled = (option = "showCostConvert", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On comment section showed.
     * 
     */
    onCommentSectionShowed = () => {
        this.setState(prevState => ({
            showCommentSection: !prevState.showCommentSection
        }))
    }

    /**
     * Get domain.
     * 
     */
    onGetFormation() {
        let { formationCode } = this.props.location.state
        let { onSetFormation, onPreloaderToggled, onAppTokenAsked } = this.props
        getFormation(formationCode).then((response) => {
            if (response && response.publicId && (response.publicId === formationCode)) {
                onSetFormation({
                    ...response,
                    school: {
                        ...response.school,
                        schoolLogo: response.school.schoolLogo ? response.school.schoolLogo : SCHOOL.image,
                        city: (response.school.city ? response.school.city : COUNTRY.city),
                        address: (response.school.address ? response.school.address : COUNTRY.address),
                        country: response.school.country ? {
                            ...response.school.country,
                            countryCode: response.school.country.countryCode ? response.school.country.countryCode : COUNTRY.code
                        } : COUNTRY,
                        coordinates: {
                            lat: (response.school.latitude != null) ? response.school.latitude : OPPORTUNITY.coordinates.lat,
                            lng: (response.school.longitude != null) ? response.school.longitude : OPPORTUNITY.coordinates.lng
                        }
                    },
                    testimony: response.testimony ? response.testimony : TESTIMONY.words
                })
                onPreloaderToggled(false, "")
            } else if (isUnauthenticatedRequest(response)) {
                onAppTokenAsked(true)
            } else {
                onPreloaderToggled(false, "")
                console.error(response, "GET FORMATION ERROR")
            }
        }).catch((error) => {
            onPreloaderToggled(false, "")
            console.error(error, "GET FORMATION ERROR")
        });
    }

    /**
     * On Commented.
     * 
     * @param {*} data 
     */
    onCommented = (data) => {
        try {
            console.log({ data }, "ON COMMENTED")

            //Get props
            let { school } = this.props

            //Start loader
            this.setState({ commenter: { loading: true, failure: "", success: false } })

            //Launch save comment's request
            let comment_data = {
                content: data.comments,
                internationalOpening: data.internationalOpening,
                professionalPreparation: data.professionalPreparation,
                teachingQuality: data.teachingQuality,
                recommendSchool: data.recommendSchool,
                title: data.title,
                schoolId: school.id
            }

            console.log({ comment_data }, "POST COMMENT DATA")

            addComment(comment_data).then((response) => {
                console.log({ response }, "POST COMMENT RESPONSE")
                if (response.id && response.school && response.school.id && (response.school.id === school.id)) {
                    this.setState({ commenter: { loading: false, failure: "", success: true } })
                } else {
                    console.error(response, "POST COMMENT ERROR")
                    this.setState({ commenter: { loading: false, failure: UNKNOWN_ERROR, success: false } })
                }
            }).catch((error) => {
                console.error(error, "POST COMMENT ERROR")
                this.setState({ commenter: { loading: false, failure: UNKNOWN_ERROR, success: false } })
            });
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        const { isPreloader, location } = this.props
        const { id, publicId, name, formationRythme, formationCost, inscriptionCost, createdAt, school, testimony, domain, subDomain } = this.props.formation

        const { commenter, showLoginLink, showSubsriptionLink, showCommentSection, showSocialShare, showCostConvert } = this.state

        // console.log({ school })

        return (
            <React.Fragment>
                <div id="formation" className={`${isPreloader ? "d-none" : "d-block"}`}>
                    {(id && publicId) ? (
                        <div id="formation-content">
                            {/* Banner */}
                            <Banner bannerId="domain-banner" withBackground />

                            {/* Breadcrumbs */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Breadcrumbs
                                            extratitle={school.country.name}
                                            extratitleLink={`/pays/${school.country.publicId}`}
                                            subtitle={school.name}
                                            subtitleLink="#"
                                            currentTile={`${name}`}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="box-single p-0 mb-0">
                                {/* Top Section */}
                                <div className="top-section shadow-sm py-5">
                                    <div className="container">
                                        <div className="d-flex justify-content-between">
                                            <div className="first-column">
                                                <small className="d-block text-muted">
                                                    {school.name}
                                                </small>
                                                <h3 className="mb-2">
                                                    {name}
                                                </h3>
                                                <div className="single-meta p-0">
                                                    <ul>
                                                        {(school.recognizedByState) ? (
                                                            <li>
                                                                <span className="d-flex align-items-center">
                                                                    <i className='bx bxs-check-circle text-green me-1'></i>
                                                                    Reconnu par l'État
                                                                </span>
                                                            </li>
                                                        ) : null}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="p-0 single-meta second-column">
                                                <ul>
                                                    <li>
                                                        <span title="Date de la prochaine rentrée">
                                                            <img src={icDate} width={24} height={24} alt="date icon" />{" "}
                                                            {(school.annualBackToSchool !== null) ? `${moment(new Date(school.annualBackToSchool)).format('DD MMM YYYY')}` : "Non renseigné"}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span title="Durée de la formation">
                                                            <img src={icDuration} width={24} height={24} alt="duration icon" />{" "}
                                                            {(inscriptionCost !== null) ? `${inscriptionCost} mois` : "Non renseigné"}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span title="Format d’apprentissage">
                                                            <img src={icDeliveryMode} width={24} height={24} alt="formats icon" />{" "}
                                                            {(school.teachingMode !== null) ? `${school.teachingMode}` : "Non renseigné"}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span title="Langue d’enseignement">
                                                            <img src={icLanguage} width={24} height={24} alt="langues icon" />{" "}
                                                            {(school.language !== null) ? `${school.language}` : "Non renseigné"}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="p-0 single-meta third-column">
                                                <ul>
                                                    <li>
                                                        <span title="Rythme de formation">
                                                            <img src={icClassesRhythm} width={24} height={24} alt="rythmes icon" />{" "}
                                                            {(formationRythme !== null) ? `${(formationRythme.length > 8) ? formationRythme.substr(0, 7) : formationRythme}` : "Non renseigné"}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span title="Date limite de candidature étudiants nationaux">
                                                            <img src={icDeadline} width={24} height={24} alt="date icon" />{" "}
                                                            {(createdAt !== null) ? `${moment(createdAt, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY')}` : "Non renseigné"}
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span title="Date limite de candidature étudiants internationaux">
                                                            <img src={icDeadline} width={24} height={24} alt="date icon" />{" "}
                                                            {(createdAt !== null) ? `${moment(createdAt, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY')}` : "Non renseigné"}
                                                        </span>
                                                    </li>

                                                    <li className="cursor-hand" onClick={() => this.onConvertToggled("showCostConvert", true)}>
                                                        <span title={`Estimation du coût(${FORMATION_COST_DISCLAIMER})`}>
                                                            <img src={icMoney} width={24} height={24} alt="cost icon" />{" "}
                                                            {(formationCost !== null) ? `XOF ${formationCost}` : "Non renseigné"}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="school-reviews fourth-column">
                                                <div className="reviews">
                                                    <div className="reviews-stars">
                                                        <i className='bx bxs-star text-muted'></i>
                                                        <i className='bx bxs-star text-muted'></i>
                                                        <i className='bx bxs-star text-muted'></i>
                                                        <i className='bx bxs-star text-muted'></i>
                                                        <i className='bx bxs-star text-muted'></i>
                                                        <span className="cursor-hand ms-1 text-muted">
                                                            {`(${school.note ? school.note : "0"})`}
                                                        </span>
                                                    </div>
                                                    <div className="reviews-actions justify-content-end mt-2">
                                                        <span className="px-2 py-1 d-flex align-items-center justify-content-center me-1">
                                                            <i className='bx bx-like me-1' /> J’aime
                                                        </span>
                                                        <span
                                                            className="px-2 py-1 d-flex align-items-center justify-content-center ms-1"
                                                            onClick={() => this.onModalToggled("showSocialShare", true)}
                                                        >
                                                            <i className='bx bx-share-alt me-1' /> Partager
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-end mt-3">
                                                    {((school.facebookLink !== null) && (school.facebookLink !== "")) ? (
                                                        <Link
                                                            id="btn-candidate" to={school.admissionLink} className="btn-apply"
                                                            target="_blank" rel="noopener noreferrer"
                                                        >
                                                            Je candidate
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            id="btn-candidate" className="btn-apply"
                                                            to={{
                                                                pathname: `/etablissements/${slugify(school.name)}/apply`,
                                                                state: { schoolCode: school.publicId, isSchoolPublic: school.isPublic, schoolName: school.name }
                                                            }}
                                                        >
                                                            Je candidate
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Content Section */}
                                <div className="content-section py-4 mb-5">
                                    <div className="container">
                                        <div className="row g-0 gx-4">
                                            <div className="col-lg-3">
                                                <Square squareClasses="mb-3">
                                                    <div className="square-item square-recents mb-0">
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={school.schoolLogo}
                                                                className="img-fluid me-1"
                                                                width={115} height={115}
                                                                alt={school.name.toLowerCase()}
                                                            />
                                                            <div className="d-flex flex-column">
                                                                <h5 className="mb-0">
                                                                    {school.name}
                                                                </h5>

                                                                <div className="d-flex align-items-center mt-2">
                                                                    {school.country.name} {" "}
                                                                    <Flag
                                                                        country={school.country.countryCode.toUpperCase()}
                                                                        className="ms-2 border border-dark"
                                                                        height={16} width={22}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex align-items-center justify-content-center mt-3">
                                                            <Link
                                                                id="btn-school-profile"
                                                                to={{
                                                                    pathname: `/etablissements/${slugify(school.name)}`,
                                                                    state: { schoolCode: school.publicId, isSchoolPublic: school.isPublic, schoolName: school.name }
                                                                }}
                                                                className="btn-apply text-center"
                                                            >
                                                                Profil de l'établissement
                                                            </Link>
                                                        </div>

                                                        <div className="mt-2 text-center">
                                                            <Link
                                                                to={{
                                                                    pathname: `/etablissements/${slugify(school.name)}`,
                                                                    state: { schoolCode: school.publicId, isSchoolPublic: school.isPublic, schoolName: school.name }
                                                                }}
                                                                className="fs-14 text-muted text-center"
                                                            >
                                                                Des questions ?
                                                                <br />
                                                                Trouvez des réponses ici
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Square>

                                                <Square squareClasses="mb-3">
                                                    <h3 className="square-title">
                                                        {`Adresse de ${school.name}`}
                                                    </h3>
                                                    <div className="square-item square-recents mb-0">
                                                        <div className="mb-3">
                                                            {school.address}{`${school.city ? `, ${school.city}` : ""}`}
                                                            {(school.country && school.country.name) ? `, ${school.country.name}` : ""}
                                                        </div>
                                                        <Maper items={{ coordinates: school.coordinates }} size="300" icon={mkSchool} />
                                                    </div>
                                                </Square>

                                                <div className="ads">
                                                    <div className="rectangle center-box">
                                                        PUB LATÉRAL ICI
                                                    </div>
                                                </div>

                                                <div className="my-3" />

                                                <div className="ads">
                                                    <div className="rectangle center-box">
                                                        PUB LATÉRAL ICI
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-9 px-4">
                                                <div className="ads">
                                                    <div className="leaderboard center-box">
                                                        PUB MOYENNE ICI
                                                    </div>
                                                </div>

                                                {(testimony && testimony.videoLink) ? (
                                                    <div className="row mt-5">
                                                        <div className="col-lg-12">
                                                            <div className="student-testimony-video">
                                                                <video controls>
                                                                    <source src={testimony.videoLink} type="video/mp4" />
                                                                    Votre navigateur ne prend pas en charge la balise vidéo.
                                                                </video>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (testimony && testimony.content) ? (
                                                    <div className="row mt-5">
                                                        <div className="col-lg-12">
                                                            <div className="student-testimony mx-5 bg-light rounded p-4">
                                                                <figure>
                                                                    <blockquote className="blockquote">
                                                                        <p className="fs-15">
                                                                            {testimony.content}
                                                                        </p>
                                                                    </blockquote>
                                                                    <figcaption className="blockquote-footer mb-0 mt-1">
                                                                        {testimony.author}, {" "}
                                                                        <cite title="Source Title">
                                                                            {testimony.authorLevel}
                                                                        </cite>
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                <div className="row mt-5">
                                                    <div className="col-lg-12">
                                                        <div className="accordion content-accordion" id="accordionSchoolContent">
                                                            {SCHOOL_ACCORDIONS.map((item, index) => (
                                                                <div key={`school_accordion_${index}`} className="accordion-item">
                                                                    <h2 className="accordion-header" id={`heading${item.key}`}>
                                                                        <button
                                                                            className="accordion-button collapsed"
                                                                            type="button" data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse${item.key}`}
                                                                            aria-expanded="false" aria-controls={`collapse${item.key}`}
                                                                        >
                                                                            {item.label}
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`collapse${item.key}`}
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby={`heading${item.key}`}
                                                                        data-bs-parent="#accordionSchoolContent"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            {school[item.key] || "Non renseigné"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 availables-formations mt-5">
                                                        <h5 className="mb-0 fw-bold">
                                                            Autres formations similaires disponibles
                                                        </h5>
                                                        {(school.formations && (school.formations.length > 0)) ? (
                                                            <ListFormation
                                                                listerClasses="g-3 mt-0"
                                                                items={school.formations.map((item) => ({
                                                                    ...item,
                                                                    school: school
                                                                }))}
                                                            />
                                                        ) : (
                                                            <p className="m-0 p-2">
                                                                Aucune formation similaire disponible.
                                                            </p>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-12 leave-comment mt-5">
                                                        <button
                                                            type="button" className="btn-leave-comment"
                                                            onClick={() => this.onCommentSectionShowed()}
                                                        >
                                                            {`${showCommentSection ? "Réduire" : "Laisser un commentaire"}`} {" "}
                                                            <i className={`bx bx-${showCommentSection ? "up" : "down"}-arrow-alt text-light`} />
                                                        </button>
                                                        {showCommentSection ? (
                                                            <div className="mt-3">
                                                                <h5 className="mb-2 fw-bold">
                                                                    Ancien.ne étudiant.e ? Partagez votre expérience en laissant un commentaire
                                                                </h5>
                                                                <div className="content">
                                                                    {localStorage.getItem(USER_DATA) ? (
                                                                        <Commenter
                                                                            onCommented={this.onCommented}
                                                                            {...commenter}
                                                                        />
                                                                    ) : (
                                                                        <p className="m-0">
                                                                            <Link
                                                                                to={{ pathname: `/login`, state: { from: location } }}
                                                                            >
                                                                                Connectez-vous
                                                                            </Link> {" "} à votre compte membre pour laisser un commentaire.
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container py-5">
                            <div className="row py-5">
                                <div className="col-lg-12 py-5">
                                    <center>
                                        <p>Cette formation est introuvable.</p>
                                        <p className="mb-0">
                                            Continuer sur{" "}
                                            <a href="/">l'accueil</a> ou sur la page {" "}
                                            <a href="/">précédente</a>.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {(showSocialShare && school && school.publicId) ? (
                    <SocialShare
                        opened={showSocialShare}
                        shareLink={`http://localhost:3000/etablissements/${slugify(school.name)}`}
                        shareMessage="Découvre cet établissement d'exception"
                        title="Partager un établissement"
                        subtitle={`Partager ${school.name} sur les réseaux sociaux et autres plateformes...`}
                        onClosed={this.onModalToggled}
                    />
                ) : null}
                {(showCostConvert && formationCost) ? (
                    <ConvertCost
                        opened={showCostConvert}
                        cost={formationCost}
                        onClosed={this.onConvertToggled}
                    />
                ) : null}
            </React.Fragment>
        )
    }
}
Formation.propTypes = {
    formation: PropTypes.object,
    onSetFormation: PropTypes.func,
    isPreloader: PropTypes.bool,
    tokenFailed: PropTypes.bool,
    tokenRetrieved: PropTypes.bool,
    onAppTokenAsked: PropTypes.func,
    onAppTokenRetrieved: PropTypes.func,
    onPreloaderToggled: PropTypes.func,
}
const mapStateToProps = state => ({
    formation: state.formation,
    tokenRetrieved: state.tokenRetrieved,
    tokenFailed: state.tokenFailed,
    isPreloader: state.isPreloader,
})
const mapDispatchToProps = dispatch => ({
    onSetFormation: data => dispatch(setFormation(data)),
    onAppTokenAsked: (data) => dispatch(setAppTokenAsked(data)),
    onAppTokenRetrieved: (data) => dispatch(setAppTokenRetrieved(data)),
    onPreloaderToggled: (preload, message) => dispatch(togglePreloader(preload, message)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Formation)