import React from "react"

const Cta = () => {
    return (
        <section id="cta">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <p className="text-success">
                            <strong>
                                Bénéficiez des services ACE pour construire un projet académique gagnant en Afrique et dans le monde.
                            </strong>
                        </p>
                        <p>
                            Tests d'orientation : apprendre à mieux se connaître pour faire les bons choix
                        </p>
                        <p>
                            Accompagnements personnalisés : se préparer pour réussir son projet
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <p>
                            Vous êtes parent, lycéen.ne, bachelier.e ou étudiant.e en Afrique francophone ?
                        </p>
                        <p>
                            Découvrez les forfaits ACE Orientation pour réussir votre orientation, votre projet d'études et votre insertion professionnelle.
                        </p>
                        <a className="btn-cta" href="#">Préparer mon avenir</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Cta